
export const initialState = {
	account: {},
	personal: {},
	company: null,
	employees: null,
	locations: null,
	gmaps: "",
	initialized: false,
	loading: false,
	error: "",
	errorCode: -1,
};

export const UserDataReducer = (initialState, action) => {
	switch (action.type) {
		case 'REQUEST_USER_DATA':
		case 'SAVE_PERSONAL_DATA':
		case 'SAVE_LOGIN_DATA':
		case 'SAVE_PORTRAIT_DATA':
		case 'SAVE_PERSONAL_CERTIFICATIONS_DATA':
		case 'SAVE_BUSINESS_LOGO_DATA':
		case 'SAVE_BUSINESS_ADDRESS_DATA':
		case 'SAVE_BUSINESS_TEXT_DATA':
		case 'SAVE_BUSINESS_CONTACT_PERSONS_DATA':
		case 'SAVE_BUSINESS_CONTACT_PERSON_MAIN_DATA':
		case 'SAVE_BUSINESS_PARTNERSTATUS_DATA':
		case 'SAVE_BUSINESS_MANUFACTURERS_DATA':
		case 'SAVE_BUSINESS_CUSTOMERGROUPS_DATA':
		case 'ADD_BUSINESS_EMPLOYEES_DATA':
		case 'EDIT_BUSINESS_EMPLOYEES_DATA':
		case 'DELETE_BUSINESS_EMPLOYEES_DATA':
		case 'ADD_BUSINESS_LOCATIONS_DATA':
		case 'EDIT_BUSINESS_LOCATIONS_DATA':
		case 'DELETE_BUSINESS_LOCATIONS_DATA':
			return {
				...initialState,
				loading: true,
				error: "",
				errorCode: -1
			};
		case 'SET_USER_DATA':
		case 'REQUEST_USER_DATA_SUCCESS':
			return {
				...initialState,
				account: action.payload.account,
				personal: action.payload.personal,
				company: action.payload.company,
				employees: action.payload.employees,
				locations: action.payload.locations,
				gmaps: action.payload.config.gmaps,
				initialized: true,
				loading: false,
				error: "",
				errorCode: -1,
			};
		case 'SAVE_PERSONAL_DATA_SUCCESS':
		case 'SAVE_PORTRAIT_DATA_SUCCESS':
		case 'SAVE_PERSONAL_CERTIFICATIONS_DATA_SUCCESS':
			return {
				...initialState,
				personal: action.payload.personal,
				loading: false,
				error: "",
				errorCode: -1,
			};

		case 'SAVE_LOGIN_DATA_SUCCESS':
			return {
				...initialState,
				account: action.payload.account,
				loading: false,
				error: "",
				errorCode: -1,
			};

		case 'SAVE_BUSINESS_ADDRESS_DATA_SUCCESS':
		case 'SAVE_BUSINESS_TEXT_DATA_SUCCESS':
		case 'SAVE_BUSINESS_LOGO_DATA_SUCCESS':
		case 'SAVE_BUSINESS_CONTACT_PERSONS_DATA_SUCCESS':
		case 'SAVE_BUSINESS_CONTACT_PERSON_MAIN_DATA_SUCCESS':
		case 'SAVE_BUSINESS_PARTNERSTATUS_DATA_SUCCESS':
		case 'SAVE_BUSINESS_MANUFACTURERS_DATA_SUCCESS':
		case 'SAVE_BUSINESS_CUSTOMERGROUPS_DATA_SUCCESS':
			return {
				...initialState,
				company: action.payload.company,
				loading: false,
				error: "",
				errorCode: -1,
			};

		case 'ADD_BUSINESS_EMPLOYEES_DATA_SUCCESS':
		case 'DELETE_BUSINESS_EMPLOYEES_DATA_SUCCESS':
		case 'EDIT_BUSINESS_EMPLOYEES_DATA_SUCCESS':
			return {
				...initialState,
				employees: action.payload.employees,
				loading: false,
				error: "",
				errorCode: -1,
			};

		case 'ADD_BUSINESS_LOCATIONS_DATA_SUCCESS':
		case 'DELETE_BUSINESS_LOCATIONS_DATA_SUCCESS':
		case 'EDIT_BUSINESS_LOCATIONS_DATA_SUCCESS':
			return {
				...initialState,
				locations: action.payload.locations,
				loading: false,
				error: "",
				errorCode: -1,
			};

		case 'REQUEST_USER_DATA_ERROR':
		case 'SAVE_PERSONAL_DATA_ERROR':
		case 'SAVE_PORTRAIT_DATA_ERROR':
		case 'SAVE_LOGIN_DATA_ERROR':
		case 'SAVE_PERSONAL_CERTIFICATIONS_DATA_ERROR':
		case 'SAVE_BUSINESS_ADDRESS_DATA_ERROR':
		case 'SAVE_BUSINESS_TEXT_DATA_ERROR':
		case 'SAVE_BUSINESS_LOGO_DATA_ERROR':
		case 'SAVE_BUSINESS_CONTACT_PERSONS_DATA_ERROR':
		case 'SAVE_BUSINESS_CONTACT_PERSON_MAIN_DATA_ERROR':
		case 'SAVE_BUSINESS_PARTNERSTATUS_DATA_ERROR':
		case 'SAVE_BUSINESS_MANUFACTURERS_DATA_ERROR':
		case 'SAVE_BUSINESS_CUSTOMERGROUPS_DATA_ERROR':
		case 'ADD_BUSINESS_EMPLOYEES_DATA_ERROR':
		case 'DELETE_BUSINESS_EMPLOYEES_DATA_ERROR':
		case 'EDIT_BUSINESS_EMPLOYEES_DATA_ERROR':
		case 'ADD_BUSINESS_LOCATIONS_DATA_ERROR':
		case 'DELETE_BUSINESS_LOCATIONS_DATA_ERROR':
		case 'EDIT_BUSINESS_LOCATIONS_DATA_ERROR':
			return {
				...initialState,
				loading: false,
				error: action.payload.error,
				errorCode: action.payload.code,
			};

		case 'UNSET_USER_DATA':
			return {
				...initialState,
				account: {},
				personal: {},
				company: null,
				employees: null,
				locations: null,
				gmaps: "",
				initialized: false,
				loading: false,
				error: "",
				errorCode: -1,
			};

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
