import React, { Component } from "react";

class CompanyMap extends Component {
  
  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    );
    this.addMarker(map);
  }

  addMarker(map) {
    new window.google.maps.Marker({
        position: this.props.options.center,
        map: map
    });
  }

  componentDidMount() {
    if (!window.google) {
      if(document.getElementById("gmapsScript")===null) {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.id = "gmapsScript";
        s.src = `https://maps.google.com/maps/api/js?key=${this.props.gmaps}`;
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      }
      
      // Below is important.
      //We cannot access google.maps until it's finished loading
      document.getElementById("gmapsScript").addEventListener("load", e => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    return <div className="companymap" id={this.props.id} />;
  }
}

export default CompanyMap;
