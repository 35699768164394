import React, { Fragment } from 'react';
import { useEffectOnce } from '../utils/useEffectOnce';
import { useAuthDispatch, useAuthState } from '../contexts/auth'
import { useUserDataDispatch, unsetUserData } from '../contexts/userdata'
import apiService from '../utils/apiService';
import { purgeLocalStorage } from '../utils';
import { useQueryClient } from 'react-query'


function Logout(props) {
    const queryClient = useQueryClient();
    const { loggedIn } = useAuthState();
    const authDispatch = useAuthDispatch();
    const userDataDispatch = useUserDataDispatch();

    queryClient.invalidateQueries();

    useEffectOnce(() => {
        if(!loggedIn) {
            purgeLocalStorage();
            return;
        }
        const handleLogout = async () => {
            try {
                authDispatch({ type: 'REQUEST_LOGOUT' });
                await apiService('logout', { useTokenAuth: true });
            } catch (error) {
                authDispatch({ type: 'LOGOUT' });
                purgeLocalStorage();
            }
            await unsetUserData(userDataDispatch);
        };
        handleLogout();
      // eslint-disable-next-line
      }, [loggedIn]);


	return (
		<Fragment />
	);
}

export default Logout;
