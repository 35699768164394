import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import useMarketingSitemap from "../hooks/useMarketingSitemap";
import LoadingView from "../components/misc/LoadingView";
import MarketingResultGrid from "../components/marketing/MarketingResultGrid";
import MarketingDetail from "../components/marketing/MarketingDetail";
import MarketingFilter from "../components/marketing/MarketingFilter";

function Marketing(props) {
  const { slug, page } = useParams();
  const { status, data } = useMarketingSitemap();

  if (status === "loading") {
    return <LoadingView />;
  }

  let section;

  if (slug) {
    section = data.find((item) => item.slug === slug);
  }

  return (
    <Fragment>
      {page && page !== "" ? (
        <MarketingDetail slug={slug} page={page} section={section} />
      ) : (
        <Fragment>
          <MarketingFilter slug={slug} />
          {data &&
            data.map((item) => {
              if (slug && slug !== item.slug) {
                return null;
              }
              return <MarketingResultGrid key={item.slug} {...item} />;
            })}
        </Fragment>
      )}
    </Fragment>
  );
}

export default Marketing;
