import { useQuery } from "react-query";
import apiService from "../utils/apiService";
import { useHistory } from "react-router-dom";
import { useAuthState } from "../contexts/auth";
import { LOGOUT_ROUTE } from "../utils";
import { getUseQueryOptions } from ".";

export default function useMarketing(uuid) {
  const history = useHistory();
  const userDetails = useAuthState();

  const queryUrl = `get-marketing/${uuid}`;

  return useQuery(
    queryUrl.concat(userDetails.uuid),
    async () => {
      const data = await apiService(queryUrl, { useTokenAuth: true });
      return data;
    },
    getUseQueryOptions({
      enabled: uuid !== "",
      onError: () => history.push(LOGOUT_ROUTE),
    })
  );
}
