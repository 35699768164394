import React, { Component, PureComponent } from "react";
import { Box, Grid, Typography, Divider, Button } from "@mui/material";
import _ from "lodash";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useHistory } from "react-router-dom";
import { PATHNAMES } from "../../routes/pathnames";
import { setMapScrollIndex, useMapScrollDispatch } from "../../contexts/map";
// import CompaniesLogo from '../companies/CompaniesLogo';

const elemId = "partnermap";

const boxStyle = {
  pb: { xs: "140%", sm: "75%", md: "75%", xl: "60%" },
  borderRadius: "15px",
  position: "relative",
};

function CompaniesDetailButton(props) {
  const history = useHistory();

  const dispatch = useMapScrollDispatch();

  const handleClick = () => {
    setMapScrollIndex(dispatch, { mapScrollIndex: props.num });
    history.push(`${PATHNAMES.COMPANIES}/${props.slug}`);
  };

  const handleMapsClick = () => {
    props.map.panTo({ lat: parseFloat(props.lat), lng: parseFloat(props.lng) });
    props.map.setZoom(12);
    props.openInfoWin(props.num);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        left: 0,
        right: 0,
        bottom: 0,
        px: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          size="small"
          sx={{ px: 2, fontSize: "0.7rem" }}
          onClick={handleClick}
        >
          Detail-Seite
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ px: 2, fontSize: "0.7rem" }}
          onClick={handleMapsClick}
        >
          Auf Karte zeigen
        </Button>
      </Box>

      <Divider sx={{ mt: 2, mb: 0 }} />
    </Box>
  );
}
class CompaniesItem extends PureComponent {
  render() {
    // Access the items array using the "data" prop:
    const item = this.props.data.locations[this.props.index];

    const small = parseInt(this.props.style.height, 10) === 210;
    //   const Logo = () => item.logo !== "" ? <CompaniesLogo src={item.logo} alt={item.slug} /> : null;
    const Logo = () =>
      item.logo !== "" ? (
        <Box
          component="img"
          sx={{
            height: "auto",
            width: "auto",
            maxHeight: "inherit",
            maxWidth: "100%",
            objectFit: "contain",
          }}
          alt={item.slug}
          src={item.logo}
        />
      ) : null;

    return (
      <div style={this.props.style}>
        <Box
          key={item.uuid}
          sx={{ p: 2, pb: 0, position: "relative", height: "100%" }}
        >
          <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }}>
            <Grid item xs={item.logo !== "" ? 8 : 12}>
              <Typography
                component="div"
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: 500,
                  lineHeight: 1.15,
                }}
              >
                {item.name}
              </Typography>
            </Grid>
            {item.logo !== "" && (
              <Grid item xs={4} sx={{ textAlign: "right", pr: 1 }}>
                <Box sx={{ maxHeight: "65px", width: "100%" }}>
                  <Logo />
                </Box>
              </Grid>
            )}
          </Grid>

          <Typography
            color="text.secondary"
            sx={{ fontSize: "0.75rem", mt: 0.5 }}
          >
            {item.street}, {item.zipcode} {item.city}
          </Typography>
          <Typography
            color="text.secondary"
            sx={{
              fontSize: "0.85rem",
              mt: 2,
              maxHeight: small ? "35px" : "85px",
              overflow: "hidden",
            }}
          >
            {item.excerpt}
          </Typography>
          <CompaniesDetailButton
            num={this.props.index}
            slug={item.slug}
            map={this.props.data.map}
            lat={item.lat}
            lng={item.lng}
            openInfoWin={this.props.data.openInfoWin}
          />
        </Box>
      </div>
    );
  }
}

class PartnerMap extends Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.state = {
      init: false,
      markers: [],
      map: null,
      infoWindow: null,
    };
    this.deleteMarkers = this.deleteMarkers.bind(this);
    this.clickMarker = this.clickMarker.bind(this);
    this.openInfoWin = this.openInfoWin.bind(this);
  }

  deleteMarkers() {
    for (let i = 0; i < this.state.markers.length; i++) {
      this.state.markers[i].setMap(null);
    }
  }

  clickMarker(num) {
    this.listRef.current.scrollToItem(num, "start");
  }

  openInfoWin(num) {
    const currLoc = this.props.locations[num];
    this.state.infoWindow.setContent(
      "<strong>" +
        currLoc.name +
        "</strong><br>" +
        currLoc.street +
        ", " +
        currLoc.zipcode +
        " " +
        currLoc.city
    );
    this.state.infoWindow.open({
      anchor: this.state.markers[num],
      map: this.state.map,
      shouldFocus: false,
    });
  }

  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById(elemId),
      this.props.options
    );
    this.addMarker(map);

    if (this.props.mapScrollIndex >= 0) {
      window.setTimeout(() => {
        if (this.listRef.current) {
          this.listRef.current.scrollToItem(this.props.mapScrollIndex, "start");
        }
      }, 100);
    }
  }

  addMarker(map) {
    const bounds = new window.google.maps.LatLngBounds();
    const markers = [];
    this.props.locations.forEach((location, i) => {
      let marker = new window.google.maps.Marker({
        position: {
          lat: parseFloat(location.lat),
          lng: parseFloat(location.lng),
        },
        map: map,
        title: location.name,
      });
      marker.addListener("click", () => {
        map.setZoom(10);
        map.setCenter(marker.getPosition());
        this.clickMarker(i);
        this.openInfoWin(i);
      });
      markers.push(marker);

      if (this.props.locations.length > 1) {
        bounds.extend(
          new window.google.maps.LatLng(
            parseFloat(location.lat),
            parseFloat(location.lng)
          )
        );
      }
    });
    if (this.props.locations.length > 1) {
      map.fitBounds(bounds);
    } else {
      map.setCenter({
        lat: parseFloat(this.props.locations[0].lat),
        lng: parseFloat(this.props.locations[0].lng),
      });
      map.setZoom(13);
    }

    const infoWindow = new window.google.maps.InfoWindow({
      content: "",
      maxWidth: 350,
    });

    window.google.maps.event.addListener(map, "click", function (event) {
      infoWindow.close();
    });

    this.setState({
      init: true,
      markers: markers,
      map: map,
      infoWindow: infoWindow,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.isLoading) {
      if (!_.isEqual(this.props.locations, prevProps.locations)) {
        if (this.state.markers.length > 0) {
          this.deleteMarkers();
        }
        this.addMarker(this.state.map);
      }
    }
  }

  componentDidMount() {
    if (!window.google) {
      if (document.getElementById("gmapsScript") === null) {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.id = "gmapsScript";
        s.src = `https://maps.google.com/maps/api/js?key=${this.props.gmaps}`;
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      }
      document.getElementById("gmapsScript").addEventListener("load", (e) => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    const addClassName = this.props.largeScreen ? `large` : `small`;
    const isTablet = !this.props.largeScreen && this.props.tabletScreen;

    return (
      <Grid container rowSpacing={2} columnSpacing={0}>
        <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
          {this.state.init && (
            <Box className={`partnerlist ${addClassName}`}>
              <Box className={`partnerlistinner ${addClassName}`}>
                {this.props.locations.length > 0 ? (
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        className="List"
                        height={height}
                        itemCount={this.props.locations.length}
                        itemSize={isTablet ? 210 : 250}
                        width={width}
                        ref={this.listRef}
                        itemData={{
                          locations: this.props.locations,
                          map: this.state.map,
                          markers: this.state.markers,
                          openInfoWin: this.openInfoWin,
                        }}
                      >
                        {CompaniesItem}
                      </List>
                    )}
                  </AutoSizer>
                ) : null}
              </Box>
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          order={{ xs: 1, md: 2, borderRadius: "15px" }}
        >
          <Box sx={boxStyle}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: "15px",
              }}
            >
              <div className={`partnermap ${addClassName}`} id={elemId} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default PartnerMap;
