import { useQuery } from "react-query";
import apiService from "../utils/apiService";
import { useHistory } from "react-router-dom";
import { useAuthState } from "../contexts/auth";
import { LOGOUT_ROUTE } from "../utils";
import { getUseQueryOptions } from ".";

export default function useMarketingSitemap() {
  const history = useHistory();
  const userDetails = useAuthState();

  const queryUrl = "get-marketing-sitemap";

  return useQuery(
    queryUrl.concat(userDetails.uuid),
    async () => {
      const data = await apiService(queryUrl, { useTokenAuth: true });
      return data;
    },
    getUseQueryOptions({
      onError: () => history.push(LOGOUT_ROUTE),
      enabled: true,
    })
  );
}
