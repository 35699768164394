import React, { useState } from 'react';
import { Box, TextField, Typography, Paper, FormControl, Button, Grid, Switch, FormControlLabel, Alert } from '@mui/material';
import { useUserDataState, saveLoginData, useUserDataDispatch } from '../../contexts/userdata';
import { validateEmail } from '../../utils';
import _ from 'lodash';

const pwRegEx = /(?=(?:.*[A-Z]){1,})(?=(?:.*[a-z]){1,})(?=(?:.*\d){1,})(?=(?:.*[!@#§$%^&*()\-_=+{};:,<.>?/€~£¥|]){1,})([A-Za-z0-9!@#§$%^&*()\-_=+{};:,<.>?/€~£¥|]{8,50})$/;
const pwHint = "Ein Kennwort muss zwischen 8 und 50 Zeichen lang sein. Mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und eines dieser Sonderzeichen enthalten: !@#§$%^&*()-_=+{};:,<.>?/€~£¥|";

function LoginData(props) {
    const { account, loading } = useUserDataState();
    const defaultAccountObj = {
        email: account.email,
        changePassword: false,
        newPassword: "",
    };
    const [form, setForm] = useState(defaultAccountObj);
    const dispatch = useUserDataDispatch();
    const [msg, setMsg] = useState({str:"",type:""});

    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultAccountObj);
        setMsg({str:"",type:""});
    }

    const handleChangePassword = (event) => {
        setForm({
            ...form,
            changePassword: event.target.checked
        })
        setMsg({str:"",type:""});
    };

    const handleChange = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
        setMsg({str:"",type:""});
    }

    const preventSubmit = () => {
        if(form.changePassword) {
            if(form.newPassword.length <8 || form.newPassword.length > 50) {
                return true;
            }
            if(!pwRegEx.test(form.newPassword)) {
                return true;
            }
        }
        if(form.email==="") {
            return true;
        }
        if(!validateEmail(form.email)) {
            return true;
        }
        if(_.isEqual(defaultAccountObj, form)) {
            return true;
        }
        return false;
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
        if(_.isEqual(defaultAccountObj, form)) {
            return;
        }
        if(preventSubmit()) {
            return;
        }
        const fields = {};
        if(form.email!==defaultAccountObj.email) {
            fields.email = form.email;
        }
        if(form.changePassword!==defaultAccountObj.changePassword) {
            fields.changePassword = form.changePassword;
        }
        if(form.newPassword!==defaultAccountObj.newPassword) {
            fields.newPassword = form.newPassword;
        }
        const res = await saveLoginData(dispatch, fields);
        if(res?.account) {
            setForm({
                email: res.account.email,
                changePassword: false,
                newPassword: ""
            });
            setMsg({str:"Erfolgreich gespeichert",type:"success"});
        } else {
            setMsg({str:"Ein Fehler ist aufgetreten — nicht gespeichert",type:"error"});
        }
    }


	return (
		<Box sx={{mt:3}}>
			<Typography variant="h2">
				Login Daten
			</Typography>
            <Paper elevation={10}>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="email"
                        name="email"
                        label="E-Mail"
                        variant="outlined"
                        value={form.email ?? ""}
                        error={form.email===""}
                        onChange={handleChange}
                        helperText="Hinweis: die Email Adresse ist gleichzeitig der Benutzername für die Anmeldung im Partnerportal!"
                    />
                </FormControl>
                <FormControlLabel control={<Switch checked={form.changePassword} onChange={handleChangePassword} />} label="Passwort ändern" />

                {form.changePassword && 
                    <FormControl fullWidth sx={{my: 2}}>
                        <TextField
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            label="Neues Passwort"
                            variant="outlined"
                            value={form.newPassword ?? ""}
                            onChange={handleChange}
                            helperText={pwHint}
                        />
                    </FormControl>
                }
                {
                    msg?.str && <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 3, mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={preventSubmit()}
                            >
                            { !loading ? "Daten speichern" : "Verarbeite…" }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            disabled={_.isEqual(defaultAccountObj, form)}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
                
             </Box>
            </Paper>
		</Box>
	);
}

export default LoginData;
