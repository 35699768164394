import React from 'react';
import { Typography } from '@mui/material';

function SectionHeadline(props) {

	return (
        <Typography component="div" sx={{fontSize: "1.25rem", fontWeight: 500}}>
            {props.children}
        </Typography>
	);
}

export default SectionHeadline;
