import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

export default function LinkToButton(props) {
  const history = useHistory();

  const handleClick = evt => {
    evt.preventDefault();
    history.push(props.link);
  }

  return (
      <Button variant="outlined" size="small" onClick={handleClick}>
        {props.label}
      </Button>
  );
}

LinkToButton.propTypes = {
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};