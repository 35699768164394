import React, { Fragment, useState } from 'react';
import { Box, TextField, FormControl, InputLabel, MenuItem, IconButton, Select, Button, Grid, Dialog, DialogContent, Typography } from '@mui/material';
import _ from 'lodash';
import { contactPersonTypes, validateEmail } from '../../utils';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteConfirm(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
        if(props.isNew) {
            props.handleDelete(props.id, props.isNew);
            return;
        }
        setOpen(true);
      };
    
    const handleClose = (value) => {
        setOpen(false);
    };

    const handleDelete = (evt) => {
        props.handleDelete(props.id, props.isNew);
        setOpen(false);
    };
  
    return (
        <>
            <IconButton aria-label="delete" onClick={handleClickOpen} color="primary">
                <DeleteIcon />
            </IconButton>
            <Dialog onClose={handleClose} open={open} maxWidth="xs">
                <DialogContent>
                    <Typography>
                        Soll dieser Eintrag wirklich gelöscht werden?
                    </Typography>
                    <Box sx={{display:"flex",justifyContent:"space-between", mt: 2}}>
                        <Button variant="contained" onClick={handleDelete}>Löschen</Button>
                        <Button variant="outlined" onClick={handleClose}>Abbrechen</Button>
                    </Box>
                    
                </DialogContent>
            </Dialog>
        </>
        
    );
  }


function ContactPerson(props) {
    const defaultFormObj = {
        salutation: props.salutation,
        name: props.name,
        email: props.email,
        phone: props.phone,
        type: props.type,
        id: props.id,
    };
    const [form, setForm] = useState(defaultFormObj);

    const handleChange = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
        props.onChange(evt);
    }

    const handleCancel = evt => {
        evt.preventDefault();
        if(checkIfNew()) {
            props.onDelete(props.id, true);
            return;
        }
        setForm(defaultFormObj);
    }

    const handleDelete = (id, isNew) => {
        props.onDelete(id, isNew);
    }

    const handleSubmit = (e) => {
		e.preventDefault();
        if(_.isEqual(defaultFormObj, form)) {
            return;
        }
        if(form.salutation==="" || form.type==="" || form.name==="" || form.email==="" || form.phone==="") {
            return;
        }
        if(!validateEmail(form.email)) {
            return;
        }
        const fields = {
            salutation: form.salutation,
            name: form.name,
            email: form.email,
            phone: form.phone,
            type: form.type,
            id: form.id,
        };
        setForm(fields);
        props.onSubmit(props.id, fields);
    }

    const checkIfNew = () => {
        return props.salutation==="" && props.name==="" && props.email==="" && props.phone==="" && props.type==="";
    }


	return (
		<Fragment>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{my: 2}}>
                    <InputLabel id="typelabel" sx={{background:"#fff",px:1}} required>Abteilung</InputLabel>
                    <Select
                        required
                        labelId="typelabel"
                        onChange={handleChange}
                        value={form.type ?? ""}
                        error={form.type===""}
                        name="type"
                    >
                        {
                            contactPersonTypes.map((item, i) => <MenuItem key={i} value={item}>{item}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <InputLabel id="salutationlabel" sx={{background:"#fff",px:1}} required>Anrede</InputLabel>
                    <Select
                        required
                        labelId="salutationlabel"
                        onChange={handleChange}
                        value={form.salutation ?? ""}
                        error={form.salutation===""}
                        name="salutation"
                    >
                        <MenuItem value="female">Frau</MenuItem>
                        <MenuItem value="male">Herr</MenuItem>
                        <MenuItem value="diverse">Divers</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Vor- und Nachname"
                        variant="outlined"
                        value={form.name ?? ""}
                        error={form.name===""}
                        helperText="Bitte Vorname und Nachname angeben"
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="E-Mail"
                        variant="outlined"
                        value={form.email ?? ""}
                        error={form.email==="" || !validateEmail(form.email)}
                        helperText={!validateEmail(form.email) ? "Diese E-Mail Adresse ist ungültig": (form.email==="" ? "Bitte eine gültige E-Mail Adresse eintragen" : "")}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="phone"
                        name="phone"
                        label="Telefon"
                        variant="outlined"
                        value={form.phone ?? ""}
                        error={form.phone===""}
                        helperText="Bitte Telefonnummer in diesem Format angeben: +49 (0)123 98765432"
                        onChange={handleChange}
                    />
                </FormControl>
                
                {props.message}
                
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 3, mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={_.isEqual(defaultFormObj, form) || form.salutation==="" || form.type==="" || form.name==="" || form.email==="" || form.phone==="" || !validateEmail(form.email)}
                            >
                            { "Daten speichern" }
                        </Button>
                    </Grid>
                    <Grid item xs={checkIfNew() ? 12 : 10} sm={checkIfNew() ? 6 : 5}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            disabled={!checkIfNew() && _.isEqual(defaultFormObj, form)}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                    { !checkIfNew() &&
                        <Grid item xs={2} sm={1}>
                            <Box>
                                <DeleteConfirm id={props.id} handleDelete={handleDelete} isNew={checkIfNew()} />
                            </Box>
                        </Grid>
                    }
                </Grid>
             </Box>
		</Fragment>
	);
}

export default ContactPerson;
