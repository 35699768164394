export const stopwords = [
    "ab",
    "aber",
    "abgesehen",
    "alle",
    "allein",
    "allem",
    "allen",
    "aller",
    "allerdings",
    "alles",
    "als",
    "also",
    "am",
    "an",
    "andere",
    "anderem",
    "anderen",
    "anderenfalls",
    "anderer",
    "anderes",
    "andernfalls",
    "anders",
    "andersherum",
    "anfangs",
    "anhand",
    "anschließend",
    "ansonsten",
    "anstatt",
    "auch",
    "auf",
    "aufgrund",
    "aus",
    "aussen",
    "ausser",
    "ausserdem",
    "ausserhalb",
    "außen",
    "außer",
    "außerdem",
    "außerhalb",
    "befindet",
    "behalten",
    "bei",
    "beide",
    "beiden",
    "beider",
    "beides",
    "beim",
    "beinahe",
    "beispielsweise",
    "bereits",
    "besonders",
    "besteht",
    "bestimmte",
    "bestimmten",
    "bestimmter",
    "bevor",
    "bietet",
    "bin",
    "bis",
    "bist",
    "bitte",
    "bleiben",
    "bringen",
    "bringt",
    "bsp",
    "bzw",
    "d.h",
    "d.h.",
    "da",
    "dabei",
    "dafür",
    "daher",
    "damit",
    "danach",
    "dann",
    "dar",
    "daran",
    "darauf",
    "daraus",
    "darf",
    "darstellt",
    "darueber",
    "darueberhinaus",
    "darum",
    "darüber",
    "darüberhinaus",
    "das",
    "dass",
    "davon",
    "dazu",
    "daß",
    "dem",
    "demzufolge",
    "den",
    "denen",
    "denn",
    "der",
    "deren",
    "des",
    "deshalb",
    "dessen",
    "desto",
    "dh",
    "die",
    "dies",
    "diese",
    "diesem",
    "diesen",
    "dieser",
    "dieses",
    "doch",
    "dort",
    "duerfte",
    "duerften",
    "duerftest",
    "duerftet",
    "durch",
    "durfte",
    "durften",
    "durftest",
    "durftet",
    "dürfte",
    "dürften",
    "dürftest",
    "dürftet",
    "ebenfalls",
    "eher",
    "eigenen",
    "eigentlich",
    "ein",
    "eine",
    "einem",
    "einen",
    "einer",
    "eines",
    "einige",
    "einigen",
    "einiger",
    "einiges",
    "einmal",
    "einzelnen",
    "entgegen",
    "entscheidend",
    "entweder",
    "er",
    "erscheinen",
    "erstmals",
    "es",
    "etc",
    "etwas",
    "euch",
    "fast",
    "fertig",
    "folgende",
    "folgendem",
    "folgenden",
    "folgender",
    "folgendes",
    "folgt",
    "fort",
    "fuer",
    "für",
    "ganz",
    "gegen",
    "gegenueber",
    "gegenüber",
    "gehalten",
    "gehen",
    "geht",
    "gemacht",
    "gemaess",
    "gemäß",
    "genannte",
    "genannten",
    "genug",
    "gerade",
    "gerne",
    "getan",
    "getrennt",
    "gewesen",
    "gibt",
    "gilt",
    "gleich",
    "gleichen",
    "gleichzeitig",
    "gruendlich",
    "gründlich",
    "habe",
    "haben",
    "habt",
    "haeufig",
    "hast",
    "hat",
    "hatte",
    "hatten",
    "hattest",
    "hattet",
    "hauptsächlich",
    "her",
    "heutigen",
    "hier",
    "hierbei",
    "hierfür",
    "hin",
    "hindurch",
    "hingegen",
    "hintendran",
    "hinter",
    "hinunter",
    "hinzu",
    "hoch",
    "hält",
    "hätte",
    "häufig",
    "ich",
    "ihm",
    "ihn",
    "ihnen",
    "ihr",
    "ihre",
    "ihrem",
    "ihren",
    "ihrer",
    "ihres",
    "ihrige",
    "ihrigen",
    "ihriges",
    "im",
    "immer",
    "immerhin",
    "in",
    "indem",
    "innerhalb",
    "innerlich",
    "insgesamt",
    "irgendetwas",
    "irgendwelche",
    "irgendwenn",
    "irgendwo",
    "irgendwohin",
    "ist",
    "ja",
    "je",
    "jede",
    "jedem",
    "jeden",
    "jeder",
    "jedes",
    "jedoch",
    "jemals",
    "jemand",
    "jemandem",
    "jemanden",
    "jemandes",
    "jene",
    "jetzt",
    "jeweilige",
    "jeweiligen",
    "jeweils",
    "jung",
    "junge",
    "jungem",
    "jungen",
    "junger",
    "junges",
    "kam",
    "kann",
    "kannst",
    "kaum",
    "keine",
    "koennen",
    "koennt",
    "koennte",
    "koennten",
    "koenntest",
    "koenntet",
    "kommen",
    "kommt",
    "konnte",
    "konnten",
    "konntest",
    "konntet",
    "können",
    "könnt",
    "könnte",
    "könnten",
    "könntest",
    "könntet",
    "lassen",
    "lautet",
    "lediglich",
    "leider",
    "letztendlich",
    "letztere",
    "letzteres",
    "liegt",
    "lässt",
    "machen",
    "macht",
    "machte",
    "mal",
    "man",
    "mehr",
    "mehrere",
    "mein",
    "meine",
    "meinem",
    "meinen",
    "meiner",
    "meines",
    "meisten",
    "meistens",
    "mich",
    "mir",
    "mit",
    "mithilfe",
    "mittels",
    "momentan",
    "muessen",
    "muesst",
    "muss",
    "musst",
    "musste",
    "muß",
    "mußt",
    "möchte",
    "möglich",
    "möglichst",
    "müssen",
    "müßt",
    "nach",
    "nachdem",
    "naechste",
    "nahezu",
    "natürlich",
    "nebenan",
    "nein",
    "neue",
    "neuen",
    "nicht",
    "nichts",
    "niemand",
    "niemandem",
    "niemanden",
    "niemandes",
    "nirgendwo",
    "noch",
    "nun",
    "nur",
    "nächste",
    "nächsten",
    "nämlich",
    "ob",
    "oben",
    "obwohl",
    "oder",
    "oft",
    "oftmals",
    "ohne",
    "per",
    "pro",
    "sagte",
    "sagten",
    "sagtest",
    "sagtet",
    "scheinen",
    "scheint",
    "schon",
    "sehr",
    "sei",
    "seid",
    "seien",
    "seiest",
    "seiet",
    "sein",
    "seine",
    "seinem",
    "seinen",
    "seiner",
    "seines",
    "seit",
    "selbst",
    "sich",
    "sicherlich",
    "sie",
    "siehe",
    "sind",
    "so",
    "sobald",
    "sofern",
    "sogar",
    "solche",
    "solchem",
    "solchen",
    "solcher",
    "solches",
    "soll",
    "sollen",
    "sollte",
    "sollten",
    "solltest",
    "solltet",
    "somit",
    "sondern",
    "sorgt",
    "sowie",
    "sowohl",
    "sprich",
    "später",
    "statt",
    "stets",
    "sämtliche",
    "tatsaechlich",
    "tatsächlich",
    "tief",
    "trotz",
    "tun",
    "tut",
    "ueber",
    "ueberall",
    "um",
    "und",
    "uns",
    "unser",
    "unsere",
    "unserem",
    "unseren",
    "unserer",
    "unseres",
    "unten",
    "unter",
    "unterhalb",
    "usw",
    "viel",
    "viele",
    "vielen",
    "vielleicht",
    "vom",
    "von",
    "vor",
    "vorbei",
    "vorerst",
    "vorher",
    "vorueber",
    "vorüber",
    "völlig",
    "waehrend",
    "wann",
    "war",
    "waren",
    "warst",
    "wart",
    "warum",
    "was",
    "weder",
    "wegen",
    "weil",
    "weit",
    "weiter",
    "weitere",
    "weiterem",
    "weiteren",
    "weiterer",
    "weiteres",
    "weiterhin",
    "welche",
    "welchem",
    "welchen",
    "welcher",
    "welches",
    "wem",
    "wen",
    "wenige",
    "wenn",
    "wer",
    "werde",
    "werden",
    "werdet",
    "wesentlich",
    "wessen",
    "wichtige",
    "wichtigsten",
    "wie",
    "wieder",
    "wiederum",
    "will",
    "wir",
    "wird",
    "wirklich",
    "wirst",
    "wo",
    "wobei",
    "wohin",
    "worden",
    "wuerde",
    "wuerden",
    "wuerdest",
    "wuerdet",
    "wurde",
    "wurden",
    "wurdest",
    "wurdet",
    "während",
    "wäre",
    "würde",
    "würden",
    "würdest",
    "würdet",
    "z.b",
    "z.b.",
    "zb",
    "ziemlich",
    "zu",
    "zuerst",
    "zum",
    "zur",
    "zusammen",
    "zusätzlich",
    "zuvor",
    "zwar",
    "zwecks",
    "zwischen",
    "über",
    "überall",
    "überhaupt"
];