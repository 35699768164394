import React from 'react';
import { Container } from '@mui/material';
import BusinessContactPersonMain from '../components/account/BusinessContactPersonMain';
import { useUserDataState } from '../contexts/userdata';

function ContactPersonMain(props) {
	const { initialized } = useUserDataState();

	if(!initialized) {
		return null;
	}

	return (
		<Container maxWidth="md" sx={{ mt: "-20px", mb: 4}} className="no-padding">
			<BusinessContactPersonMain />
		</Container>
	);
}

export default ContactPersonMain;
