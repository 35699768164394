import React from 'react';
import { Grid, Box, Paper } from '@mui/material';
import useCompanies from '../../hooks/useCompanies';
import LoadingView from '../misc/LoadingView';
import ErrorView from '../misc/ErrorView';
import PageNotFoundView from '../misc/PageNotFoundView';
import CompaniesDetailHeader from './CompaniesDetailHeader';
import CompaniesPartnerstatus from './CompaniesPartnerstatus';
import CompaniesCustomerGroups from './CompaniesCustomerGroups';
import CompaniesManufacturers from './CompaniesManufacturers';
import CompaniesContactPersons from './CompaniesContactPersons';
import CompaniesDetailRightColumn from './CompaniesDetailRightColumn';
import CompaniesServices from './CompaniesServices';
import CompaniesEmployees from './CompaniesEmployees';


function CompaniesDetail(props) {
    const { companies, slug } = props;
    
    const company = companies.find(item => item.slug === slug);

	const { status, data } = useCompanies(company ? company.uuid : "");

    if(!company) {
        return <PageNotFoundView />;
    }

    if(status === "loading") {
        return <LoadingView />;
    }
    if(status === "error") {
        return <ErrorView />;
    }


	return (
        <Box sx={{mb: 2}}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={8}>
                    <Paper elevation={10} sx={{px:3, pt: 2, pb: 4}}>
                        <CompaniesDetailHeader {...data} />
                        <CompaniesServices services={data.services} />
                        <CompaniesPartnerstatus partnerstatus={data.partnerstatus} />
                        <CompaniesCustomerGroups customerGroups={data.customerGroups} />
                        <CompaniesManufacturers manufacturers={data.manufacturers} />
                        <CompaniesContactPersons contactPersons={data.contactPersons} />
                        <CompaniesEmployees employees={data.employees} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CompaniesDetailRightColumn {...data} />
                </Grid>
            </Grid>
        </Box>
	);
}

export default CompaniesDetail;
