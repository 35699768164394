import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import TinyMceRenderer from "../misc/TinyMceRenderer";
import { useHistory, useLocation } from "react-router-dom";
import useServices from "../../hooks/useServices";
import { PATHNAMES } from "../../routes/pathnames";
import ErrorView from "../misc/ErrorView";
import LoadingView from "../misc/LoadingView";
import PageNotFoundView from "../misc/PageNotFoundView";
import ServicesDownloadLink from "./ServicesDownloadLink";

function ServicesDetail(props) {
  const { section, slug, page } = props;
  const history = useHistory();
  const location = useLocation();

  const comesFromList =
    location.query?.prevPath &&
    location.query?.prevPath !== "" &&
    location.query?.prevPath === PATHNAMES.SERVICES;
  const backLabel = comesFromList ? "Zurück " : "";

  const handleGoBack = (evt) => {
    if (comesFromList) {
      window.history.back();
    } else {
      history.push(PATHNAMES.SERVICES);
    }
  };

  const content = section
    ? section.pages.find((item) => item.slug === `${slug}/${page}`)
    : null;

  const { status, data } = useServices(content ? content.uuid : "");

  if (!section || !content) {
    return <PageNotFoundView />;
  }

  if (status === "loading") {
    return <LoadingView />;
  }
  if (status === "error") {
    return <ErrorView />;
  }

  // const Logo = () => data.teaser !== "" ? <ServicesLogo src={data.teaser} alt={data.slug} isDetail={true} /> : null;

  const Logo = () =>
    data.teaser !== "" ? (
      <Box
        component="img"
        sx={{
          height: "auto",
          width: "auto",
          maxHeight: "inherit",
          maxWidth: "100%",
          objectFit: "contain",
        }}
        alt={data.slug}
        src={data.teaser}
      />
    ) : null;

  return (
    <Container maxWidth="md" sx={{ marginTop: "-20px" }} className="no-padding">
      <Card elevation={10}>
        <CardContent sx={{ px: 3 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 2 }}
            sx={{ mb: 2 }}
          >
            <Grid item xs={9}>
              <Typography
                component="div"
                sx={{
                  height: "3rem",
                  lineHeight: 1.15,
                  overflow: "hidden",
                  fontSize: "1.25rem",
                  fontWeight: 500,
                }}
              >
                {data.title}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "right", pr: 1 }}>
              <Box sx={{ maxHeight: "45px", width: "100%" }}>
                <Logo />
              </Box>
            </Grid>
          </Grid>

          {data &&
            data.pageContent.map((item, i) => {
              return (
                <Box key={i}>
                  <TinyMceRenderer rawHtml={item.text} />
                </Box>
              );
            })}
          {data && data.downloads.length > 0 && (
            <Box>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1" sx={{ mt: 2, fontWeight: 500 }}>
                Download Datei{data.downloads.length > 1 ? "en" : ""}
              </Typography>
              {data.downloads.map((item, i) => {
                return (
                  <Box key={i} sx={{ my: 2 }}>
                    <ServicesDownloadLink {...item} />
                  </Box>
                );
              })}
            </Box>
          )}
        </CardContent>
      </Card>
      <Box sx={{ my: 2, textAlign: "center" }}>
        <Button
          variant="outlined"
          onClick={handleGoBack}
          sx={{ borderRadius: "30px" }}
        >
          {backLabel} zur Services-Übersicht
        </Button>
      </Box>
    </Container>
  );
}

export default ServicesDetail;
