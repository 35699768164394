import { Box, Typography, Grid } from '@mui/material';
import ServicesItemsCard from './ServicesItemsCard';

export default function ServicesDashboardGrid(props) {
    
    return (
        <Box sx={{mb: 8}}>
			<Typography variant="h3" sx={{mb: 1, fontWeight:600}}>
                {props.title}
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={16}>
                { props.pages.map((item, n) =>  {
                    return (
                        <Grid item xs={4} md={2} key={n}>
                            <ServicesItemsCard {...item} isDashboard={true} />
                        </Grid>
                    )
                })
                }
            </Grid>
        </Box>
    )
}