import Grid from '@mui/material/Grid';
import LinkIcon from '@mui/icons-material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getDateFromString } from '../../utils';

export default function CompaniesNameAndLogo(props) {
  
 const Logo = () => props.logo !== "" ? <Box
        component="img"
        sx={{
            height: "auto",
            width: "auto",
            maxHeight: "inherit",
            maxWidth: "100%",
            objectFit: "contain"
        }}
        alt={props.slug}
        src={props.logo}
    /> : null;

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }}>
        <Grid item xs={props.logo!=="" ? 8 : 12}>
            <Typography component="div" sx={{
                fontSize: "1.25rem", fontWeight: 500, lineHeight: 1.2
            }}>
                {props.name}
            </Typography>
            { props.isDetail &&
                <Typography component="div" color="text.secondary" sx={{fontSize: ".75rem"}}>
                    {props.street}, {props.zipcode} {props.city}
                    { props.website!=="" && 
                        <Box sx={{ display: "block", mt: 1}}>
                            <Typography component="a" href={`${props.website}`} target="_blank" rel="noreferrer" sx={{display:"flex", alignItems: "center", fontSize: ".85rem"}}><LinkIcon sx={{fontSize: ".85rem", mr: .5}} />{props.website}</Typography>
                        </Box>
                    }
                    {
                        props.memberSince!=="" &&
                        <Box sx={{ display: "block"}}>
                            <Typography component="div" sx={{display:"flex", alignItems: "center", fontSize: ".75rem"}}>Im CPN seit: {getDateFromString(props.memberSince)}</Typography>
                        </Box>
                        
                    }
                </Typography>
            }
        </Grid>
        {
        props.logo!=="" && 
            <Grid item xs={4} sx={{textAlign:"right"}}>
                <Box sx={{maxHeight: "85px", width: "100%"}}>
                    <Logo />
                </Box>
            </Grid>
        }
    </Grid>
        
  );
}


CompaniesNameAndLogo.defaultProps = {
    isDetail: false
  }