import { Box, Typography, Grid } from "@mui/material";
import MarketingItemsCard from "./MarketingItemsCard";
import TinyMceRenderer from "../misc/TinyMceRenderer";

export default function MarketingResultGrid(props) {
  return (
    <Box sx={{ mb: 8 }}>
      <Typography variant="h3" sx={{ mb: 1, fontWeight: 600 }}>
        {props.title}
      </Typography>
      {!props.isSearch && props?.description && props.description !== "" ? (
        <Typography
          component="div"
          color="text.secondary"
          className="page-description-wrapper"
          sx={{ mb: 1 }}
        >
          <TinyMceRenderer rawHtml={props.description} />
        </Typography>
      ) : null}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {props.pages.map((item, n) => {
          return (
            <Grid item xs={12} sm={6} md={4} xl={4} key={n}>
              <MarketingItemsCard {...item} isSearch={props.isSearch} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

MarketingResultGrid.defaultProps = {
  isSearch: false,
};
