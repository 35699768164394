import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { PATHNAMES } from "../../routes/pathnames";
import TinyMceRenderer from "../misc/TinyMceRenderer";
import { getDateFromString } from "../../utils";
import { CardActionArea } from "@mui/material";

export default function EventsCard(props) {
  const history = useHistory();

  const handleClick = (e) => {
    history.push({
      pathname: `${PATHNAMES.EVENTS}/${props.slug}`,
      query: { prevPath: props.isDashboardOrSearch ? `` : PATHNAMES.EVENTS },
    });
  };

  let dateString = "";
  if (props.isMultiDay === true && props.toDate) {
    dateString = `${getDateFromString(props.fromDate)} bis ${getDateFromString(
      props.toDate
    )}`;
  } else {
    dateString = `${getDateFromString(props.fromDate)}`;
  }
  let timeString = "";
  if (props.fromTime && props.fromTime !== "") {
    timeString = `${props.fromTime} Uhr `;
  }
  if (props.toTime && props.toTime !== "") {
    timeString = timeString.concat(`bis ${props.toTime} Uhr`);
  }

  const specsSx = {
    whiteSpace: "nowrap",
    fontSize: "0.875rem",
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Card elevation={10} sx={{ height: "100%", alignItems: "top" }}>
        <CardActionArea
          onClick={handleClick}
          sx={{
            height: "100%",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <CardContent>
            <Typography
              component="div"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 500,
                lineHeight: "1.65rem",
              }}
            >
              {props.title}
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Typography color="text.secondary" sx={specsSx}>
                <strong>Datum: </strong> {dateString}
              </Typography>

              {timeString !== "" && (
                <Typography color="text.secondary" sx={specsSx}>
                  <strong>Zeit: </strong> {timeString}
                </Typography>
              )}

              {props.location !== "" && (
                <Typography color="text.secondary" sx={specsSx}>
                  <strong>Ort: </strong> {props.location}
                </Typography>
              )}

              {props.organizer !== "" && (
                <Typography color="text.secondary" sx={specsSx}>
                  <strong>Veranstalter: </strong> {props.organizer}
                </Typography>
              )}

              {props.eventType !== "" && (
                <Typography color="text.secondary" sx={specsSx}>
                  <strong>Art: </strong> {props.eventType}
                </Typography>
              )}
            </Box>

            <Typography
              component="div"
              color="text.secondary"
              className="excerpt-wrapper"
              sx={{
                height: "6rem",
                overflow: "hidden",
                fontSize: "0.875rem",
                mb: 1,
                lineHeight: "1.25rem",
              }}
            >
              <TinyMceRenderer rawHtml={props.excerpt} />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}
