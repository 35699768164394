import { useQuery } from "react-query";
import apiService from '../utils/apiService';
import { getUseQueryOptions } from ".";

export default function usePasswordToken(type, token, loggedIn) {

    const enabled = token !== "" && !loggedIn;
    const endpoint = type === "reset" ? "password-reset" : "initial-login";

    const queryUrl = `${endpoint}?token=${token}`;

    return useQuery(queryUrl, async () => {
        const data = await apiService(queryUrl, { useTokenAuth: false });
        return data;
    }, getUseQueryOptions(
        {
            enabled: enabled,
            staleTime: 0,
            cacheTime: 0,
            retry: 0,
        }
    ));

}
