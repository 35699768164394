import DeleteIcon from '@mui/icons-material/Delete';
import {
    Alert, Box, Button, Dialog, DialogContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { Fragment, useState } from 'react';
import { employeeTypes, salutations } from '../../utils';

function DeleteConfirm(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = (value) => {
        setOpen(false);
    };

    const handleDelete = (evt) => {
        props.handleDelete();
        setOpen(false);
    };
  
    return (
        <>
            <IconButton aria-label="delete" onClick={handleClickOpen} color="primary" size="small" sx={{ml: 2}}>
                <DeleteIcon />
            </IconButton>
            <Dialog onClose={handleClose} open={open} maxWidth="xs">
                <DialogContent>
                    <Typography>
                        Soll dieser Eintrag wirklich gelöscht werden?
                    </Typography>
                    <Box sx={{display:"flex",justifyContent:"space-between", mt: 2}}>
                        <Button variant="contained" onClick={handleDelete}>Löschen</Button>
                        <Button variant="outlined" onClick={handleClose}>Abbrechen</Button>
                    </Box>
                    
                </DialogContent>
            </Dialog>
        </>
        
    );
  }


function ChangeType(props) {
    const [open, setOpen] = React.useState(false);
    const [userType, setUserType] = useState(props.userType);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = (value) => {
        setOpen(false);
    };

    const handleChange = evt => {
        evt.preventDefault();
        setUserType(evt.target.value);
    }

    const handleSubmit = (evt) => {
        props.handleSubmit(userType);
        setOpen(false);
    };
  
    return (
        <>
            <Button color="primary" variant="outlined" size="small" onClick={handleClickOpen}>User-Typ ändern</Button>
            <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth fullScreen={fullScreen}>
                <DialogContent>
                    <Typography sx={{fontWeight: 500}}>
                        {props.employeeName}
                    </Typography>
                    <FormControl fullWidth sx={{my: 2}}>
                        <InputLabel id="typelabel" sx={{background:"#fff",px:1}} required>User-Typ</InputLabel>
                        <Select
                            required
                            labelId="typelabel"
                            onChange={handleChange}
                            value={userType ?? ""}
                            error={userType===""}
                            name="userType"
                        >
                            {
                                employeeTypes.map((item, i) => <MenuItem key={i} value={item}>{item}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <Alert sx={{mb: 2}} severity="warning">
                        <strong>Berechtigungen der verschiedenen User-Typen im Partnerportal:</strong><br />
                        USER: darf nur die Seiten der CPN-Services sehen<br />
                        MEMBER: darf alle Seiten sehen<br />
                        ADMIN: darf alle Seiten sehen und die Firmendaten bearbeiten (entsprechend deinem Account!)<br /><br />
                        Alle User dürfen generell den eigenen Account bearbeiten.
                    </Alert>
                    <Box sx={{display:"flex",justifyContent:"space-between", mt: 2}}>
                        <Button variant="contained" onClick={handleSubmit} disabled={userType === props.userType}>Speichern</Button>
                        <Button variant="outlined" onClick={handleClose}>Abbrechen</Button>
                    </Box>
                    
                </DialogContent>
            </Dialog>
        </>
        
    );
  }


function EmployeeEdit(props) {

    const handleSubmit = userType => {
        props.onEdit(props.uuid, userType);
    };

    const handleDelete = () => {
        props.onDelete(props.uuid);
    };

    const employeeName = `${salutations[props.salutation]} ${props.firstname} ${props.lastname}`;

	return (
		<Fragment>
            <Typography variant="h5" sx={{fontWeight: 500}}>{employeeName}</Typography>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">User-Typ: {`${props.userType ?? "k.A."}`}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">E-Mail: {`${props.email ?? ""}`}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Telefon: {`${props.phone ?? "k.A."}`}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Position: {`${props.jobPosition ?? "k.A."}`}</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mt:2}}>
                <Grid item xs={12}>
                    <ChangeType userType={props.userType} employeeName={employeeName} handleSubmit={handleSubmit} />
                    <DeleteConfirm handleDelete={handleDelete} />
                </Grid>
            </Grid>
		</Fragment>
	);
}

export default EmployeeEdit;
