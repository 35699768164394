import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Routes from "./routes/Routes";
import { AuthProvider } from "./contexts/auth";
import ResponsiveAppBar from "./components/misc/menu/AppBar";
import { HelmetProvider } from "react-helmet-async";
import { UserDataProvider } from "./contexts/userdata";
import { MapScrollProvider } from "./contexts/map";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";

import * as moment from "moment";

moment.locale("de");

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#E3010F",
    },
    secondary: {
      main: "#424242",
    },
    warning: {
      main: "#ffc300",
    },
    background: {
      paper: "#fff",
      default: "#F4F4F4",
    },
  },
  typography: {
    fontFamily: ["CPN"].join(","),
    h2: {
      fontSize: "1.875rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.875rem",
    },
    h4: {
      fontSize: "1.25rem",
    },
    h5: {
      fontSize: "1.125rem",
    },
    h6: {
      fontSize: "1rem",
    },
  },
  shape: {
    borderRadius: 15,
    borderColor: "rgba(255,255,255,0)",
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  spacing: 8,
});

theme.shadows[10] =
  "0 0 8px 1px rgba(0, 0, 0, 0.04), 0 8px 16px -3px rgba(0, 0, 0, 0.08), 0 4px 18px -2px rgba(0, 0, 0, 0.02)";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <AuthProvider>
          <UserDataProvider>
            <MapScrollProvider>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <CssBaseline />
                  <ResponsiveAppBar />
                  <Routes />
                </Router>
              </QueryClientProvider>
            </MapScrollProvider>
          </UserDataProvider>
        </AuthProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
