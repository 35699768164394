import { Alert, Autocomplete, Box, Button, Chip, Divider, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { savePersonalCertificationsData, useUserDataDispatch, useUserDataState } from '../../contexts/userdata';
import useGeneralData from '../../hooks/useGeneralData';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


function PersonalCertificationsData(props) {
    const { personal, loading } = useUserDataState();
    const { data: generalData } = useGeneralData();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const buttonLabel = isSmall ? `Hinzu`: `Hinzufügen`;

    const defaultFormObj = {
        certifications: personal.certifications,
        certSuggestions: personal.certSuggestions
    };
    const defaultMsgObj = {str:"",type:""};
    const [form, setForm] = useState(defaultFormObj);
    const [newCert, setNewCert] = useState("");
    const [newSuggestion, setNewSuggestion] = useState("");
    const [randomKey, setRandomKey] = useState(_.uniqueId());
    const [msg, setMsg] = useState(defaultMsgObj);
    const dispatch = useUserDataDispatch();
    
    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultFormObj);
        setMsg(defaultMsgObj);
    }
    
    const handleDelete = certId => {
        const certs = form.certifications.filter(item => item !== certId.toString());
        setRandomKey(_.uniqueId());
        setNewCert("");
        setForm({
            ...form,
            certifications: certs
        })
        setMsg(defaultMsgObj);
    }
    
    const handleDeleteSuggestion = cert => {
        const certs = form.certSuggestions.filter(item => item !== cert);
        setForm({
            ...form,
            certSuggestions: certs
        })
        setMsg(defaultMsgObj);
    }
    
    const handleChangeCertSuggestion = evt => {
        evt.preventDefault();
        setNewSuggestion(evt.target.value);
        setMsg(defaultMsgObj);
    }

    const handleAddSuggestion = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            certSuggestions: [
                ...form.certSuggestions,
                newSuggestion
            ]
        })
        setNewSuggestion("");
        setMsg(defaultMsgObj);
    }

    const handleAdd = evt => {
        evt.preventDefault();
        const item = generalData.certifications.find(item => item.name === newCert);
        setRandomKey(_.uniqueId());
        setNewCert("");
        setForm({
            ...form,
            certifications: [
                ...form.certifications,
                item.id
            ]
        })
        setMsg(defaultMsgObj);
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
        if(_.isEqual(defaultFormObj, form)) {
            return;
        }
        const fields = {
            certifications: form.certifications,
            certSuggestions: form.certSuggestions
        };
        const res = await savePersonalCertificationsData(dispatch, fields);
        if(res?.personal) {
            const defaultFormObj = {
                certifications: res.personal.certifications,
                certSuggestions: res.personal.certSuggestions
            };
            setForm(defaultFormObj);
            setMsg({str:"Erfolgreich gespeichert",type:"success"});
        } else {
            setMsg({str:"Ein Fehler ist aufgetreten — nicht gespeichert",type:"error"});
        }
        
    }

    const alreadyExists = () => {
        return generalData.certifications.find(item => item.name.toLowerCase() === newSuggestion.toLowerCase())!==undefined || form.certSuggestions.find(item => item.toLowerCase() === newSuggestion.toLowerCase())!==undefined;
    }


	return (
		<Box sx={{mt:3}}>
			<Typography variant="h2">
				Zertifizierungen
			</Typography>
            <Paper elevation={10}>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>
                <Typography variant="h5" sx={{fontWeight:500,mb:2}}>
                    Ausgewählt
                </Typography>
                <Stack direction="row" spacing={0} sx={{mb:2, flexWrap: "wrap"}} maxWidth="md">
                    {
                        form.certifications.length > 0 && form.certifications.map((certId, i) =>
                            {
                                const certObject = generalData.certifications.find(item => item.id === certId);
                                if(!certObject) {
                                    return null;
                                }
                                return (
                                    <Chip key={i} label={certObject.name} onDelete={() => handleDelete(certObject.id)} sx={{mx: .5, mb: 1}} />
                                )
                            }
                        )
                    }
                    {
                        form.certifications.length === 0 && <Typography variant="button" sx={{mb:2, fontSize: ".8rem", color:"#888"}}>Keine Zuordnungen vorhanden</Typography>
                    }
                </Stack>
                <Typography variant="h5" sx={{fontWeight:500,mb:1}}>
                    Hinzufügen
                </Typography>
                <Grid container columnSpacing={2}>
                    <Grid item xs={8}>
                        <Autocomplete
                            key={randomKey}
                            clearOnBlur
                            noOptionsText="Keine Zertifizierungen gefunden"
                            options={generalData.certifications.filter(item => !form.certifications.includes(item.id)).map(option => option.name)}
                            name="newCerticationField"
                            inputValue={newCert}
                            onInputChange={(event, newValue) => {
                                setNewCert(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Zertifizierung auswählen" variant="outlined" sx={{mt: "5px"}} inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'
                                }} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{display:"flex", alignItems: "center"}}>
                        <Button
                            disabled={newCert==="" || !generalData.certifications.find(item => item.name === newCert)}
                            onClick={handleAdd}
                            variant="contained"
                            fullWidth
                        >
                            {buttonLabel}
                        </Button>
                    </Grid>
                </Grid>
                <Divider sx={{mt: 5, mb: 3}} />
                <Typography variant="h5" sx={{fontWeight:500, mt: 0}}>
                    Eigene Vorschläge
                </Typography>
                <Typography sx={{mb:2, fontSize: ".75rem"}}>
                    {`(werden von Partnerportal Admins geprüft und ggfls. freigeschaltet)`}
                </Typography>
                <Stack direction="row" spacing={0} sx={{mb:2, flexWrap: "wrap"}} maxWidth="md">
                    {
                        form.certSuggestions.length > 0 && form.certSuggestions.map((item, i) =>
                            {
                                return (
                                    <Chip key={i} label={item} onDelete={() => handleDeleteSuggestion(item)} sx={{mx: .5, mb: 1}} />
                                )
                            }
                        )
                    }
                    {
                        form.certSuggestions.length === 0 && <Typography variant="button" sx={{mb:2, fontSize: ".8rem", color:"#888"}}>Keine Vorschläge vorhanden</Typography>
                    }
                </Stack>
                <Typography variant="h5" sx={{fontWeight:500,mb:1}}>
                    Hinzufügen
                </Typography>
                <Grid container columnSpacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            id="newsuggestion"
                            name="newsuggestion"
                            label="Vorschlag Zertifizierung"
                            variant="outlined"
                            fullWidth
                            disabled={form.certSuggestions.length > 2}
                            value={newSuggestion ?? ""}
                            onChange={handleChangeCertSuggestion}
                            error={alreadyExists()}
                            helperText={alreadyExists() ? "Diese Zertifizierung existiert bereits" : ""}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{display:"flex", alignItems: "center"}}>
                        <Button
                            disabled={form.certSuggestions.length > 2 || newSuggestion==="" || alreadyExists()}
                            onClick={handleAddSuggestion}
                            variant="contained"
                            fullWidth
                        >
                            {buttonLabel}
                        </Button>
                    </Grid>
                </Grid>
                {
                    msg?.str && <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                {
                    form.certSuggestions.length > 2 && _.isEqual(defaultFormObj, form) && 
                    <Alert sx={{mt: 2}} severity="info">
                        Mehr als 3 Vorschläge auf einmal nicht möglich. Bitte warten Sie, bis die bisherigen Vorschläge von den Partnerportal Admins bearbeitet wurden.
                    </Alert>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 3, mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading || _.isEqual(defaultFormObj, form)}
                            >
                            { !loading ? "Daten speichern" : "Verarbeite…" }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            disabled={_.isEqual(defaultFormObj, form)}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
             </Box>
            </Paper>
		</Box>
	);
}

export default PersonalCertificationsData;
