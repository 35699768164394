import { API_URL, REALM, namespaced } from ".";

const apiService = async (endpoint, options = {}) => {
    const defaultOptions = {
        method: 'GET',
        payload: null,
        token: null,
        useTokenAuth: false,
    };
    if(options.useTokenAuth) {
      options.headers = {
        ...options.headers,
        Token: localStorage.getItem(namespaced('token'))
      }
    }
    const requestHeaders = new Headers({
        ...options.headers,
        Accept: 'application/json',
        "Content-Type": 'application/json',
        Realm: REALM
    });

    const {
      payload,
      method
    } = {
      ...defaultOptions,
      ...options,
    };

    const url = new URL(`${API_URL}/${endpoint}`);

    let requestBody = null;
    if (method === 'GET' || method === 'HEAD') {
      if (payload) {
        url.search = new URLSearchParams(payload).toString();
      }
    } else {
      requestBody = payload ? JSON.stringify(payload) : null;
    }
    const response = await fetch(url, { ...defaultOptions, ...options, body: requestBody, headers: requestHeaders })
    const text = await response.text()
    const o = {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
    };
    let status = o.status, statusText = o.statusText, headers = o.headers, body = o.body;
    let json;
    try {
        json = JSON.parse(body);
    } catch (e) {
        // not json, no big deal
    }
    if (status < 200 || status >= 300) {
        var errMsg = statusText;
        if(json && json.errors) {
          if(json.errors.messages) {
            errMsg = json.errors.messages.join(" / ");
          } else if(json.errors.message) {
            errMsg = json.errors.message;
          }
          
        }
        return Promise.reject(new Error(`${errMsg}|||${status}`));
    }
    const token = headers.get('token');
    if(token) {
      localStorage.setItem(namespaced('token'), token);
    }
    
    // return Promise.resolve({ status: status, headers: headers, body: body, json: json });
    return Promise.resolve(json.data);
  };
  export default apiService;