import React, { useState } from 'react';
import { confirmPassword, useAuthState, useAuthDispatch } from '../contexts/auth';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LogoFull from '../components/misc/logos/LogoFull';
import { validatePassword } from '../utils';
import usePasswordToken from '../hooks/usePasswordToken';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { PATHNAMES } from '../routes/pathnames'
import LoadingView from '../components/misc/LoadingView';
import { userType as getUserType } from '../utils';

export default function SetNewPassword() {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [msg, setMsg] = useState('');
    const [userType, setUserType] = useState("USER");

	const { loading, error, loggedIn } = useAuthState();
    const history = useHistory();
    const location = useLocation();
    const passwordType = location.pathname.includes(PATHNAMES.PASSWORDRESET) ? "reset": "initial";
    const { token } = useParams();

	const { status, data, error: tokenError } = usePasswordToken(passwordType, token ?? "", loggedIn);

	const dispatch = useAuthDispatch();

    if(status === "loading") {
        return <LoadingView />;
    }

	const handleSubmit = async (e) => {
		e.preventDefault();
        if(password==="" || password!==passwordConfirm || !validatePassword(password)) {
            return;
        }
        let response = await confirmPassword(dispatch, { user: data.uuid, password, passwordConfirm });
        if (!response) return;
        if(response.success) {
            setMsg("Ihr neues Passwort wurde erfolgreich gespeichert.");
            setUserType(response.userType);
        }
        
	};

    const goToHome = e => {
        if(loggedIn) {
            const USER_TYPE = getUserType();
            if(USER_TYPE!=="USER") {
                history.push(PATHNAMES.DASHBOARD);
            } else {
                history.push(PATHNAMES.SERVICES);
            }
        } else {
            if(userType!=="USER") {
                history.push(PATHNAMES.DASHBOARD);
            } else {
                history.push(PATHNAMES.SERVICES);
            }
        }
        
    }


  return (
      <Container component="main" maxWidth="xs">
        <Paper>
            <Box
                sx={{
                    p: 3,
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <LogoFull styles={{maxWidth: "250px"}} />
                <Typography component="h1" variant="h5">
                    Neues Passwort festlegen
                </Typography>

                { msg==="" && status!=="error" && !loggedIn ?
                    (
                        <Typography variant="caption" sx={{textAlign: "center",mt:1}}>
                            {`Ein Kennwort muss zwischen 8 und 50 Zeichen lang sein. Mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und eines dieser Sonderzeichen enthalten: !@#§$%^&*()-_=+{};:,<.>?/€~£¥|`}
                        </Typography>
                    ):
                    (
                        <Box sx={{p:0}}>
                            { status!=="error" &&
                                <Box sx={{mt:1}}>
                                    <Alert severity="success">{loggedIn && msg==="" ? `Sie sind bereits eingeloggt`: msg}</Alert>
                                    <Button sx={{mt: 2}} variant="contained" onClick={goToHome} fullWidth>
                                        Zur Startseite
                                    </Button>
                                </Box>
                            }
                        </Box>
                    )
                }
                { msg==="" && status!=="error" && !loggedIn &&
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Passwort"
                            name="password"
                            type="password"
                            autoFocus
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={loading || msg!==""}
                            helperText={password!=="" && !validatePassword(password) ? "Die Bedingungen an ein Passwort sind nicht erfüllt" : ""}
                            error={password!=="" && !validatePassword(password)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="passwordConfirm"
                            label="Passwort wiederholen"
                            name="passwordConfirm"
                            type="password"
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                            disabled={loading || msg!=="" || !validatePassword(password)}
                            helperText={password!=="" && passwordConfirm!=="" && validatePassword(password) && password!==passwordConfirm ? "Passwörter stimmen nicht überein" : ""}
                            error={password!=="" && passwordConfirm!=="" && validatePassword(password) && password!==passwordConfirm}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading || password==="" || msg!=="" || !validatePassword(password) || password!==passwordConfirm}
                            >
                            { !loading ? "Neues Passwort festlegen" : "Verarbeite…" }
                        </Button>
                        {
                            (error && !loading && msg==="") && <Alert severity="error">{error}</Alert>
                        }
                    </Box>
                }
            </Box>

            {
                (status==="error" && tokenError && tokenError!=="" && !loading && msg==="") && <Box sx={{p:2}}><Alert severity="error">{tokenError.message.split("|||")[0]}</Alert></Box>
            }
        </Paper>
      </Container>
  );
}