import { Paper, Select, MenuItem, Grid, Button, InputLabel, TextField, Autocomplete, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import useGeneralData from '../../hooks/useGeneralData';
import { PATHNAMES } from '../../routes/pathnames';
import { sortingOptions } from '../../utils/sortingOptions';
import { stopwords } from '../../utils/stopwords';

const SmallTextField = styled(TextField)({
    '& input.MuiInput-input': {
        fontSize: "0.8rem"
    }
  });


const CustomPopper = function (props) {
    return <Popper {...props} style={{fontSize: "0.8rem",width: "200px"}} placement="bottom-start" />;
};
const CustomPopperLarge = function (props) {
    return <Popper {...props} style={{fontSize: "0.8rem",width: "330px"}} placement="bottom-start" />;
};

const emptyLabelForAutocomplete = "alle";
export default function CompaniesListFilter(props) {
    const history = useHistory();
    const { status, data } = useGeneralData();

    const [query, setQuery] = useState(props.options);


    useEffect(() => {
        if(props.options.length === 0) {
            return;
        }
        setQuery(props.options);
    },[props.options]);


    if(status!=="success") {
        return null;
    }

    let manufacturers = data.manufacturers.slice(0);
    manufacturers.unshift({id:"0",name:"alle"});

    let partnerstatus = data.partnerstatus.slice(0);
    partnerstatus.unshift({id:"0",name:"alle"});

    const handleChange = e => {
        setQuery(
            {
                ...query,
                [e.target.name]: e.target.value
            }
        );
    }

    const handleChangeInt = e => {
        setQuery(
            {
                ...query,
                [e.target.name]: parseInt(e.target.value, 10)
            }
        );
    }

    const handleClick = e => {
        let filterArray = [];
        if(query.q!=="" && !stopwords.includes(query.q.trim()) && query.q.trim().length > 2) {
            filterArray.push(`q=${query.q.trim()}`);
        }
        if(query.z && query.z.trim().length === 5) {
            filterArray.push(`z=${query.z}`);
        }
        if(query.r && parseInt(query.r, 10) > 0) {
            filterArray.push(`r=${parseInt(query.r, 10)}`);
        }
        if(query.m && parseInt(query.m, 10) > 0) {
            filterArray.push(`m=${parseInt(query.m, 10)}`);
        }
        if(query.p && parseInt(query.p, 10) > 0) {
            filterArray.push(`p=${parseInt(query.p, 10)}`);
        }
        if(query.s && parseInt(query.s, 10) > 0 && sortingOptions.find(item => item.num === parseInt(query.s, 10))) {
            filterArray.push(`s=${sortingOptions.find(item => item.num === parseInt(query.s, 10)).type}`);
        }
        if(filterArray.length === 0) {
            history.push(
                `${PATHNAMES.COMPANIES}`
            );
            return;
        }
        history.push(
            `${PATHNAMES.COMPANIES}?${filterArray.join("&")}`
        )
    }

    const preventSubmit = () => {
        if(query.q!=="" || query.z!=="" || query.s!=="" || query.r>0 || query.m>0 || query.p>0) {
            return false;
        }
        return true;
    }

    return (
        <Fragment>
            <Paper elevation={props.isAccordion ? 0 : 10} sx={!props.isAccordion ? {px:2, py: 1, mb: 4}: {}}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item xs={12} sm={6} md={2}>
                        <InputLabel sx={{fontSize: "0.7rem"}} id="freetextlabel">Freitext (mind. 3 Zeichen)</InputLabel>
                        <SmallTextField
                            id="freetext"
                            value={query.q}
                            name="q"
                            variant="standard"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" && !preventSubmit()) {
                                    if(e.target.value.length>0) {
                                        if(e.target.value.length>2) {
                                            handleClick();
                                        }
                                    } else {
                                        handleClick();
                                    }
                                }
                            }}
                            fullWidth
                            sx={{mt: "5px"}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={1}>
                        <InputLabel sx={{fontSize: "0.7rem"}} id="radiuslabel">Im Umkreis von</InputLabel>
                        <Select
                            labelId="radiuslabel"
                            value={query.r}
                            onChange={handleChangeInt}
                            name="r"
                            fullWidth
                            variant="standard"
                            sx={{fontSize: "0.8rem", mt: "5px"}}
                        >
                            <MenuItem value={0}>ohne</MenuItem>
                            { [5,10,25,50,100,150,200,250,300].map((item, i) => <MenuItem key={i} value={item} disabled={query.r===item}>{`${item} km`}</MenuItem>) }
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <InputLabel sx={{fontSize: "0.7rem"}} id="zipcodelabel">Umkreis Plz (5 Ziffern)</InputLabel>
                        <SmallTextField
                            id="zipcode"
                            value={query.z}
                            name="z"
                            variant="standard"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" && !preventSubmit()) {
                                    if(e.target.value.length>0) {
                                        if(e.target.value.length===5) {
                                            handleClick();
                                        }
                                    } else {
                                        handleClick();
                                    }
                                }
                            }}
                            fullWidth
                            sx={{mt: "5px"}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <InputLabel sx={{fontSize: "0.7rem"}} id="manufacturerlabel">Hersteller</InputLabel>
                        <Autocomplete
                            PopperComponent={CustomPopper}
                            noOptionsText="Keine Hersteller gefunden"
                            options={manufacturers.map(option => option.name)}
                            value={manufacturers.find(item => parseInt(item.id, 10) === parseInt(query.m, 10))?.name ?? emptyLabelForAutocomplete}
                            name="m"
                            onChange={(event, newValue) => {
                                const newId = parseInt(manufacturers.find(item => item.name === newValue)?.id, 10) ?? 0;
                                setQuery(
                                    {
                                        ...query,
                                        m: newId
                                    }
                                );
                            }}
                            renderInput={(params) => (
                                <SmallTextField
                                    {...params}
                                    label=""
                                    variant="standard"
                                    sx={{mt: "5px"}}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter" &&
                                            !preventSubmit()
                                        ) {
                                            if(params.inputProps.value!=="") {
                                                if(params.inputProps.value===emptyLabelForAutocomplete || manufacturers.find(item => item.name === params.inputProps.value)) {
                                                    handleClick();
                                                }
                                            } else {
                                                handleClick();
                                            }
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <InputLabel sx={{fontSize: "0.7rem"}} id="certslabel">Partnerstatus</InputLabel>
                        <Autocomplete
                            PopperComponent={CustomPopperLarge}
                            noOptionsText="Kein Status gefunden"
                            options={partnerstatus.map(option => option.name)}
                            value={partnerstatus.find(item => parseInt(item.id, 10) === parseInt(query.p, 10))?.name ?? emptyLabelForAutocomplete}
                            name="p"
                            onChange={(event, newValue) => {
                                const newId = parseInt(partnerstatus.find(item => item.name === newValue)?.id, 10) ?? 0;
                                setQuery(
                                    {
                                        ...query,
                                        p: newId
                                    }
                                );
                            }}
                            renderInput={(params) => (
                                <SmallTextField
                                    {...params}
                                    label=""
                                    variant="standard"
                                    sx={{mt: "5px"}}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter" &&
                                            !preventSubmit()
                                        ) {
                                            if(params.inputProps.value!=="") {
                                                if(params.inputProps.value===emptyLabelForAutocomplete || partnerstatus.find(item => item.name === params.inputProps.value)) {
                                                    handleClick();
                                                }
                                            } else {
                                                handleClick();
                                            }
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <InputLabel sx={{fontSize: "0.7rem"}} id="sortinglabel">Sortierung</InputLabel>
                        <Select
                            labelId="sortinglabel"
                            value={query.s}
                            onChange={handleChange}
                            name="s"
                            fullWidth
                            variant="standard"
                            sx={{fontSize: "0.8rem", mt: "5px"}}
                        >
                            <MenuItem value={0}>alle</MenuItem>
                            { sortingOptions.map((item, i) => <MenuItem key={i} value={item.num} disabled={query.s===item.num}>{item.label}</MenuItem>) }
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                        <Button variant="outlined" disabled={preventSubmit()} onClick={handleClick} fullWidth sx={{mt:1, mb:.5}}>
                            Filtern
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Fragment>
    );
}

CompaniesListFilter.defaultProps = {
    isAccordion: false
}