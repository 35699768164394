
import { stopwords } from './stopwords';
import { sortingOptions } from './sortingOptions';

export function companiesFilter(query) {
    const freeText = query.get("q");
    const zipcode = query.get("z");
    const radius = query.get("r");
    const manufacturer = query.get("m");
    const partnerstatus = query.get("p");
    const sort = query.get("s");
	let sortNum = 0;

	let filterArray = [];
	if(freeText && !stopwords.includes(freeText.trim()) && freeText.trim().length > 2) {
		filterArray.push(`q=${freeText.trim()}`);
	}
	if(zipcode && zipcode.trim().length === 5) {
		filterArray.push(`z=${zipcode}`);
	}
	if(radius && parseInt(radius, 10) > 0) {
		filterArray.push(`r=${parseInt(radius, 10)}`);
	}
	if(manufacturer && parseInt(manufacturer, 10) > 0) {
		filterArray.push(`m=${parseInt(manufacturer, 10)}`);
	}
	if(partnerstatus && parseInt(partnerstatus, 10) > 0) {
		filterArray.push(`p=${parseInt(partnerstatus, 10)}`);
	}
	if(sort && sort.trim().length > 0 && sortingOptions.find(item => item.type === sort.trim())) {
		filterArray.push(`s=${sort}`);
		sortNum = sortingOptions.find(item => item.type === sort.trim()).num;
	}

    const filterOptions = {
		q: freeText ?? "",
		z: zipcode ?? "",
		r: radius ?? 0,
		m: manufacturer ?? 0,
		p: partnerstatus ?? 0,
		s: sortNum
	};

    return {
        filterArray,
        filterOptions
    }
}