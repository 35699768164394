import React, { Fragment, useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFilterCompanies from "../../hooks/useFilterCompanies";
import CompaniesListFilter from "./CompaniesListFilter";
import LoadingView from "../misc/LoadingView";
import ErrorView from "../misc/ErrorView";
import PartnerMap from "../map/PartnerMap";
import { useUserDataState } from "../../contexts/userdata";
import { gmapsDefaultOptions } from "../../utils/gmapsOptions";
import { companiesFilter } from "../../utils/companiesFilter";
import { useMapScrollState } from "../../contexts/map";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function CompaniesList(props) {
  const { gmaps } = useUserDataState();
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const tabletScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const [allCompanies] = useState(props.companies);
  const [locations, setLocations] = useState(props.companies);
  const [msg, setMsg] = useState("");

  const { filterArray, filterOptions } = companiesFilter(useQuery());

  const searchFor = filterArray.length > 0 ? filterArray.join("&") : "";
  const { status, data } = useFilterCompanies(searchFor);
  const { mapScrollIndex } = useMapScrollState();

  useEffect(() => {
    if (searchFor === "") {
      setLocations(allCompanies);
    }
    if (!data || data.length === 0) {
      return;
    }
    if (data.error !== "") {
      setMsg(data.error);
    } else {
      setLocations(data.companies);
      setMsg("");
    }
  }, [searchFor, data, allCompanies]);

  if (status === "error") {
    return <ErrorView />;
  }

  return (
    <Fragment>
      {msg !== "" && status !== "loading" && (
        <Alert severity="info">{msg}</Alert>
      )}
      <Box sx={{ mb: largeScreen ? 0 : 2 }}>
        {largeScreen ? (
          <CompaniesListFilter options={filterOptions} />
        ) : (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-content"
              id="filter-header"
            >
              <Typography>Suche/Filter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CompaniesListFilter isAccordion={true} options={filterOptions} />
            </AccordionDetails>
          </Accordion>
        )}
      </Box>

      {gmaps === "" ? (
        <LoadingView />
      ) : (
        <PartnerMap
          gmaps={gmaps}
          largeScreen={largeScreen}
          tabletScreen={tabletScreen}
          locations={locations}
          isLoading={status === "loading"}
          mapScrollIndex={mapScrollIndex}
          options={{
            ...gmapsDefaultOptions,
            zoom: 10,
          }}
        />
      )}
    </Fragment>
  );
}

export default CompaniesList;
