import apiService from '../../utils/apiService';
import { getUserStorage } from '../../utils';

export async function loadUserData(dispatch) {
    try {
        dispatch({ type: 'REQUEST_USER_DATA' });
        const data = await apiService('get-userdata', { useTokenAuth: true });
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'REQUEST_USER_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'REQUEST_USER_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function savePersonalData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_PERSONAL_DATA' });
        const data = await apiService(`set-userdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "personal" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_PERSONAL_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_PERSONAL_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}


export async function savePersonalCertificationsData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_PERSONAL_CERTIFICATIONS_DATA' });
        const data = await apiService(`set-userdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "certifications" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_PERSONAL_CERTIFICATIONS_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_PERSONAL_CERTIFICATIONS_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function saveBusinessAddressData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_BUSINESS_ADDRESS_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "address" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_BUSINESS_ADDRESS_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_BUSINESS_ADDRESS_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function saveBusinessContactPersonsData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_BUSINESS_CONTACT_PERSONS_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "contactpersons" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_BUSINESS_CONTACT_PERSONS_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_BUSINESS_CONTACT_PERSONS_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function saveBusinessContactPersonMainData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_BUSINESS_CONTACT_PERSON_MAIN_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "contactpersonmain" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_BUSINESS_CONTACT_PERSON_MAIN_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_BUSINESS_CONTACT_PERSON_MAIN_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function saveBusinessPartnerstatusData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_BUSINESS_PARTNERSTATUS_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "partnerstatus" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_BUSINESS_PARTNERSTATUS_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_BUSINESS_PARTNERSTATUS_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}


export async function saveBusinessManufacturersData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_BUSINESS_MANUFACTURERS_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "manufacturers" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_BUSINESS_MANUFACTURERS_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_BUSINESS_MANUFACTURERS_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function saveBusinessCustomerGroupsData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_BUSINESS_CUSTOMERGROUPS_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "customergroups" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_BUSINESS_CUSTOMERGROUPS_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_BUSINESS_CUSTOMERGROUPS_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function addBusinessEmployeesData(dispatch, payload) {
    try {
        dispatch({ type: 'ADD_BUSINESS_EMPLOYEES_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "employee-add" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'ADD_BUSINESS_EMPLOYEES_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'ADD_BUSINESS_EMPLOYEES_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function deleteBusinessEmployeesData(dispatch, payload) {
    try {
        dispatch({ type: 'DELETE_BUSINESS_EMPLOYEES_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "employee-delete" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'DELETE_BUSINESS_EMPLOYEES_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'DELETE_BUSINESS_EMPLOYEES_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function editBusinessEmployeesData(dispatch, payload) {
    try {
        dispatch({ type: 'EDIT_BUSINESS_EMPLOYEES_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "employee-edit" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'EDIT_BUSINESS_EMPLOYEES_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'EDIT_BUSINESS_EMPLOYEES_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function saveBusinessTextData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_BUSINESS_TEXT_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "text" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_BUSINESS_TEXT_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_BUSINESS_TEXT_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function savePortraitData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_PORTRAIT_DATA' });
        const data = await apiService(`set-userdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "portrait" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_PORTRAIT_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_PORTRAIT_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function saveBusinessLogoData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_BUSINESS_LOGO_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "logo" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_BUSINESS_LOGO_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_BUSINESS_LOGO_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function saveLoginData(dispatch, payload) {
    try {
        dispatch({ type: 'SAVE_LOGIN_DATA' });
        const data = await apiService(`set-userdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "credentials" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'SAVE_LOGIN_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'SAVE_PERSONAL_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function unsetUserData(dispatch) {
    dispatch({ type: 'UNSET_USER_DATA' });
}



export async function addBusinessLocationsData(dispatch, payload) {
    try {
        dispatch({ type: 'ADD_BUSINESS_LOCATIONS_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "locations-add" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'ADD_BUSINESS_LOCATIONS_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'ADD_BUSINESS_LOCATIONS_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function deleteBusinessLocationsData(dispatch, payload) {
    try {
        dispatch({ type: 'DELETE_BUSINESS_LOCATIONS_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "locations-delete" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'DELETE_BUSINESS_LOCATIONS_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'DELETE_BUSINESS_LOCATIONS_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function editBusinessLocationsData(dispatch, payload) {
    try {
        dispatch({ type: 'EDIT_BUSINESS_LOCATIONS_DATA' });
        const data = await apiService(`set-businessdata/${getUserStorage().uuid}`, { method: "PUT", useTokenAuth: true, payload: {...payload, action: "locations-edit" }});
        // const jsonData = data.json;
        if (data) {
            dispatch({ type: 'EDIT_BUSINESS_LOCATIONS_DATA_SUCCESS', payload: data });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'EDIT_BUSINESS_LOCATIONS_DATA_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}
