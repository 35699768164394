import { useQuery } from "react-query";
import apiService from '../utils/apiService';
import { useHistory } from "react-router-dom";
import { useAuthState } from '../contexts/auth';
import { LOGOUT_ROUTE } from "../utils";
import { getUseQueryOptions } from ".";


export default function useFilterCompanies(query) {
    const history = useHistory();
    const userDetails = useAuthState();

    const url = `filtercompanies?${query}`;

    return useQuery(query.concat(userDetails.uuid), async () => {
        const data = await apiService(url, { useTokenAuth: true });
        return data;
    }, getUseQueryOptions(
        {
            onError:() => history.push(LOGOUT_ROUTE),
            retry: 0,
            enabled: query !== "",
        }
    ));

}
