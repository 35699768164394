import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { salutations } from '../../utils';
import SectionHeadline from '../misc/SectionHeadline';

export default function CompaniesEmployees(props) {

    if(!props.employees || props.employees.length===0) {
        return null;
    }

  return (
    <Fragment>
        <Divider />
        <Box sx={{my: 4}}>
            <SectionHeadline>Mitarbeiter*innen</SectionHeadline>
            {
                props.employees.map(
                    item => {
                        return (
                            <Box key={item.email} sx={{p:0, mb: 2}}>
                                <Typography variant="h5" sx={{fontWeight: 500}}>{`${salutations[item.salutation]} ${item.firstname} ${item.lastname}`}</Typography>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">E-Mail: {`${item.email ?? "k.A."}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">Telefon: {`${item.phone ?? "k.A."}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1">Position: {`${item.jobPosition ?? "k.A."}`}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    }
                )
            }
        </Box>
    </Fragment>
  );
}

CompaniesEmployees.propTypes = {
    employees: PropTypes.array,
};