import React from 'react';
import { Container } from '@mui/material';
import BusinessEmployeesData from '../components/account/BusinessEmployeesData';
import { useUserDataState } from '../contexts/userdata';

function BusinessEmployees(props) {
	const { initialized } = useUserDataState();

	if(!initialized) {
		return null;
	}

	return (
		<Container maxWidth="md" sx={{ mt: "-20px", mb: 4}} className="no-padding">
			<BusinessEmployeesData />
		</Container>
	);
}

export default BusinessEmployees;
