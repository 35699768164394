import { useRef, useState, Fragment } from "react";
import { Box, Button, Alert, Typography }  from "@mui/material";
import Resizer from "react-image-file-resizer";
import AddIcon from '@mui/icons-material/Add';

const resizeFile = (file, type) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            type==="portrait" ? 400 : 600,
            600,
            "JPEG",
            100,
            0,
            (uri) => {
            resolve(uri);
            },
            "base64"
        );
    }
);



const FileInput = (props) => {
  const ref = useRef();
  const [attachment, setAttachment] = useState(null);
  const [error, setError] = useState("");
  const maxUploadSize = props.type==="portrait" ? 256000 : 512000;

  const handleChange = async (event) => {

    if (event.target.files > 1) {
        setError("Nur eine Datei pro Upload erlaubt");
        setAttachment(null);
        return;
      } else if (new RegExp([".php",".php5",".js"].join("|")).test(event.target.files[0].name)) {
        setError("Falsches Dateiformat (erlaubt sind PNG oder JPG)");
        setAttachment(null);
        return;
      } else if (!new RegExp(["png","jpg","jpeg"].join("|")).test(event.target.files[0].type)) {
        setError("Falsches Dateiformat (erlaubt sind PNG oder JPG)");
        setAttachment(null);
        return;
      } else if (event.target.files[0].size > maxUploadSize) {
        setError(`Die Datei ist zu gross (ist ${(Math.round(parseInt(event.target.files[0].size, 10)/1000))}kb gross)`);
        setAttachment(null);
        return;
      } else if(event.target.files[0].errors && event.target.files[0].errors.length) {
        if(event.target.files[0].errors[0].code==='file-invalid-type') {
            setError("Falsches Dateiformat (erlaubt sind PNG oder JPG)");
            setAttachment(null);
            return;
        } else {
            setError(event.target.files[0].errors.message);
            setAttachment(null);
            return;
        }
    }

    try {
      const file = event.target.files[0];
      const image = await resizeFile(file, props.type);
      setAttachment(image);
      props.onChange(image);
    } catch (err) {
      setAttachment(null);
      props.onChange(null);
    }
  };

  return (
    <Box>
        <Typography variant="h4">
            {
              props.type==="portrait" ?
              "Upload neues Portrait" : 
              "Upload neues Logo"
            }
        </Typography>
        <Typography variant="body2">
            { props.type==="portrait" && <Fragment>Vorzugsweise im Hochformat,<br /></Fragment> }
            erlaubte Dateitypen: JPG oder PNG,<br />maximale Dateigrösse {(maxUploadSize/1000).toString()}kb
            { props.type==="logo" && <Fragment><br />Am besten mit wenig weißer Fläche an den Rändern</Fragment> }
        </Typography>
        { attachment && 
            <><Box
                component="img"
                sx={{
                height: "auto",
                mx: "auto",
                my: 2,
                width: "100%",
                display: "block",
                maxWidth: { xs: 120, md: 200 },
                }}
                alt="Neuer Upload"
                src={attachment}
            />
                <Typography variant="body2" sx={{display:"block"}}>
                    (Wird verkleinert dargestellt)
                </Typography>
            </>
        }
        <Button sx={{mt:2}} variant="contained" component="label" color="primary" onKeyDown={(e) => e.keyCode === 32 && ref.current?.click()} onClick={() => {setError("");setAttachment(null);}}>
            <AddIcon/> Datei auswählen
            <input
                ref={ref}
                type="file"
                accept="image/jpg,image/jpeg,image/png"
                hidden
                onChange={handleChange}
            />
        </Button>
        { error && <Alert sx={{mt: 2}} severity="error">{error}</Alert> }
        
    </Box>
  );
};


export default FileInput;
