import React, { Fragment } from 'react';
import { useEffectOnce } from '../../utils/useEffectOnce';
import { useAuthState } from '../../contexts/auth';
import { loadUserData, useUserDataDispatch, useUserDataState } from '../../contexts/userdata';

export default function UserDataLoader() {

    const { loggedIn } = useAuthState();
    const dispatch = useUserDataDispatch();
    const { initialized } = useUserDataState();
  
    useEffectOnce(() => {
        if(!loggedIn || initialized) {
            return;
        }
        const fetchData = async () => {
            await loadUserData(dispatch);
        };
        fetchData();
        
    // eslint-disable-next-line
    }, [loggedIn, initialized]);

    return (
        <Fragment />
    );
};