import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography } from '@mui/material';

export default function ErrorView() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <ErrorOutlineIcon color="error" /><br />
      <Typography>Ein Fehler ist aufgetreten</Typography>
    </Box>
  );
}