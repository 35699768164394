import { Box, Typography, Grid } from "@mui/material";
import CompaniesCard from "../companies/CompaniesCard";
import NewsCard from "../news/NewsCard";
import EventsCard from "../events/EventsCard";
import PropTypes from "prop-types";
import LinkToButton from "./LinkToButton";
import { PATHNAMES } from "../../routes/pathnames";

const SECTION_TITLES = {
  dashboard: {
    news: "Aktuelle Neuigkeiten",
    events: "Die nächsten Termine",
  },
  search: {
    companies: "Partner",
    news: "News",
    events: "Termine",
  },
  news: {
    news: "Alle Neuigkeiten",
  },
  events: {
    events: "Alle Termine",
  },
};

export default function CardsResultGrid(props) {
  return (
    <Box sx={{ mb: 8 }}>
      <Typography variant="h3" sx={{ mb: 1, fontWeight: 600 }}>
        {SECTION_TITLES[props.page][props.type]}
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {props.data.map((item, n) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              xl={props.type === "companies" ? 4 : 3}
              key={n}
            >
              {props.type === "companies" && (
                <CompaniesCard
                  {...item}
                  isDashboardOrSearch={
                    props.page === "dashboard" || props.page === "search"
                  }
                />
              )}
              {props.type === "news" && (
                <NewsCard
                  {...item}
                  isDashboardOrSearch={
                    props.page === "dashboard" || props.page === "search"
                  }
                />
              )}
              {props.type === "events" && (
                <EventsCard
                  {...item}
                  isDashboardOrSearch={
                    props.page === "dashboard" || props.page === "search"
                  }
                />
              )}
            </Grid>
          );
        })}
      </Grid>
      {props.page === "dashboard" &&
        (props.type === "news" || props.type === "events") &&
        props.showDetailLink && (
          <Box sx={{ mt: 2 }}>
            <LinkToButton
              link={PATHNAMES[props.type.toUpperCase()]}
              label={
                props.type === "news"
                  ? "Alle News anzeigen"
                  : "Alle Termine anzeigen"
              }
            />
          </Box>
        )}
    </Box>
  );
}

CardsResultGrid.propTypes = {
  page: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  showDetailLink: PropTypes.bool,
};
CardsResultGrid.defaultProps = {
  showDetailLink: false,
};
