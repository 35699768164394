import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import useGeneralData from '../../hooks/useGeneralData';
import SectionHeadline from '../misc/SectionHeadline';

function CompaniesManufacturers(props) {

    const { status, data } = useGeneralData();

    if(status!=="success" || props.manufacturers.length === 0) {
        return null;
    }

    const listing = data.manufacturers.filter(item => {
        return props.manufacturers.includes(item.id);
    }).map(item => item.name);

	return (
        <Box sx={{py: 2}}>
            <SectionHeadline>Hersteller</SectionHeadline>
            <Typography variant="body1" sx={{pb:2}}>
                { listing.length > 0 && listing.join(", ")}
            </Typography>
            <Divider sx={{mt:2}} />
        </Box>
	);
}

export default CompaniesManufacturers;
