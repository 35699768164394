import React, { Component } from "react";

const elemId = "partnermap";

class CompanyMapWithLocations extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            init: false,
            markers: [],
            map: null,
            infoWindow: null
        };
        this.deleteMarkers = this.deleteMarkers.bind(this);
        this.openInfoWin = this.openInfoWin.bind(this);
    }

    deleteMarkers() {
        for (let i = 0; i < this.state.markers.length; i++) {
            this.state.markers[i].setMap(null);
        }
    }


    openInfoWin(num) {
        const currLoc = this.props.locations[num];
        this.state.infoWindow.setContent('<strong>'+currLoc.name+'</strong><br>'+currLoc.street+', '+currLoc.zipcode+' '+currLoc.city);
        this.state.infoWindow.open({
            anchor: this.state.markers[num],
            map: this.state.map,
            shouldFocus: false,
        });
    }

    onScriptLoad() {
        const map = new window.google.maps.Map(
            document.getElementById(elemId),
            this.props.options
        );
        this.addMarker(map);
    }

    addMarker(map) {
        const bounds = new window.google.maps.LatLngBounds();
        const markers = [];
        this.props.locations.forEach((location, i) => {
            let marker = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lng)
                },
                map: map,
                title: location.name
            });
            marker.addListener("click", () => {
                map.setZoom(10);
                map.setCenter(marker.getPosition());
                this.openInfoWin(i);
            });
            markers.push(marker);

            if(this.props.locations.length > 1) {
                bounds.extend(
                    new window.google.maps.LatLng(
                        parseFloat(location.lat),
                        parseFloat(location.lng)
                    )
                );
            }
        });
        if(this.props.locations.length > 1) {
            map.fitBounds(bounds);
        } else {
            map.setCenter({lat: parseFloat(this.props.locations[0].lat), lng: parseFloat(this.props.locations[0].lng)});
            map.setZoom(11);
        }

        const infoWindow = new window.google.maps.InfoWindow({
            content: '',
            maxWidth: 350,
        });

        window.google.maps.event.addListener(map, "click", function(event) {
            infoWindow.close();
        });

          
        this.setState({
            init: true,
            markers: markers,
            map: map,
            infoWindow: infoWindow
        });
    }

  componentDidMount() {
    if (!window.google) {
      if(document.getElementById("gmapsScript")===null) {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.id = "gmapsScript";
        s.src = `https://maps.google.com/maps/api/js?key=${this.props.gmaps}`;
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      }
      
      // Below is important.
      //We cannot access google.maps until it's finished loading
      document.getElementById("gmapsScript").addEventListener("load", e => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    return <div className="companymap" id={elemId} />;
  }
}

export default CompanyMapWithLocations;
