import React, { useState } from 'react';
import { forgotPassword, useAuthState, useAuthDispatch } from '../contexts/auth';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LogoFull from '../components/misc/logos/LogoFull';
import { validateEmail } from '../utils';



export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');

	const dispatch = useAuthDispatch();
	const { loading, error } = useAuthState();

	const handleSubmit = async (e) => {
		e.preventDefault();
        if(email==="" || !validateEmail(email)) {
            return;
        }
        let response = await forgotPassword(dispatch, { email });
        if (!response) return;
        if(response.success) {
            setMsg("Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts geschickt.");
        }
        
	};


  return (
      <Container component="main" maxWidth="xs" className="no-padding">
        <Paper>
            <Box
                sx={{
                    p: 3,
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <LogoFull styles={{maxWidth: "250px"}} />
                <Typography component="h1" variant="h5">
                    Passwort vergessen
                </Typography>

                { msg==="" ?
                    (
                        <Typography variant="caption" sx={{textAlign: "center",mt:1}}>
                            Fordern Sie einen Link zum Zurücksetzen Ihres Passworts an
                        </Typography>
                    ):
                    (
                        <Box sx={{mt:1}}>
                            <Alert severity="success">{msg}</Alert>
                        </Box>
                    )
                }
                { msg==="" &&
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-Mail Adresse"
                            name="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={loading || msg!==""}
                            helperText={email!=="" && !validateEmail(email) ? "Bitte eine gültige E-Mail Adresse angeben" : ""}
                            error={email!=="" && !validateEmail(email)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading || email==="" || msg!=="" || !validateEmail(email)}
                            >
                            { !loading ? "Link anfordern" : "Verarbeite…" }
                        </Button>
                        {
                            (error && !loading && msg==="") && <Alert severity="error">{error}</Alert>
                        }
                    </Box>
                }
            </Box>
        </Paper>
      </Container>
  );
}