import Box from '@mui/material/Box';

export default function CompaniesLogo(props) {
  let sxImg = {
    height: "auto",
    width: "auto",
    maxHeight: { xs: 50, md: 100 },
    maxWidth: { xs: 100, md: 110 },
    position: "absolute", right: 0
  };

  if(props.isDetail) {
    sxImg = {
      ...sxImg,
      maxHeight: "100%",
      maxWidth: "100%",
    }
  }

  return (
    <Box sx={{position: "relative"}}>
      <Box
        component="img"
        sx={sxImg}
        alt={props.alt}
        src={props.src}
      />
    </Box>
  );
}

CompaniesLogo.defaultProps = {
  isDetail: false
}