import Login from "../pages/Login";
import Account from "../pages/Account";
import Dashboard from "../pages/Dashboard";
// import Otherpage from '../pages/Otherpage'
import News from "../pages/News";
import Events from "../pages/Events";
import Companies from "../pages/Companies";
import Logout from "../pages/Logout";
import Search from "../pages/Search";
import Services from "../pages/Services";
import Marketing from "../pages/Marketing";
import Business from "../pages/Business";
import BusinessLocations from "../pages/BusinessLocations";
import BusinessEmployees from "../pages/BusinessEmployees";
import ContactPersons from "../pages/ContactPersons";
import ContactPersonMain from "../pages/ContactPersonMain";
import ForgotPassword from "../pages/ForgotPassword";
import SetNewPassword from "../pages/SetNewPassword";
import PageNotFound from "../pages/PageNotFound";
import { PATHNAMES } from "./pathnames";

const routes = [
  {
    path: PATHNAMES.PASSWORDFORGOT,
    component: ForgotPassword,
    label: "Passwort vergessen",
    exact: true,
    inUserMenu: false,
    inAppBar: false,
    authRequired: false,
    accessLevel: "ALL",
  },
  {
    path: `${PATHNAMES.PASSWORDRESET}/:token`,
    component: SetNewPassword,
    label: "Passwort zurücksetzen",
    exact: true,
    inUserMenu: false,
    inAppBar: false,
    authRequired: false,
    accessLevel: "ALL",
  },
  {
    path: `${PATHNAMES.PASSWORDINITIAL}/:token`,
    component: SetNewPassword,
    label: "Konto aktivieren",
    exact: true,
    inUserMenu: false,
    inAppBar: false,
    authRequired: false,
    accessLevel: "ALL",
  },
  {
    path: PATHNAMES.LOGIN,
    component: Login,
    label: "Login",
    exact: true,
    inUserMenu: false,
    inAppBar: false,
    authRequired: false,
    accessLevel: "ALL",
  },
  {
    path: PATHNAMES.DASHBOARD,
    component: Dashboard,
    label: "Dashboard",
    exact: true,
    inUserMenu: false,
    inAppBar: true,
    authRequired: true,
    accessLevel: "MEMBER",
  },
  {
    path: PATHNAMES.COMPANIES,
    component: Companies,
    label: "Mitglieder",
    exact: true,
    inUserMenu: false,
    inAppBar: true,
    authRequired: true,
    accessLevel: "MEMBER",
  },
  {
    path: `${PATHNAMES.COMPANIES}/:slug`,
    component: Companies,
    label: "Mitglieder Detail",
    exact: false,
    inUserMenu: false,
    inAppBar: false,
    authRequired: true,
    accessLevel: "MEMBER",
  },
  {
    path: PATHNAMES.NEWS,
    component: News,
    label: "News",
    exact: true,
    inUserMenu: false,
    inAppBar: true,
    authRequired: true,
    accessLevel: "MEMBER",
  },
  {
    path: `${PATHNAMES.NEWS}/:slug`,
    component: News,
    label: "News",
    exact: false,
    inUserMenu: false,
    inAppBar: false,
    authRequired: true,
    accessLevel: "MEMBER",
  },
  {
    path: PATHNAMES.EVENTS,
    component: Events,
    label: "Termine",
    exact: true,
    inUserMenu: false,
    inAppBar: true,
    authRequired: true,
    accessLevel: "MEMBER",
  },
  {
    path: `${PATHNAMES.EVENTS}/:slug`,
    component: Events,
    label: "Termine",
    exact: false,
    inUserMenu: false,
    inAppBar: false,
    authRequired: true,
    accessLevel: "MEMBER",
  },
  {
    path: PATHNAMES.SERVICES,
    component: Services,
    label: "CPN-Services",
    exact: true,
    inUserMenu: false,
    inAppBar: true,
    authRequired: true,
    accessLevel: "USER",
  },
  {
    path: `${PATHNAMES.SERVICES}/:slug`,
    component: Services,
    label: "CPN-Services",
    exact: true,
    inUserMenu: false,
    inAppBar: false,
    authRequired: true,
    accessLevel: "USER",
  },
  {
    path: `${PATHNAMES.SERVICES}/:slug/:page`,
    component: Services,
    label: "CPN-Services",
    exact: false,
    inUserMenu: false,
    inAppBar: false,
    authRequired: true,
    accessLevel: "USER",
  },
  {
    path: PATHNAMES.MARKETING,
    component: Marketing,
    label: "Marketing",
    exact: true,
    inUserMenu: false,
    inAppBar: true,
    authRequired: true,
    accessLevel: "USER",
  },
  {
    path: `${PATHNAMES.MARKETING}/:slug`,
    component: Marketing,
    label: "Marketing",
    exact: true,
    inUserMenu: false,
    inAppBar: false,
    authRequired: true,
    accessLevel: "USER",
  },
  {
    path: `${PATHNAMES.MARKETING}/:slug/:page`,
    component: Marketing,
    label: "Marketing",
    exact: false,
    inUserMenu: false,
    inAppBar: false,
    authRequired: true,
    accessLevel: "USER",
  },
  {
    path: PATHNAMES.ACCOUNT,
    component: Account,
    label: "Mein Account",
    exact: true,
    inUserMenu: true,
    inAppBar: false,
    authRequired: true,
    accessLevel: "USER",
  },
  {
    path: PATHNAMES.BUSINESS,
    component: Business,
    label: "Mein Unternehmen",
    exact: true,
    inUserMenu: true,
    inAppBar: false,
    authRequired: true,
    accessLevel: "ADMIN",
  },
  {
    path: PATHNAMES.LOCATIONS,
    component: BusinessLocations,
    label: "Meine Standorte",
    exact: true,
    inUserMenu: true,
    inAppBar: false,
    authRequired: true,
    accessLevel: "ADMIN",
  },
  {
    path: PATHNAMES.CONTACTPERSONMAIN,
    component: ContactPersonMain,
    label: "Haupt-Ansprechpartner",
    exact: true,
    inUserMenu: true,
    inAppBar: false,
    authRequired: true,
    accessLevel: "ADMIN",
  },
  {
    path: PATHNAMES.CONTACTPERSONS,
    component: ContactPersons,
    label: "Weitere Ansprechpartner",
    exact: true,
    inUserMenu: true,
    inAppBar: false,
    authRequired: true,
    accessLevel: "ADMIN",
  },
  {
    path: PATHNAMES.EMPLOYEES,
    component: BusinessEmployees,
    label: "Mitarbeiter*innen",
    exact: true,
    inUserMenu: true,
    inAppBar: false,
    authRequired: true,
    accessLevel: "ADMIN",
  },
  // {
  //   path: PATHNAMES.ASSIGNMENTS,
  //   component: BusinessAssignments,
  //   label: 'Zuordnungen',
  //   exact: true,
  //   inUserMenu: true,
  //   inAppBar: false,
  //   authRequired: true,
  //   accessLevel: "ADMIN",
  // },
  {
    path: PATHNAMES.SEARCH,
    component: Search,
    label: "Suche",
    exact: true,
    authRequired: true,
    inUserMenu: false,
    inAppBar: false,
    accessLevel: "USER",
  },
  {
    path: PATHNAMES.LOGOUT,
    component: Logout,
    label: "Logout",
    exact: true,
    authRequired: true,
    inUserMenu: true,
    inAppBar: false,
    accessLevel: "USER",
  },
  {
    path: "/*",
    component: PageNotFound,
    label: "Seite nicht gefunden",
    authRequired: false,
    inUserMenu: false,
    inAppBar: false,
    accessLevel: "ALL",
  },
];

export default routes;
