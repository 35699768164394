import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import useNewsEvents from '../hooks/useNewsEvents';
import LoadingView from '../components/misc/LoadingView';
import EventsList from '../components/events/EventsList';
import EventsDetail from '../components/events/EventsDetail';

function Events(props) {
	const { slug } = useParams();
	const { status, data } = useNewsEvents();

    if(status === "loading") {
        return <LoadingView />;
    }

	return (
		<Fragment>
			{ slug && slug!=="" ? <EventsDetail slug={slug} events={data.events} /> : <EventsList events={data.events} />}
		</Fragment>
	);
}

export default Events;
