import decodeJwt from "jwt-decode";
import { Buffer } from 'buffer';
import AppConfig from "../config";
import { accessLevels } from "./accessLevels";
import * as moment from 'moment';

if (!Date.now) {
    Date.now = function() { return new Date().getTime(); }
}

export function getDateFromString(d) {
    return moment(d).format('DD.MM.YYYY');
}

export function getTimeFromString(d) {
    return moment(d).format('HH:ii');
}

export function getMinutesInMilliseconds(minutes) {
    return 1000 * 60 * minutes;
}

export function getNextAuth() {
	return parseInt(Date.now(), 10) + getMinutesInMilliseconds(4);
}

export function encodeHTML(str) {
    return str.replace(/[^\w-_. ]/gi, function (c) {
        return `&#${c.charCodeAt(0)};`;
    });
}
export function htmlEntities(str) {
    return str.replace(/[\u00A0-\u9999<>&]/g, function(i) {
        return '&#'+i.charCodeAt(0)+';';
     });
}

export const USE_QUERY_STALE_TIME = getMinutesInMilliseconds(10);
export const USE_QUERY_CACHE_TIME = getMinutesInMilliseconds(5);

export function needsAuth(next) {
    if(next === -1) {
        return false;
    }
    const ts = parseInt(Date.now(), 10);
    return next <= ts;
}

export function namespaced(str) {
    const pre = AppConfig.namespace;
    return `${pre}:${str}`;
}

export function hasAccess(level) {
    const permissions = getPermissions();
    const userType = permissions.userType;
    return accessLevels[userType] >= accessLevels[level]
}

export function getTokenStorage() {
	const token = localStorage.getItem(namespaced('token'));
    let decodedToken = {};
    if(token) {
        decodedToken = decodeJwt(token);
    }
    return {
        token, decodedToken
    }
}

export function getDecodedToken(token) {
    return decodeJwt(token);
}

export function userType() {
	const permissions = getPermissions();
    return permissions.userType;
}

export function getPermissions() {
	const { decodedToken } = getTokenStorage();
    return decodedToken?.permissions || {userType: "USER"};
}

export function getUserStorage() {
    var userObj = {};
    if(localStorage.getItem(namespaced('user'))) {
        userObj = JSON.parse(localStorage.getItem(namespaced('user')));
    }
    if(userObj.hasOwnProperty("uuid") && userObj.uuid!=="") {
        return userObj;
    }
    const { decodedToken } = getTokenStorage();
    if(decodedToken.hasOwnProperty("uuid") && decodedToken.uuid!=="") {
        return {
            username: decodedToken.username,
            uuid: decodedToken.uuid
        }
    }
    
    return {
        username: "",
        uuid: ""
    }
}

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const validatePassword = (pw) => {
    return String(pw)
      .match(
        /(?=(?:.*[A-Z]){1,})(?=(?:.*[a-z]){1,})(?=(?:.*\d){1,})(?=(?:.*[!@#§$%^&*()\-_=+{};:,<.>?/€~£¥|]){1,})([A-Za-z0-9!@#§$%^&*()\-_=+{};:,<.>?/€~£¥|]{8,50})$/
      );
};

export const validateUsername = (str) => {
    return String(str)
      .match(
        /^[a-zA-Z\-0-9_.@]{6,}$/
      );
};

export function userLoggedIn() {
    const { decodedToken } = getTokenStorage();
    const { uuid } = getUserStorage();

	if(uuid!=="" && decodedToken.exp) {
        const expires = new Date(decodedToken.exp * 1000).getTime();
        const now = Date.now();
        return expires >= now;
    }
    return false;
}

export function purgeLocalStorage() {
	localStorage.removeItem(namespaced('user'));
	localStorage.removeItem(namespaced('token'));
}

export const API_URL = AppConfig.api[process.env.NODE_ENV].concat(AppConfig.serviceUrl);

export const REALM = Buffer.from(AppConfig.realm).toString('base64');

export const LOGOUT_ROUTE = {
    pathname: '/logout', 
    query: {}
};


export const NO_IMAGE_TEASER = `${AppConfig.api[process.env.NODE_ENV]}/assets/images/cpn-no-image-teaser.png`;
export const NO_IMAGE_FULL = `${AppConfig.api[process.env.NODE_ENV]}/assets/images/cpn-no-image-full.png`;

export const salutations = {
    diverse: "Divers",
    female: "Frau",
    male: "Herr"
}

export const contactPersonTypes = [
    "Technik",
    "Marketing",
    "Buchhaltung",
    "Vertrieb",
    "Schulung",
    "Allgemein",
]

export const employeeTypes = [
    "ADMIN",
    "MEMBER",
    "USER",
]

export const getSalutation = str => {
    if(str==="") {
        return "";
    }
    return salutations[str] ?? "";
}


export function formatBytes(a){let b=0,c=parseInt(a,10)||0;for(;1024<=c&&++b;)c/=1024;return c.toFixed(10>c&&0<b?1:0)+" "+["bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][b]}