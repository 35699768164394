import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import CompaniesNameAndLogo from './CompaniesNameAndLogo';
import TinyMceRenderer from '../misc/TinyMceRenderer';
import { Divider } from '@mui/material';

export default function CompaniesDetailHeader(props) {


  return (
    <Fragment>
        <CompaniesNameAndLogo {...props} isDetail={true} />
        <Box sx={{my: 4}}>
          <TinyMceRenderer rawHtml={props.selfDisclosure} />
        </Box>
        <Divider />
    </Fragment>
  );
}

CompaniesDetailHeader.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  street: PropTypes.string,
  website: PropTypes.string,
  zipcode: PropTypes.string,
  selfDisclosure: PropTypes.string,
};