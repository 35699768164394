import React, { useState } from 'react';
import { Box, Typography, Paper, Button, Grid, Switch, FormControlLabel, Alert } from '@mui/material';
import { useUserDataState, saveBusinessLogoData, useUserDataDispatch } from '../../contexts/userdata';
import FileInput from '../misc/FileInput';

function BusinessLogoData(props) {
    const { company, loading } = useUserDataState();
    const [logo, setPortrait] = useState(company.logo);
    const [changeLogo, setChangeLogo] = useState(false);
    const [newLogo, setNewLogo] = useState(null);
    const dispatch = useUserDataDispatch();
    const [msg, setMsg] = useState({str:"",type:""});

    const handleCancel = evt => {
        evt.preventDefault();
        setPortrait(company.logo);
        setNewLogo(null);
        setChangeLogo(false);
        setMsg({str:"",type:""});
    }

    const handleChange = props => {
        setNewLogo(props)
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
        const fields = {
            image: newLogo
        };
        
        const res = await saveBusinessLogoData(dispatch, fields);
        if(res?.company) {
            setPortrait(res.company.logo);
            setNewLogo(null);
            setChangeLogo(false);
            setMsg({str:"Erfolgreich gespeichert",type:"success"});
        } else {
            setMsg({str:"Ein Fehler ist aufgetreten — nicht gespeichert",type:"error"});
        }
    }


	return (
		<Box sx={{mt:3}}>
			<Typography variant="h2">
				Firmen-Logo
			</Typography>
            <Paper elevation={0} variant="outlined">
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                        {logo!=="" ? 
                            (<Box
                                component="img"
                                sx={{
                                height: "auto",
                                width: "100%",
                                maxWidth: { xs: 200, md: 300 },
                                }}
                                alt="Logo"
                                src={logo}
                            />):(
                                <Typography variant="button">bisher ohne Logo</Typography>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
                        <FormControlLabel control={<Switch checked={changeLogo} onChange={() => {setChangeLogo(!changeLogo);setMsg({str:"",type:""});}} />} label="Neues Logo hochladen" />
                        {changeLogo && 
                            <FileInput onChange={handleChange} type="logo" />
                        }
                    </Grid>
                </Grid>

                
                {
                    msg?.str && <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 3, mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={newLogo===null || newLogo===""}
                            >
                            { !loading ? "Daten speichern" : "Verarbeite…" }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            onClick={handleCancel}
                            disabled={newLogo===null || newLogo===""}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
                
             </Box>
            </Paper>
		</Box>
	);
}

export default BusinessLogoData;
