import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import SectionHeadline from '../misc/SectionHeadline';
import { getSalutation } from '../../utils';

function CompaniesContactPersonsSection(props) {

    if(props.data.length === 0) {
        return null;
    }
    
	return (
        <Box sx={{pb: 2}}>
            <SectionHeadline>{props.headline}</SectionHeadline>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            { props.data.map(item => {
                return (
                    <Grid item xs={12} sm={6} key={item.name}>
                        <Typography variant="body1" sx={{fontWeight: 500}}>{getSalutation(item.salutation)} {item.name}</Typography>
                        <Typography variant="body1">{item.email}<br />{item.phone}</Typography>
                    </Grid>
                )
            })
            }
            </Grid>
        </Box>
	);
}

export default CompaniesContactPersonsSection;
