import React, { Fragment } from 'react';
import useDashboard from '../hooks/useDashboard';
import LoadingView from '../components/misc/LoadingView';
import CardsResultGrid from '../components/misc/CardsResultGrid';
import ServicesDashboardGrid from '../components/services/ServicesDashboardGrid';

function Dashboard(props) {
	const { status, data } = useDashboard();

    if(status === "loading") {
        return <LoadingView />;
    }

	const maxElements = 4;

	return (
		<Fragment>
			{
                data.news.length > 0 && <CardsResultGrid data={data.news.slice(0, maxElements)} showDetailLink={data.news.length > maxElements} type="news" page="dashboard" />
            }
            {
                data.events.length > 0 && <CardsResultGrid data={data.events.slice(0, maxElements)} showDetailLink={data.events.length > maxElements} type="events" page="dashboard" />
            }
            {
                data.partners.length > 0 && <ServicesDashboardGrid title="Kooperationspartner" pages={data.partners} />
            }
            {
                data.distributors.length > 0 && <ServicesDashboardGrid title="Distributoren" pages={data.distributors} />
            }
		</Fragment>
	);
}

export default Dashboard;
