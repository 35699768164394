import React from "react";
import { useHistory } from "react-router-dom";
import { PATHNAMES } from "../../routes/pathnames";
import TinyMceRenderer from "../misc/TinyMceRenderer";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Grid,
} from "@mui/material";

MarketingItemsCard.defaultProps = {
  isDashboard: false,
};

export default function MarketingItemsCard(props) {
  const history = useHistory();

  const handleClick = (e) => {
    history.push({
      pathname: `${PATHNAMES.MARKETING}/${props.slug}`,
      query: { prevPath: props.isSearch ? `` : PATHNAMES.MARKETING },
    });
  };

  const NonDashboardImage = () =>
    props.teaser !== "" ? (
      <Box
        component="img"
        sx={{
          height: "auto",
          width: "auto",
          maxHeight: "inherit",
          maxWidth: "100%",
          objectFit: "contain",
        }}
        alt={props.slug}
        src={props.teaser}
      />
    ) : null;

  return (
    <Box>
      <Card elevation={10}>
        <CardActionArea onClick={handleClick}>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }}>
              <Grid item xs={9}>
                <Typography
                  component="div"
                  sx={{
                    height: "3rem",
                    lineHeight: 1.15,
                    overflow: "hidden",
                    fontSize: "1.25rem",
                    fontWeight: 500,
                  }}
                >
                  {props.title}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right", pr: 1 }}>
                <Box sx={{ maxHeight: "45px", width: "100%" }}>
                  <NonDashboardImage />
                </Box>
              </Grid>
            </Grid>

            <Typography
              component="div"
              color="text.secondary"
              className="excerpt-wrapper"
              sx={{
                height: "5.25rem",
                overflow: "hidden",
                fontSize: "0.875rem",
                mb: 1,
                mt: 0.5,
                lineHeight: "1.25rem",
              }}
            >
              <TinyMceRenderer rawHtml={props.excerpt} />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}
