import React, { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce } from '../utils/useEffectOnce';
	
function RedirectLogin(props) {
    const history = useHistory();
    const location = useLocation();

    useEffectOnce(() => {
        let afterLogin = '/dashboard';
        if(location.pathname && location.pathname!=="") {
            if(location.pathname!=='/logout' && location.pathname!=='/404' && location.pathname!=='/login') {
                afterLogin = location.pathname;
            }
            if(location.search && location.search!=="") {
                afterLogin = afterLogin.concat(location.search);
            }
        }
        history.replace({ pathname: '/login', query: { afterLogin } });
      // eslint-disable-next-line
      }, [location]);

	

	return (
		<Fragment />
	);
}

export default RedirectLogin;
