import Box from '@mui/material/Box';

export default function ServicesLogo(props) {

    let mh = {
        xs: 45
    };
    let mw = {
        xs: 110
    };

    let sxImg = {
        height: "auto",
        width: "auto",
        maxHeight: mh,
        maxWidth: mw,
        position: "absolute",
        right: 0
    };

    let sxWrapper = {
        position: "relative",
        textAlign: "right",
    };

    if(props.isDashboard) {
        sxImg = {
            maxWidth: "90%",
            maxHeight: "90%",
            height: "auto",
            width: "auto"
        };
        sxWrapper = {
            ...sxWrapper,
            textAlign: "center",
            height: "inherit",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    }

    if(props.isDetail) {
        sxImg = {
            ...sxImg,
            maxHeight: { xs: 65 },
            maxWidth: { xs: 350, sm: 320, md: 360, xl: 410 },
        };
    }


  return (
    <Box sx={sxWrapper}>
      <Box
        component="img"
        sx={sxImg}
        alt={props.alt}
        src={props.src}
      />
    </Box>
  );
}

ServicesLogo.defaultProps = {
    isDashboard : false,
    isDetail : false,
    alt : ""
}