export const PATHNAMES = {
  PASSWORDFORGOT: "/passwort-vergessen",
  PASSWORDRESET: "/passwort-zuruecksetzen",
  PASSWORDINITIAL: "/konto-aktivieren",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  COMPANIES: "/mitglieder",
  NEWS: "/news",
  EVENTS: "/termine",
  SERVICES: "/cpn-services",
  MARKETING: "/marketing",
  ACCOUNT: "/account",
  BUSINESS: "/unternehmen",
  LOCATIONS: "/standorte",
  ASSIGNMENTS: "/zuordnungen",
  EMPLOYEES: "/mitarbeiter",
  CONTACTPERSONMAIN: "/hauptansprechparter",
  CONTACTPERSONS: "/ansprechparter",
  SEARCH: "/suche",
  LOGOUT: "/logout",
};
