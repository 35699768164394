import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { purgeLocalStorage } from '../../utils';
import { useAuthDispatch } from '../../contexts/auth'
import { unsetUserData, useUserDataDispatch } from '../../contexts/userdata'
import { Typography } from '@mui/material';

export default function GeneralError(props) {
  const [open, setOpen] = React.useState(props.open);
  const authDispatch = useAuthDispatch();
  const userDataDispatch = useUserDataDispatch();

  const handleClick = async () => {
    authDispatch({ type: 'LOGOUT' });
    purgeLocalStorage();
    await unsetUserData(userDataDispatch);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Dialog open={open} disableEscapeKeyDown={true} maxWidth="sm">
      <DialogTitle>Ein Fehler ist aufgetreten</DialogTitle>
      <DialogContent>
        { props.code === 401 &&
            <Button variant="contained" onClick={handleClick} fullWidth>
                Erneut einloggen
            </Button>
        }
        { props.code !== 401 &&
        <>
            { props.message!=="" && <Typography sx={{mb:2}}>{props.message}</Typography> }
            <Button variant="contained" onClick={handleClose} fullWidth>
                Ok
            </Button>
        </>
        }
      </DialogContent>
    </Dialog>
  );
}
