import React, { useState, useRef } from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { stopwords } from '../../../utils/stopwords';
import { useHistory } from 'react-router-dom';


const SearchInput = styled('div')(({ theme }) => ({
    position: 'relative',
    alignSelf: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    marginLeft: 0,
    width: '100%',
    height: '32px',
    marginBottom: "10px",
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '93%',
    },
    [theme.breakpoints.up('md')]: {
        marginBottom: "0",
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(.5, 1, .5, 0),
      paddingLeft: `calc(1em + ${theme.spacing(3)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '8ch',
        '&:focus': {
          width: '15ch',
        },
      },
    },
  }));
  

  export default function Search() {
    const textInput =useRef(null);
    const [query, setQuery] = useState('');
    const [valid, setValid] = useState(true);

    const history = useHistory();

    const handleSearchTerm = event => {
        const val = event.target.value;
        if(!stopwords.includes(val) && val.length > 2) {
            setQuery(event.target.value);
            setValid(true);
        } else {
            setQuery("");
            setValid(false);
        }
    }

    const handleKeyDown = event => {
      if (event.key === 'Enter' && query !== "") {
        history.push({
            pathname: '/suche',
            search: `?q=${query}`
        })
        setQuery("");
        setValid(true);
        textInput.current.value = "";
      }
    };

    return (
        <SearchInput>
            <SearchIconWrapper>
            <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              inputRef={textInput}
              placeholder="Suche…"
              inputProps={{ 'aria-label': 'Suche', maxLength: 50 }}
              onChange={handleSearchTerm}
              onKeyDown={handleKeyDown}
              sx={{
                border: "1px solid #fff",
                borderColor: valid ? "#fff" : "text.secondary"
              }}
            />
        </SearchInput>
    );
  }