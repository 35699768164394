import React, { Fragment, useState } from 'react';
import { Box, TextField, Typography, Paper, FormControl, InputLabel, MenuItem, Select, Button, Grid, Alert } from '@mui/material';
import { useUserDataState, saveBusinessAddressData, useUserDataDispatch } from '../../contexts/userdata';
import _ from 'lodash';

function BusinessAddressData(props) {
    const { company, loading } = useUserDataState();
    const defaultAddressObj = {
        name: company.name,
        street: company.street,
        zipcode: company.zipcode,
        city: company.city,
        country: company.country,
        website: company.website,
    };
    const [form, setForm] = useState(defaultAddressObj);
    const [msg, setMsg] = useState({str:"",type:""});
    const dispatch = useUserDataDispatch();
    

    const handleChange = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
        setMsg({str:"",type:""});
    }

    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultAddressObj);
        setMsg({str:"",type:""});
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
        if(_.isEqual(defaultAddressObj, form)) {
            return;
        }
        if(form.name==="" || form.street==="" || form.zipcode==="" || form.city==="") {
            return;
        }
        const fields = {
            name: form.name,
            street: form.street,
            zipcode: form.zipcode,
            city: form.city,
            country: form.country,
            website: form.website,
        };
        const res = await saveBusinessAddressData(dispatch, fields);
        if(res?.company) {
            setMsg({str:"Erfolgreich gespeichert",type:"success"});
        } else {
            setMsg({str:"Ein Fehler ist aufgetreten — nicht gespeichert",type:"error"});
        }
        
    }


	return (
		<Fragment>
			<Typography variant="h2">
				Adressdaten
			</Typography>
            <Paper elevation={10}>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Firmenname"
                        variant="outlined"
                        value={form.name ?? ""}
                        error={form.name===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="street"
                        name="street"
                        label="Strasse"
                        variant="outlined"
                        value={form.street ?? ""}
                        error={form.street===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="zipcode"
                        name="zipcode"
                        label="Postleitzahl"
                        variant="outlined"
                        value={form.zipcode ?? ""}
                        error={form.zipcode===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="city"
                        name="city"
                        label="Stadt"
                        variant="outlined"
                        value={form.city ?? ""}
                        error={form.city===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <InputLabel id="countrylabel" sx={{background:"#fff",px:1}}>Land</InputLabel>
                    <Select
                        labelId="countrylabel"
                        onChange={handleChange}
                        value={form.country ?? "DE"}
                        name="country"
                    >
                        <MenuItem value="DE">Deutschland</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="website"
                        name="website"
                        label="Webseite"
                        variant="outlined"
                        value={form.website ?? ""}
                        error={form.website===""}
                        onChange={handleChange}
                        helperText="Bitte eine vollständige Web-Adresse mit https:// angebeben"
                    />
                </FormControl>
                {
                    msg?.str && <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading || _.isEqual(defaultAddressObj, form) || form.name==="" || form.street==="" || form.zipcode==="" || form.city===""}
                            >
                            { !loading ? "Daten speichern" : "Verarbeite…" }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={_.isEqual(defaultAddressObj, form)}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
             </Box>
            </Paper>
		</Fragment>
	);
}

export default BusinessAddressData;
