import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import useCompanies from '../hooks/useCompanies';
import LoadingView from '../components/misc/LoadingView';
import CompaniesList from '../components/companies/CompaniesList';
import CompaniesDetail from '../components/companies/CompaniesDetail';

function Companies(props) {
	const { slug } = useParams();
	const { status, data } = useCompanies();

    if(status === "loading") {
        return <LoadingView />;
    }

	return (
		<Fragment>
			{ slug && slug!=="" ? <CompaniesDetail slug={slug} companies={data} /> : <CompaniesList companies={data} />}
		</Fragment>
	);
}

export default Companies;
