import React from 'react'
import DOMPurify from 'isomorphic-dompurify';
import { Box } from '@mui/material';


const TinyMceRenderer = props => (
  <Box className="tinymce-renderer">
    { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.rawHtml) }} /> }
  </Box>
)

export default TinyMceRenderer