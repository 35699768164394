import React, { useState } from 'react';
import { Box, Typography, Paper, FormControl, Button, Grid, Alert } from '@mui/material';
import { useUserDataState, saveBusinessTextData, useUserDataDispatch } from '../../contexts/userdata';
import _ from 'lodash';
// import MarkdownInput from '../misc/MarkdownInput';
import TinyMceInput from '../misc/TinyMceInput';

function BusinessTextData(props) {
    const { company, loading } = useUserDataState();
    const defaultTextObj = {
        selfDisclosure: company.selfDisclosure,
        services: company.services,
    };
    const [randomKey, setRandomKey] = useState(_.uniqueId());
    const [form, setForm] = useState(defaultTextObj);
    const [msg, setMsg] = useState({str:"",type:""});
    const dispatch = useUserDataDispatch();

    const onEditorChange = (name, value) => {
        setForm({
            ...form,
            [name]: value
        })
        setMsg({str:"",type:""});
    }

    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultTextObj);
        setMsg({str:"",type:""});
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
        if(_.isEqual(defaultTextObj, form)) {

            return;
        }
        if(form.selfDisclosure==="") {
            return;
        }
        const fields = {
            selfDisclosure: form.selfDisclosure,
            services: form.services,
        };
        const res = await saveBusinessTextData(dispatch, fields);
        if(res?.company) {
            setForm(
                {
                    selfDisclosure: res.company.selfDisclosure,
                    services: res.company.services,
                }
            );
            setRandomKey(_.uniqueId());
            setMsg({str:"Erfolgreich gespeichert",type:"success"});
        } else {
            setMsg({str:"Ein Fehler ist aufgetreten — nicht gespeichert",type:"error"});
        }
        
    }


	return (
		<Box sx={{mt: 3}}>
			<Typography variant="h2">
				Über uns Texte
			</Typography>
            <Paper elevation={10}>
            <Box sx={{p: 2, mb: 0}}>
                <Alert severity="info">Bitte nur Plain-Text in die Editor-Textfelder kopieren und dann mit den Buttons die gewünschten Textstellen im gewünschten Stil markieren. Nicht erlaubte Textstile werden beim Speichern entfernt.</Alert>
            </Box>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{my: 2}}>
                    <TinyMceInput
                        key={randomKey}
                        required
                        id="selfDisclosure"
                        name="selfDisclosure"
                        label="Selbstauskunft"
                        value={form.selfDisclosure}
                        onChange={onEditorChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TinyMceInput
                        key={`${randomKey}_2`}
                        required
                        id="services"
                        name="services"
                        label="Serviceangebote"
                        value={form.services}
                        onChange={onEditorChange}
                    />
                </FormControl>
                {
                    msg?.str && <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 3, mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading || _.isEqual(defaultTextObj, form) || form.selfDisclosure===""}
                            >
                            { !loading ? "Daten speichern" : "Verarbeite…" }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            disabled={_.isEqual(defaultTextObj, form)}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
             </Box>
            </Paper>
		</Box>
	);
}

export default BusinessTextData;
