import React, { Fragment, useState } from 'react';
import { Box, TextField, Typography, Paper, FormControl, InputLabel, MenuItem, Select, Button, Grid, Alert } from '@mui/material';
import { useUserDataState, savePersonalData, useUserDataDispatch } from '../../contexts/userdata';
import _ from 'lodash';

function PersonalData(props) {
    const { personal, loading, account } = useUserDataState();
    const defaultPersonalObj = {
        salutation: personal.salutation,
        firstname: personal.firstname,
        lastname: personal.lastname,
        phone: personal.phone,
        jobPosition: personal.jobPosition,
    };
    const [form, setForm] = useState(defaultPersonalObj);
    const [msg, setMsg] = useState({str:"",type:""});
    const dispatch = useUserDataDispatch();

    const handleChange = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
        setMsg({str:"",type:""});
    }

    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultPersonalObj);
        setMsg({str:"",type:""});
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
        if(_.isEqual(defaultPersonalObj, form)) {
            return;
        }
        if(form.firstname==="" || form.lastname==="") {
            return;
        }
        const fields = {
            salutation: form.salutation,
            firstname: form.firstname,
            lastname: form.lastname,
            phone: form.phone,
            jobPosition: form.jobPosition,
        };
        const res = await savePersonalData(dispatch, fields);
        if(res?.personal) {
            setMsg({str:"Erfolgreich gespeichert",type:"success"});
        } else {
            setMsg({str:"Ein Fehler ist aufgetreten — nicht gespeichert",type:"error"});
        }
        
    }


	return (
		<Fragment>
			<Typography variant="h2">
				Meine Daten
			</Typography>
            <Paper elevation={10}>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{my: 2}}>
                    <InputLabel id="salutationlabel" sx={{background:"#fff",px:1}}>Anrede</InputLabel>
                    <Select
                        labelId="salutationlabel"
                        onChange={handleChange}
                        value={form.salutation ?? ""}
                        name="salutation"
                    >
                        <MenuItem value="female">Frau</MenuItem>
                        <MenuItem value="male">Herr</MenuItem>
                        <MenuItem value="diverse">Divers</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="firstname"
                        name="firstname"
                        label="Vorname"
                        variant="outlined"
                        value={form.firstname ?? ""}
                        error={form.firstname===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="lastname"
                        name="lastname"
                        label="Nachname"
                        variant="outlined"
                        value={form.lastname ?? ""}
                        error={form.lastname===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="disabledemail"
                        name="email"
                        label="E-Mail"
                        variant="outlined"
                        value={account.email ?? ""}
                        disabled={true}
                        helperText="Hinweis: Die E-Mail Adresse kann in den Login-Einstellungen geändert werden"
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="phone"
                        name="phone"
                        label="Telefon"
                        variant="outlined"
                        value={form.phone ?? ""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="jobPosition"
                        name="jobPosition"
                        label="Job Position"
                        variant="outlined"
                        value={form.jobPosition ?? ""}
                        onChange={handleChange}
                    />
                </FormControl>
                {
                    msg?.str && <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 3, mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading || _.isEqual(defaultPersonalObj, form) || form.firstname==="" || form.lastname===""}
                            >
                            { !loading ? "Daten speichern" : "Verarbeite…" }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            disabled={_.isEqual(defaultPersonalObj, form)}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
             </Box>
            </Paper>
		</Fragment>
	);
}

export default PersonalData;
