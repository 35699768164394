import { Alert, Autocomplete, Box, Button, Chip, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { saveBusinessCustomerGroupsData, useUserDataDispatch, useUserDataState } from '../../contexts/userdata';
import useGeneralData from '../../hooks/useGeneralData';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


function BusinessCustomerGroupsData(props) {
    const { company, loading } = useUserDataState();
    const { data: generalData } = useGeneralData();

    const defaultFormObj = {customerGroups: company.customerGroups};
    const defaultMsgObj = {str:"",type:""};
    const [form, setForm] = useState(defaultFormObj);
    const [newCustomerGroup, setNewCustomerGroup] = useState("");
    const [randomKey, setRandomKey] = useState(_.uniqueId());
    const [msg, setMsg] = useState(defaultMsgObj);
    const dispatch = useUserDataDispatch();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const buttonLabel = isSmall ? `Hinzu`: `Hinzufügen`;
    
    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultFormObj);
        setMsg(defaultMsgObj);
    }
    
    const handleDelete = certId => {
        const arr = form.customerGroups.filter(item => item !== certId.toString());
        setRandomKey(_.uniqueId());
        setNewCustomerGroup("");
        setForm({
            ...form,
            customerGroups: arr
        })
        setMsg(defaultMsgObj);
    }

    const handleAdd = evt => {
        evt.preventDefault();
        const item = generalData.customerGroups.find(item => item.name === newCustomerGroup);
        setRandomKey(_.uniqueId());
        setNewCustomerGroup("");
        setForm({
            ...form,
            customerGroups: [
                ...form.customerGroups,
                item.id
            ]
        })
        setMsg(defaultMsgObj);
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
        if(_.isEqual(defaultFormObj, form)) {
            return;
        }
        const fields = {
            customerGroups: form.customerGroups
        };
        const res = await saveBusinessCustomerGroupsData(dispatch, fields);
        if(res?.company) {
            const defaultFormObj = {
                customerGroups: res.company.customerGroups
            };
            setForm(defaultFormObj);
            setMsg({str:"Erfolgreich gespeichert",type:"success"});
        } else {
            setMsg({str:"Ein Fehler ist aufgetreten — nicht gespeichert",type:"error"});
        }
        
    }

	return (
		<Box sx={{ mt: 3}}>
			<Typography variant="h2">
                Kundengruppen-Fokus
			</Typography>
            <Paper elevation={10}>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>
                <Typography variant="h5" sx={{fontWeight:500,mb:2}}>
                    Ausgewählt
                </Typography>
                <Stack direction="row" spacing={0} sx={{mb:2, flexWrap: "wrap"}} maxWidth="md">
                    {
                        form.customerGroups.length > 0 && form.customerGroups.map((certId, i) =>
                            {
                                const currObj = generalData.customerGroups.find(item => item.id === certId);
                                if(!currObj) {
                                    return null;
                                }
                                return (
                                    <Chip key={i} label={currObj.name} onDelete={() => handleDelete(currObj.id)} sx={{mx: .5, mb: 1}} />
                                )
                            }
                        )
                    }
                    {
                        form.customerGroups.length === 0 && <Typography variant="button" sx={{mb:2, fontSize: ".8rem", color:"#888"}}>Keine Kundengruppen-Fokus vorhanden</Typography>
                    }
                </Stack>
                <Typography variant="h5" sx={{fontWeight:500,mb:1}}>
                    Hinzufügen
                </Typography>
                <Grid container columnSpacing={2}>
                    <Grid item xs={8}>
                        <Autocomplete
                            key={randomKey}
                            clearOnBlur
                            noOptionsText="Keine Kundengruppe gefunden"
                            options={generalData.customerGroups.filter(item => !form.customerGroups.includes(item.id)).map(option => option.name)}
                            name="newCustomerGroup"
                            inputValue={newCustomerGroup}
                            onInputChange={(event, newValue) => {
                                setNewCustomerGroup(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Kundengruppe auswählen" variant="outlined" sx={{mt: "5px"}} inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password'
                                }} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{display:"flex", alignItems: "center"}}>
                        <Button
                            disabled={newCustomerGroup==="" || !generalData.customerGroups.find(item => item.name === newCustomerGroup)}
                            onClick={handleAdd}
                            variant="contained"
                            fullWidth
                        >
                            {buttonLabel}
                        </Button>
                    </Grid>
                </Grid>
                
                {
                    msg?.str && <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 3, mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading || _.isEqual(defaultFormObj, form)}
                            >
                            { !loading ? "Daten speichern" : "Verarbeite…" }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            disabled={_.isEqual(defaultFormObj, form)}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
             </Box>
            </Paper>
		</Box>
	);
}

export default BusinessCustomerGroupsData;
