import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthState } from "../contexts/auth";
import AuthRoute from "./AuthRoute";
import { hasAccess, userType } from "../utils";
import RedirectLogin from "../pages/RedirectLogin";
import { PATHNAMES } from "./pathnames";
import { resetMapScrollIndex, useMapScrollDispatch } from "../contexts/map";

const AppRoute = ({
  component: Component,
  path,
  exact,
  pageTitle,
  accessLevel,
  authRequired,
  ...rest
}) => {
  const userDetails = useAuthState();
  const USER_TYPE = userType();
  const dispatch = useMapScrollDispatch();

  if (!path.includes(PATHNAMES.COMPANIES)) {
    resetMapScrollIndex(dispatch);
  }
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        authRequired && !userDetails.loggedIn ? (
          <RedirectLogin />
        ) : (path === PATHNAMES.LOGIN ||
            path === PATHNAMES.PASSWORDINITIAL ||
            path === PATHNAMES.PASSWORDRESET ||
            path === PATHNAMES.PASSWORDFORGOT) &&
          userDetails.loggedIn ? (
          <Redirect
            to={{
              pathname: USER_TYPE !== "USER" ? "/dashboard" : "/cpn-services",
            }}
          />
        ) : !hasAccess(accessLevel) ? (
          <Redirect to={{ pathname: "/404" }} />
        ) : !authRequired ? (
          <Component {...props} pageTitle={pageTitle} />
        ) : (
          <AuthRoute component={Component} {...props} pageTitle={pageTitle} />
        )
      }
      {...rest}
    />
  );
};

export default AppRoute;
