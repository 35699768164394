export const sortingOptions = [
    {
        num: 1,
        type:"nameAsc",
        label:"Aufsteigend (nach Name)"
    },
    {
        num: 2,
        type:"nameDesc",
        label:"Absteigend (nach Name)"
    },
    {
        num: 3,
        type:"cityAsc",
        label:"Aufsteigend (nach Ort)"
    },
    {
        num: 4,
        type:"cityDesc",
        label:"Absteigend (nach Ort)"
    },
    {
        num: 5,
        type:"zipAsc",
        label:"Aufsteigend (nach Postleitzahl)"
    },
    {
        num: 6,
        type:"zipDesc",
        label:"Absteigend (nach Postleitzahl)"
    }
]
