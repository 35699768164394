import React, { useState, Fragment } from "react";
import { useEffectOnce } from "../utils/useEffectOnce";
import { needsAuth } from "../utils";
import { checkAuth, useAuthDispatch, useAuthState } from '../contexts/auth';
import Layout from "../pages/Layout";

function AuthRoute({component:Component, ...props}) {
    const { nextAuth } = useAuthState();
    const [authorized, setAuthorized] = useState(needsAuth(nextAuth) ? false : true);

    const dispatch = useAuthDispatch();

    useEffectOnce(() => {
        if(authorized) {
            return;
        }
        const fetchData = async () => {
            let response = await checkAuth(dispatch);
            setAuthorized(response);
        };
        fetchData();
      // eslint-disable-next-line
      }, [authorized]);

    return (
        <Fragment>
            {authorized === true ? (
            <Layout component={Component} {...props} />
            ) : null}
        </Fragment>
    );
}
export default AuthRoute;