import React, { useReducer } from 'react';
import { initialState, UserDataReducer } from './reducer';

const UserDataStateContext = React.createContext();
const UserDataDispatchContext = React.createContext();

export function useUserDataState() {
    const context = React.useContext(UserDataStateContext);
    if (context === undefined) {
        throw new Error("useUserDataState must be used within a UserDataProvider");
    }

    return context;
}

export function useUserDataDispatch() {
    const context = React.useContext(UserDataDispatchContext);
    if (context === undefined) {
        throw new Error("useUserDataDispatch must be used within a UserDataProvider");
    }

    return context;
}

export const UserDataProvider = ({ children }) => {
  const [userdata, dispatch] = useReducer(UserDataReducer, initialState);
 
  return (
    <UserDataStateContext.Provider value={userdata}>
      <UserDataDispatchContext.Provider value={dispatch}>
        {children}
      </UserDataDispatchContext.Provider>
    </UserDataStateContext.Provider>
  );
};