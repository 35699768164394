import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Alert, Grid } from '@mui/material';
import apiService from '../../utils/apiService';
import { formatBytes } from '../../utils';

export default function ServicesDownloadLink(props) {
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');
  
  const handleClick = async (e) => {
    setLoading(true);
    setErr("");
    try {
        const data = await apiService(`get-download/${props.uuid}`, { useTokenAuth: true });
        if (data) {
            if(data.error!=="") {
                setErr(data.error);
            } else {
                window.location.href = data.link;
            }
        }
    } catch (error) {
        const _err = error.message.split("|||");
        setErr(_err[0]);
    } finally {
        setLoading(false);
    }
	
  }

  return (
    <Box onClick={handleClick} sx={{ cursor:"pointer", "& :hover": { background: "#fafafa" } }}>
        <Grid container cellSpacing={2}>
            <Grid item xs={1} sx={{ cursor:"pointer" }}>
                <Box sx={{display:"flex",alignContent:"center",alignItems:"center",justifyContent: "center",height: "70px"}}>
                    {loading ? 
                        <CircularProgress sx={{width: "30px", height: "30px"}} /> :

                        <Box sx={{textAlign: "center"}}>
                            <img src={props.icon} style={{width:"35px",height:"auto"}} alt="icon" />
                            <Typography variant="caption" sx={{fontSize: ".6rem",cursor:"pointer", display:"block"}}>
                                {formatBytes(props.fileSize)}
                            </Typography>
                        </Box>
                    }
                </Box>
            </Grid>
            <Grid item xs={11} sx={{display:"flex",alignContent:"center",alignItems:"center"}}>
                { err!=="" ? <Alert severity="warning">{err}</Alert>
                : (
                    <Box>
                        <Typography variant="button" sx={{fontWeight: 500,cursor:"pointer"}}>
                            {props.name}
                        </Typography>
                        {
                            props.description!=="" && 
                            <Typography variant="body2" sx={{fontSize: ".75rem", lineHeight:1.15,cursor:"pointer"}}>
                                {props.description}
                            </Typography>
                        }
                    </Box>
                )}
            </Grid>
        </Grid>
    </Box>
    
  );
}
