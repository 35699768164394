import React, { useState } from 'react';
import { login, useAuthState, useAuthDispatch } from '../contexts/auth';
import { useUserDataDispatch } from '../contexts/userdata';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import LogoFull from '../components/misc/logos/LogoFull';
import { PATHNAMES } from '../routes/pathnames'


export default function Login() {
    const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const history = useHistory();
	const location = useLocation();
	const dispatch = useAuthDispatch();
	const dispatchUserData = useUserDataDispatch();
	const { loading, error } = useAuthState();

	const handlePasswordForgot = (e) => {
        history.push({
            pathname: PATHNAMES.PASSWORDFORGOT,
            query: {}
        });
    }

	const handleLogin = async (e) => {
		e.preventDefault();
        if(username==="" || password==="") {
            return;
        }
        let response = await login(dispatch, { username, password });
        if (!response || !response.user) return;
        dispatchUserData({ type: 'SET_USER_DATA', payload: response.userdata });
        if(response.user.userType==="USER") {
            history.push({
                pathname: PATHNAMES.SERVICES,
                query: {}
            });
        } else {
            const afterLogin = location.query?.afterLogin;
            if(afterLogin && afterLogin!=="" && afterLogin!=="/logout" && afterLogin!=="/404" && afterLogin!=="/suche") {
                history.push(afterLogin);
            } else {
                history.push(PATHNAMES.DASHBOARD);
            }
        }
        
	};


  return (
      <Container component="main" maxWidth="xs" className="no-padding">
        <Paper>
            <Box
                sx={{
                    p: 3,
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <LogoFull styles={{maxWidth: "250px"}} />
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Benutzername"
                        name="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        disabled={loading}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={loading}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading || username==="" || password===""}
                        >
                        { !loading ? "Jetzt anmelden" : "Verarbeite…" }
                    </Button>
                    {
                        (error && !loading) && <Alert severity="error">{error}</Alert>
                    }
                </Box>
            </Box>
        </Paper>
        <Box sx={{p:1, textAlign: "center"}}>
            <Button onClick={handlePasswordForgot} variant="text" color="secondary">
                Passwort vergessen
            </Button>
        </Box>
      </Container>
  );
}