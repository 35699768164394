import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import useSitemap from '../hooks/useSitemap';
import LoadingView from '../components/misc/LoadingView';
import ServicesResultGrid from '../components/services/ServicesResultGrid';
import ServicesDetail from '../components/services/ServicesDetail';
import ServicesFilter from '../components/services/ServicesFilter';

function Services(props) {
	const { slug, page } = useParams();
	const { status, data } = useSitemap();

    if(status === "loading") {
        return <LoadingView />;
    }
    
    let section;

    if(slug) {
        section = data.find(item => item.slug === slug);
    }
    

	return (
		<Fragment>
			{
                page && page!=="" ? (
                    <ServicesDetail slug={slug} page={page} section={section} />
                ):(
                    <Fragment>
                    <ServicesFilter slug={slug} />
                    {
                        data && data.map(item => {
                            if(slug && slug!==item.slug) {
                                return null;
                            }
                            return (
                                <ServicesResultGrid key={item.slug} {...item} />
                            )
                        })
                    }
                    </Fragment>
                )
            }
		</Fragment>
	);
}

export default Services;
