import { Box } from '@mui/material';
import { contactPersonHeadlines, contactPersonTypes } from '../../utils/contactPersonTypes';
import CompaniesContactPersonsSection from './CompaniesContactPersonsSection';

function CompaniesContactPersons(props) {

    if(props.contactPersons.length === 0) {
        return null;
    }
    
	return (
        <Box sx={{py: 2}}>
            <CompaniesContactPersonsSection data={props.contactPersons.filter(item => item.type===contactPersonTypes.TECH)} headline={contactPersonHeadlines.TECH} />
            <CompaniesContactPersonsSection data={props.contactPersons.filter(item => item.type===contactPersonTypes.MARKETING)} headline={contactPersonHeadlines.MARKETING} />
            <CompaniesContactPersonsSection data={props.contactPersons.filter(item => item.type===contactPersonTypes.OFFICE)} headline={contactPersonHeadlines.OFFICE} />
            <CompaniesContactPersonsSection data={props.contactPersons.filter(item => item.type===contactPersonTypes.SALES)} headline={contactPersonHeadlines.SALES} />
            <CompaniesContactPersonsSection data={props.contactPersons.filter(item => item.type===contactPersonTypes.TRAINING)} headline={contactPersonHeadlines.TRAINING} />
            <CompaniesContactPersonsSection data={props.contactPersons.filter(item => item.type===contactPersonTypes.MISC)} headline={contactPersonHeadlines.MISC} />
            
        </Box>
	);
}

export default CompaniesContactPersons;
