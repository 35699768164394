import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TinyMceRenderer from '../misc/TinyMceRenderer';
import { Divider } from '@mui/material';

export default function CompaniesServices(props) {

    if(!props.services || props.services==="") {
        return null;
    }

  return (
    <Fragment>
        <Box sx={{my: 4}}>
          <TinyMceRenderer rawHtml={props.services} />
        </Box>
        <Divider />
    </Fragment>
  );
}

CompaniesServices.propTypes = {
  services: PropTypes.string,
};