import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Divider, Typography, Switch, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';


function LocationsAdd(props) {
    const defaultFormObj = {
        name: props.name,
        street: props.street,
        zipcode: props.zipcode,
        city: props.city,
        website: props.website,
        country: props.country,
        enabled: props.enabled,
    };
    const [form, setForm] = useState(defaultFormObj);

    const handleChange = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
        props.onChange(evt);
    }
    const toggleSwitch = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            enabled: evt.target.checked
        })
        props.onChange(evt);
    }
    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultFormObj);
        props.onCancelAdd();
    }

    const handleSubmit = (e) => {
		e.preventDefault();
        if(_.isEqual(defaultFormObj, form)) {
            return;
        }
        if(form.street==="" || form.zipcode==="" || form.city==="") {
            return;
        }
        const fields = {
            name: form.name,
            street: form.street,
            zipcode: form.zipcode,
            city: form.city,
            country: form.country,
            website: form.website,
            enabled: form.enabled,
        };
        setForm(fields);
        props.onAdd(fields);
    }



	return (
		<Fragment>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>

                <Typography variant="h6" sx={{fontWeight: 500}}>
                    Standort Adresse
                </Typography>

                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="name"
                        name="name"
                        label="Firmenname"
                        variant="outlined"
                        value={form.name ?? ""}
                        onChange={handleChange}
                        helperText="Wenn kein Standortname angegeben wird, wird im Partnerportal der Name des Hauptstandortes verwendet"
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="street"
                        name="street"
                        label="Strasse"
                        variant="outlined"
                        value={form.street ?? ""}
                        error={form.street===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="zipcode"
                        name="zipcode"
                        label="Postleitzahl"
                        variant="outlined"
                        value={form.zipcode ?? ""}
                        error={form.zipcode===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="city"
                        name="city"
                        label="Stadt"
                        variant="outlined"
                        value={form.city ?? ""}
                        error={form.city===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <InputLabel id="countrylabel" sx={{background:"#fff",px:1}}>Land</InputLabel>
                    <Select
                        labelId="countrylabel"
                        onChange={handleChange}
                        value={form.country ?? "DE"}
                        name="country"
                    >
                        <MenuItem value="DE">Deutschland</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="website"
                        name="website"
                        label="Webseite"
                        variant="outlined"
                        value={form.website ?? ""}
                        onChange={handleChange}
                        helperText="Wenn der Standort eine eigene Webseite hat bitte eine vollständige Web-Adresse mit https:// angebeben"
                    />
                </FormControl>

                <FormControlLabel
                    control={
                        <Switch checked={form.enabled} onChange={toggleSwitch} />
                    }
                    label="Standort im Partnerportal zeigen"
                />

                {
                    props.errorMsg!=="" &&
                    <>
                        <Divider sx={{my: 2}} />
                        <Alert sx={{mb: 2}} severity={props.errorType}>
                            {props.errorMsg}
                        </Alert>
                    </>
                }
                
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={_.isEqual(defaultFormObj, form) || form.street==="" || form.zipcode==="" || form.city==="" || form.country===""}
                            >
                            { "Daten speichern" }
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
             </Box>
		</Fragment>
	);
}

export default LocationsAdd;
