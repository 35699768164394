import { USE_QUERY_STALE_TIME, USE_QUERY_CACHE_TIME, userType } from "../utils";

export function getUseQueryOptions(params = {}) {
    const USER_TYPE = userType();
    
    const defaultOptions = {
        enabled: USER_TYPE!=="USER",
        staleTime: USE_QUERY_STALE_TIME,
        cacheTime: USE_QUERY_CACHE_TIME,
        retry: 1
    };
    return {
        ...defaultOptions,
        ...params
    }
}