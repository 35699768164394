import React, { Fragment, useState } from 'react';
import { Box, TextField, Typography, Paper, FormControl, InputLabel, MenuItem, Select, Button, Grid, Alert, FormControlLabel, Switch } from '@mui/material';
import { useUserDataState, saveBusinessContactPersonMainData, useUserDataDispatch } from '../../contexts/userdata';
import { getSalutation, validateEmail } from '../../utils';
import _ from 'lodash';

function BusinessContactPersonMain(props) {
    const { company, loading, employees } = useUserDataState();
    const defaultFormObj = company.contactPersonMain;
    const [form, setForm] = useState(defaultFormObj);
    const [useEmployee, setUseEmployee] = useState(defaultFormObj.useEmployee);

    const emptyMsgObj = {str:"",type:""};

    const [msg, setMsg] = useState(emptyMsgObj);
    const dispatch = useUserDataDispatch();

    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultFormObj);
        setUseEmployee(defaultFormObj.useEmployee);
        setMsg({str:"",type:""});
    }

    const toggleSwitch = evt => {
        evt.preventDefault();
        setUseEmployee(evt.target.checked);
        setMsg({str:"",type:""});
    }

    const handleChange = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
        setMsg({str:"",type:""});
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
        const fields = {
            contactPersonMain: {
                ...form,
                useEmployee: useEmployee
            }
        };
        const res = await saveBusinessContactPersonMainData(dispatch, fields);
        if(res?.company) {
            setForm(res.company.contactPersonMain);
            setMsg({
                str:"Erfolgreich gespeichert",
                type:"success"
            });
        } else {
            setMsg({
                str:"Ein Fehler ist aufgetreten — nicht gespeichert",
                type:"error"
            });
        }
        
    }


	return (
		<Fragment>
			<Typography variant="h2">
				Haupt-Ansprechpartner*in
			</Typography>
            <Paper elevation={10}>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>
                <FormControlLabel
                    control={
                        <Switch checked={useEmployee} onChange={toggleSwitch} />
                    }
                    label="Person aus Mitarbeiter*innen wählen" />
                {
                    useEmployee===true ? 
                    (
                        <>
                            <FormControl fullWidth sx={{mt: 3, mb: 2}}>
                                <InputLabel id="employeelabel" sx={{background:"#fff",px:1}}>Auswahl Mitarbeiter*in</InputLabel>
                                <Select
                                    labelId="employeelabel"
                                    onChange={handleChange}
                                    value={form.employeesId ?? ""}
                                    name="employeesId"
                                >
                                    {
                                        employees && employees.map((item,i) =>
                                            <MenuItem key={i} value={item.id}>
                                                {getSalutation(item.salutation)} {item.firstname} {item.lastname}
                                            </MenuItem>
                                        )
                                    }
                                    
                                </Select>
                            </FormControl>
                        </>
                    ):
                    (
                        <>
                            <Typography variant="h5" sx={{fontWeight: 500, mt: 3}}>
                                Person manuell angebeben
                            </Typography>
                            <FormControl fullWidth sx={{my: 2}}>
                                <InputLabel id="salutationlabel" sx={{background:"#fff",px:1}}>Anrede</InputLabel>
                                <Select
                                    labelId="salutationlabel"
                                    onChange={handleChange}
                                    value={form.salutation ?? ""}
                                    name="salutation"
                                >
                                    <MenuItem value="female">Frau</MenuItem>
                                    <MenuItem value="male">Herr</MenuItem>
                                    <MenuItem value="diverse">Divers</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{my: 2}}>
                                <TextField
                                    required
                                    id="firstname"
                                    name="firstname"
                                    label="Vorname"
                                    variant="outlined"
                                    value={form.firstname ?? ""}
                                    error={form.firstname===""}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{my: 2}}>
                                <TextField
                                    required
                                    id="lastname"
                                    name="lastname"
                                    label="Nachname"
                                    variant="outlined"
                                    value={form.lastname ?? ""}
                                    error={form.lastname===""}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{my: 2}}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="E-Mail"
                                    variant="outlined"
                                    value={form.email ?? ""}
                                    error={form.email==="" || !validateEmail(form.email)}
                                    helperText={!validateEmail(form.email) ? "Diese E-Mail Adresse ist ungültig": (form.email==="" ? "Bitte eine gültige E-Mail Adresse eintragen" : "")}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{my: 2}}>
                                <TextField
                                    required
                                    id="phone"
                                    name="phone"
                                    label="Telefon"
                                    variant="outlined"
                                    value={form.phone ?? ""}
                                    error={form.phone===""}
                                    helperText="Bitte Telefonnummer in diesem Format angeben: +49 (0)123 98765432"
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </>
                    )
                }
                
                {
                    msg?.str && <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 3, mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading || _.isEqual(defaultFormObj, form)}
                            >
                            { !loading ? "Daten speichern" : "Verarbeite…" }
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            disabled={_.isEqual(defaultFormObj, form)}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
             </Box>
            </Paper>
		</Fragment>
	);
}

export default BusinessContactPersonMain;
