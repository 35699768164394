import { namespaced, getTokenStorage, getNextAuth, getDecodedToken } from '../../utils';

let username = '';
let uuid = '';
let token = '';
let permissions = {};
let isRestricted = false;
let loggedIn = false;
let nextAuth = -1;

if(localStorage.getItem(namespaced('user'))) {
    const user = JSON.parse(localStorage.getItem(namespaced('user')));
    username = user.username;
    uuid = user.uuid;
    isRestricted = user.isRestricted;
}
if(localStorage.getItem(namespaced('token'))) {
    const { token: userToken, decodedToken } = getTokenStorage();
    var expires = new Date(decodedToken.exp * 1000);
    token = userToken;
    permissions = decodedToken.permissions;
    loggedIn = expires >= Date.now() && uuid!=="" ? true : false;
	nextAuth = loggedIn ? getNextAuth() : -1;
}

export const initialState = {
	username: '' || username,
	uuid: '' || uuid,
	token: '' || token,
	permissions: {} || permissions,
	loggedIn: false || loggedIn,
	isRestricted: false || isRestricted,
	loading: false,
	error: "",
	errorCode: -1,
	nextAuth: nextAuth
};

export const AuthReducer = (initialState, action) => {
    const { token, decodedToken } = getTokenStorage();
	switch (action.type) {
		case 'REQUEST_LOGIN':
			return {
				...initialState,
				loading: true,
				nextAuth: -1,
				error: "",
				errorCode: -1,
			};
		case 'REQUEST_PASSWORD_FORGOT':
		case 'REQUEST_PASSWORD_CONFIRM':
			return {
				...initialState,
				loading: true,
				error: "",
				errorCode: -1,
			};
		case 'PASSWORD_CONFIRM_SUCCESS':
			localStorage.setItem(namespaced('token'), action.payload.token);
			localStorage.setItem(namespaced('user'), JSON.stringify({
				username:action.payload.username,
				uuid:action.payload.uuid,
				userType:action.payload.userType
			}));
			return {
				...initialState,
				username: action.payload.username,
				uuid: action.payload.uuid,
				token: action.payload.token,
				permissions: getDecodedToken(action.payload.token).permissions,
				loggedIn: true,
				loading: false,
				isRestricted: action.payload.isRestricted,
				nextAuth: getNextAuth(),
				error: "",
				errorCode: -1
			};
		case 'PASSWORD_FORGOT_SUCCESS':
			return {
				...initialState,
				loading: false,
				error: "",
				errorCode: -1,
			};
		case 'REQUEST_LOGOUT':
			return {
				...initialState,
				loading: true,
				loggedIn: false,
				nextAuth: -1,
				error: "",
				errorCode: -1
			};
		case 'LOGIN_SUCCESS':
			return {
				...initialState,
				username: action.payload.username,
				uuid: action.payload.uuid,
				token: token,
				permissions: decodedToken.permissions,
				loggedIn: true,
				isRestricted: action.payload.isRestricted,
				loading: false,
				nextAuth: getNextAuth(),
				error: "",
				errorCode: -1
			};
		case 'LOGOUT':
			return {
				...initialState,
				user: '',
				token: '',
				uuid: '',
				permissions: {},
				loggedIn: false,
				loading: false,
				error: "",
				isRestricted: false,
				errorCode: -1
			};

		case 'LOGIN_ERROR':
			return {
				...initialState,
				loggedIn: false,
				loading: false,
				error: action.payload.error,
				errorCode: action.payload.code,
			};

		case 'PASSWORD_FORGOT_ERROR':
		case 'PASSWORD_CONFIRM_ERROR':
			return {
				...initialState,
				loading: false,
				error: action.payload.error,
				errorCode: action.payload.code,
			};

		case 'CHECK_AUTH':
			return {
				...initialState,
				loading: true,
				error: "",
				errorCode: -1,
			};

		case 'CHECK_AUTH_SUCCESS':
			return {
				...initialState,
				token: token,
				loading: false,
				permissions: decodedToken.permissions,
				nextAuth: getNextAuth(),
				error: "",
				errorCode: -1
			};

		case 'CHECK_AUTH_ERROR':
			return {
				...initialState,
				user: '',
				token: '',
				uuid: '',
				permissions: {},
				loggedIn: false,
				loading: false,
				isRestricted: false,
				nextAuth: -1,
				error: "",
				errorCode: -1
			};

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
