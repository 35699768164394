import React from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import useNews from "../../hooks/useNews";
import LoadingView from "../misc/LoadingView";
import ErrorView from "../misc/ErrorView";
import PageNotFoundView from "../misc/PageNotFoundView";
import { NO_IMAGE_FULL } from "../../utils";
import TinyMceRenderer from "../misc/TinyMceRenderer";
import { useHistory, useLocation } from "react-router-dom";
import { PATHNAMES } from "../../routes/pathnames";

function NewsDetail(props) {
  const { news, slug } = props;
  const history = useHistory();
  const location = useLocation();

  const content = news ? news.find((item) => item.slug === slug) : null;

  const comesFromList =
    location.query?.prevPath &&
    location.query?.prevPath !== "" &&
    location.query?.prevPath === PATHNAMES.NEWS;
  const backLabel = comesFromList ? "Zurück " : "";

  const { status, data } = useNews(content ? content.uuid : "");

  const handleGoBack = (evt) => {
    if (comesFromList) {
      window.history.back();
    } else {
      history.push(PATHNAMES.NEWS);
    }
  };

  if (!news || !content) {
    return <PageNotFoundView />;
  }

  if (status === "loading") {
    return <LoadingView />;
  }
  if (status === "error") {
    return <ErrorView />;
  }
  const teaser = data.teaser !== "" ? data.teaser : NO_IMAGE_FULL;

  return (
    <Container maxWidth="md" sx={{ marginTop: "-20px" }} className="no-padding">
      <Card elevation={10}>
        <Box
          component="img"
          sx={{
            height: "auto",
            width: "100%",
          }}
          alt={`teaser ${props.slug}`}
          src={teaser}
        />
        <CardContent sx={{ p: 3 }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            {data.title}
          </Typography>
          <TinyMceRenderer rawHtml={data.newsText} />
          {data.hyperlink !== "" && (
            <Button href={data.hyperlink} sx={{ p: 0 }}>
              Für weitere Informationen hier klicken
            </Button>
          )}
        </CardContent>
      </Card>
      <Box sx={{ my: 2, textAlign: "center" }}>
        <Button
          variant="outlined"
          onClick={handleGoBack}
          sx={{ borderRadius: "30px" }}
        >
          {backLabel} zur News-Übersicht
        </Button>
      </Box>
    </Container>
  );
}

export default NewsDetail;
