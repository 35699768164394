import React from 'react';
import { Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import useGeneralData from '../../hooks/useGeneralData';
import SectionHeadline from '../misc/SectionHeadline';

function CompaniesPartnerstatus(props) {

    const { status, data } = useGeneralData();

    if(status!=="success" || props.partnerstatus.length === 0) {
        return null;
    }

    const listing = data.partnerstatus.filter(item => {
        return props.partnerstatus.includes(item.id);
    })

	return (
        <Box sx={{py: 2}}>
            <SectionHeadline>Partnerstatus</SectionHeadline>
            <List>
            { listing.length > 0 && listing.map(item => {
                return (
                    <ListItem key={item.id} sx={{pl:0,ml:0, py: 0}}>
                        <ListItemText
                            primary={`· ${item.name}`}
                            sx={{my:0.25}}
                        />
                    </ListItem>
                )
            })
            }
            </List>
            <Divider sx={{mt:2}} />
        </Box>
	);
}

export default CompaniesPartnerstatus;
