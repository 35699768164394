const AppConfig = {
	namespace: "cpnfrontend",
    api: {
        development:"http://localhost:9000",
        production: window.location.origin
	},
    serviceUrl: "/api",
    assetPath: "/assets",
    realm: "tKOD*%EX7UhecuITM(!9J^ywr)GlPaFi51YvV3xS_&#mQj8Zp2g$0bsR4NCA@BkH",
    dateFormat: "DD.MM.YYYY",
    defaultTitle: "CPN Partnerportal"
};
export default AppConfig;