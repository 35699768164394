import { Alert, Box, Button, Divider, Paper, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { addBusinessLocationsData, deleteBusinessLocationsData, editBusinessLocationsData, useUserDataDispatch, useUserDataState } from '../../contexts/userdata';
import LocationsAdd from './LocationsAdd';
import LocationsEdit from './LocationsEdit';
import _ from 'lodash';

function BusinessLocationsData(props) {
    const { locations, loading } = useUserDataState();
    const [form, setForm] = useState();
    const [hasBlank, setHasBlank] = useState(false);

    const emptyMsgObj = {str:"",type:""};

    const [msg, setMsg] = useState(emptyMsgObj);
    const [msgId, setMsgId] = useState();
    const [randomKey, setRandomKey] = useState(_.uniqueId());
    const dispatch = useUserDataDispatch();
    
    const onChange = (e) => {
        setMsg(emptyMsgObj);
    }
    
    const handleAddBlank = (e) => {
        const blank = {
            name: "",
            street: "",
            zipcode: "",
            city: "",
            country: "DE",
            website: "",
        }
        setForm(blank);
        setHasBlank(true);
    }
    
    const onAdd = (fields) => {
        setMsg(emptyMsgObj);
        handleAdd(fields);
    }
    
    const onCancelAdd = (fields) => {
        setMsg(emptyMsgObj);
        setMsgId(null);
        setForm();
        setHasBlank(false);
    }
    
    const onDelete = async (uuid) => {
        setMsg(emptyMsgObj);
        setMsgId(uuid);
        setForm();
        setHasBlank(false);

        const res = await deleteBusinessLocationsData(dispatch, {uuid});
        if(res?.locations) {
            setForm();
            setRandomKey(_.uniqueId());
            setMsg({
                str:"Erfolgreich gelöscht",
                type:"success"
            });
        } else {
            setMsg({
                str:"Ein Fehler ist aufgetreten — nicht gelöscht",
                type:"error"
            });
        }
    }

    const onEdit = async (uuid, fields) => {
        setMsg(emptyMsgObj);
        setMsgId(uuid);
        const res = await editBusinessLocationsData(dispatch, {uuid, ...fields});
        if(res?.locations) {
            setForm();
            setRandomKey(_.uniqueId());
            setMsg({
                str:"Erfolgreich gespeichert",
                type:"success"
            });
        } else {
            setMsg({
                str:"Ein Fehler ist aufgetreten — nicht gespeichert",
                type:"error"
            });
        }
        setHasBlank(false);
    }

    const handleAdd = async (form) => {
        setMsg(emptyMsgObj);
        setMsgId(null);
        const res = await addBusinessLocationsData(dispatch, form);
        if(res?.locations) {
            setForm();
            setRandomKey(_.uniqueId());
            setHasBlank(false);
            setMsg({
                str:"Erfolgreich gespeichert",
                type:"success"
            });
        } else {
            setMsg({
                str:"Ein Fehler ist aufgetreten — nicht gespeichert",
                type:"error"
            });
        }
    }


	return (
		<Fragment>
			<Typography variant="h2">
				Weitere Standorte
			</Typography>
            <Paper elevation={10}>
            <Box noValidate autoComplete="off" sx={{p:2}} key={randomKey}>
                {
                    msg?.str && !hasBlank && <Alert sx={{mb: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                {
                    locations && locations.length > 0 && locations.map((item, i) => {
                        var errorMsg = "", errorType = "";
                        if(msgId===item.uuid && msg?.str) {
                            errorMsg = msg.str;
                            errorType = msg.type;
                        }
                        return (
                            <Box key={item.uuid}>
                                <LocationsEdit {...item} onEdit={onEdit} onDelete={onDelete} onChange={onChange} errorMsg={errorMsg} errorType={errorType} />
                                <Divider sx={{my: 2}} />
                            </Box>
                        )
                    })
                }
                { !hasBlank && <Button color="primary" onClick={handleAddBlank}>Weiteren Standort hinzufügen</Button> }
                { hasBlank && <LocationsAdd loading={loading} {...form} onChange={onChange} onAdd={onAdd} onCancelAdd={onCancelAdd} errorMsg={msg && msg.str!=="" ? msg.str : ""} errorType={msg && msg.type!=="" ? msg.type : ""} /> }
             </Box>
            </Paper>
		</Fragment>
	);
}

export default BusinessLocationsData;
