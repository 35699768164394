import React from 'react';
import { Container } from '@mui/material';
import BusinessLocationsData from '../components/account/BusinessLocationsData';
import { useUserDataState } from '../contexts/userdata';

function BusinessLocations(props) {
	const { initialized } = useUserDataState();

	if(!initialized) {
		return null;
	}

	return (
		<Container maxWidth="md" sx={{ mt: "-20px", mb: 4}} className="no-padding">
			<BusinessLocationsData />
		</Container>
	);
}

export default BusinessLocations;
