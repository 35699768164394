import { Buffer } from 'buffer';
import { namespaced, purgeLocalStorage, userLoggedIn, getDecodedToken } from "../../utils";
import apiService from '../../utils/apiService';

export async function login(dispatch, props) {
    const { username, password } = props;
    const encodedCredentials = Buffer.from(`${username}:${password}`).toString('base64');

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        const data = await apiService('login', {
            headers: {
                Authorization: `Basic ${encodedCredentials}`,
            }
        });
        if (data.user) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: data.user });
            localStorage.setItem(namespaced('user'), JSON.stringify(data.user));
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'LOGIN_ERROR', payload: { error: _err[0], code: _err[1] } });
        purgeLocalStorage();
	}
}

export async function forgotPassword(dispatch, props) {
    const { email } = props;

    try {
        dispatch({ type: 'REQUEST_PASSWORD_FORGOT' });
        const data = await apiService('password-forgot', { method: "GET", payload: {email: email }});
        if (data.success) {
            dispatch({ type: 'PASSWORD_FORGOT_SUCCESS' });
            return data;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'PASSWORD_FORGOT_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function confirmPassword(dispatch, props) {
    const { user, password, passwordConfirm } = props;

    try {
        dispatch({ type: 'REQUEST_PASSWORD_CONFIRM' });
        const data = await apiService(`password-confirm/${user}`, { method: "PUT", payload: { password, passwordConfirm }});
        if (data.success) {
            dispatch({ type: 'PASSWORD_CONFIRM_SUCCESS', payload: data });
            return {
                ...data,
                userType: getDecodedToken(data.token).permissions.userType
            };
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'PASSWORD_CONFIRM_ERROR', payload: { error: _err[0], code: _err[1] } });
	}
}

export async function checkAuth(dispatch) {
    if(!userLoggedIn()) {
        dispatch({ type: 'CHECK_AUTH_ERROR', error: 'Bitte loggen Sie sich erneut ein' });
        purgeLocalStorage();
        return;
    }
    try {
        dispatch({ type: 'CHECK_AUTH'});
        const data = await apiService('authorized', { useTokenAuth: true });
        if (data.authorized && data.authorized===true) {
            dispatch({ type: 'CHECK_AUTH_SUCCESS'});
            return data.authorized;
        }
    } catch (error) {
        const _err = error.message.split("|||");
		dispatch({ type: 'CHECK_AUTH_ERROR', payload: { error: _err[0], code: _err[1] } });
        purgeLocalStorage();
	}
}