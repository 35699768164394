import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import useNewsEvents from '../hooks/useNewsEvents';
import LoadingView from '../components/misc/LoadingView';
import NewsList from '../components/news/NewsList';
import NewsDetail from '../components/news/NewsDetail';

function News(props) {
	const { slug } = useParams();
	const { status, data } = useNewsEvents();

    if(status === "loading") {
        return <LoadingView />;
    }

	return (
		<Fragment>
			{ slug && slug!=="" ? <NewsDetail slug={slug} news={data.news} /> : <NewsList news={data.news} />}
		</Fragment>
	);
}

export default News;
