import React, { Fragment } from "react";
import CardsResultGrid from "../misc/CardsResultGrid";
import ServicesResultGrid from "../services/ServicesResultGrid";
import MarketingResultGrid from "../marketing/MarketingResultGrid";

function SearchResults({ results, ...props }) {
  return (
    <Fragment>
      {results.companies.length > 0 && (
        <CardsResultGrid
          data={results.companies}
          type="companies"
          page="search"
        />
      )}
      {results.news.length > 0 && (
        <CardsResultGrid data={results.news} type="news" page="search" />
      )}
      {results.events.length > 0 && (
        <CardsResultGrid data={results.events} type="events" page="search" />
      )}
      {results.sitemap.length > 0 &&
        results.sitemap.map((item) => {
          return (
            <ServicesResultGrid key={item.slug} isSearch={true} {...item} />
          );
        })}
      {results.marketing.length > 0 &&
        results.marketing.map((item) => {
          return (
            <MarketingResultGrid key={item.slug} isSearch={true} {...item} />
          );
        })}
    </Fragment>
  );
}

export default SearchResults;
