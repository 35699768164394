import React, { Fragment, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import useSearch from "../hooks/useSearch";
import LoadingView from "../components/misc/LoadingView";
import ErrorView from "../components/misc/ErrorView";
import { stopwords } from "../utils/stopwords";
import SearchResults from "../components/search/SearchResults";
import { Divider } from "@mui/material";
import { htmlEntities } from "../utils";

function SearchNotExecuted({ queryString }) {
  return (
    <Fragment>
      <Typography variant="h2">Suche nicht ausgeführt</Typography>
      <Typography variant="body1">
        {queryString.length < 3 ? (
          <>
            Der Suchbegriff{" "}
            <span
              dangerouslySetInnerHTML={{ __html: htmlEntities(queryString) }}
            />{" "}
            ist zu kurz (mindestens 3 Zeichen)
          </>
        ) : (
          <>
            Der Suchbegriff{" "}
            <span
              dangerouslySetInnerHTML={{ __html: htmlEntities(queryString) }}
            />{" "}
            hat keine inhaltliche Relevanz (sog. Stoppwort)
          </>
        )}
      </Typography>
    </Fragment>
  );
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Search(props) {
  let query = useQuery();
  const queryString = query.get("q");
  const searchFor =
    queryString &&
    !stopwords.includes(queryString.trim()) &&
    queryString.trim().length > 2
      ? queryString.trim()
      : "";
  const { status, data } = useSearch(searchFor);

  if (queryString && queryString !== "" && searchFor === "") {
    return <SearchNotExecuted queryString={queryString} />;
  }

  if (status === "loading") {
    return <LoadingView />;
  }
  if (status === "error") {
    return <ErrorView />;
  }
  let count = 0;
  if (data) {
    count = data.news.length + data.events.length + data.companies.length;
    data.sitemap.forEach((item) => {
      count += item.pages.length;
    });
    data.marketing.forEach((item) => {
      count += item.pages.length;
    });
  }
  let resultStr = "Es wurde kein Ergebnis gefunden";
  if (count > 1) {
    resultStr = `Es wurden insgesamt ${count} Ergebnisse gefunden`;
  } else if (count === 1) {
    resultStr = `Es wurde insgesamt ${count} Ergebnis gefunden`;
  }

  return (
    <Fragment>
      {searchFor !== "" && (
        <>
          <Typography variant="h2">
            Suche:{" "}
            <span
              dangerouslySetInnerHTML={{ __html: htmlEntities(queryString) }}
            />
          </Typography>
          <Typography variant="body2">{resultStr}</Typography>
          {count > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <SearchResults results={data} />
            </>
          )}
        </>
      )}

      {(!queryString || queryString === "") && searchFor === "" && (
        <Typography variant="h3">
          Bitte Suche über das Suchfeld starten
        </Typography>
      )}
    </Fragment>
  );
}

export default Search;
