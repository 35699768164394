import React from 'react';
import { Container } from '@mui/material';
import BusinessContactPersons from '../components/account/BusinessContactPersons';
import { useUserDataState } from '../contexts/userdata';

function ContactPersons(props) {
	const { initialized } = useUserDataState();

	if(!initialized) {
		return null;
	}

	return (
		<Container maxWidth="md" sx={{ mt: "-20px", mb: 4}} className="no-padding">
			<BusinessContactPersons />
		</Container>
	);
}

export default ContactPersons;
