import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Divider, Typography } from '@mui/material';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { employeeTypes, validateEmail } from '../../utils';


function EmployeeAdd(props) {
    const defaultFormObj = {
        uuid: props.uuid,
        salutation: props.salutation,
        firstname: props.firstname,
        lastname: props.lastname,
        phone: props.phone,
        portrait: props.portrait,
        jobPosition: props.jobPosition,
        userType: props.userType,
    };
    const [form, setForm] = useState(defaultFormObj);

    const handleChange = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
        props.onChange(evt);
    }

    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultFormObj);
        props.onCancelAdd();
    }

    const handleSubmit = (e) => {
		e.preventDefault();
        if(_.isEqual(defaultFormObj, form)) {
            return;
        }
        if(form.salutation==="" || form.userType==="" || form.firstname==="" || form.lastname==="" || form.email==="" || form.phone==="") {
            return;
        }
        if(!validateEmail(form.email)) {
            return;
        }
        const fields = {
            salutation: form.salutation,
            firstname: form.firstname,
            lastname: form.lastname,
            phone: form.phone,
            portrait: "",
            jobPosition: form.jobPosition,
            email: form.email,
            userType: form.userType,
        };
        setForm(fields);
        props.onAdd(fields);
    }

    const checkIfNew = () => {
        return props.salutation==="" && props.name==="" && props.email==="" && props.phone==="" && props.userType==="MEMBER";
    }


	return (
		<Fragment>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>
                
                <Alert sx={{mb: 2}} severity="info">
                    Nach dem Absenden des Formulars erhält der/die Mitarbeiter*in eine Aktivierungs-Email an die angegebene E-Mail-Adresse. Bitte stelle vor dem Speichern sicher, dass die persönlichen Angaben im Formular korrekt sind, da diese von dir NICHT nachträglich geändert werden können — dies kann nur der/die Mitarbeiter*in selbst!<br /><br />
                    Als ADMIN darfst du aber immer den User-Typ des/der Mitarbeiter*in festlegen — oder den Datensatz gegebenenfalls löschen.
                </Alert>

                <Typography variant="h6" sx={{fontWeight: 500}}>
                    Persönliche Angaben
                </Typography>

                <FormControl fullWidth sx={{my: 2}}>
                    <InputLabel id="salutationlabel" sx={{background:"#fff",px:1}} required>Anrede</InputLabel>
                    <Select
                        required
                        labelId="salutationlabel"
                        onChange={handleChange}
                        value={form.salutation ?? ""}
                        error={form.salutation===""}
                        name="salutation"
                    >
                        <MenuItem value="female">Frau</MenuItem>
                        <MenuItem value="male">Herr</MenuItem>
                        <MenuItem value="diverse">Divers</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="firstname"
                        name="firstname"
                        label="Vorname"
                        variant="outlined"
                        value={form.firstname ?? ""}
                        error={form.firstname===""}
                        helperText="Bitte Vorname angeben"
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="lastname"
                        name="lastname"
                        label="Nachname"
                        variant="outlined"
                        value={form.lastname ?? ""}
                        error={form.lastname===""}
                        helperText="Bitte Nachname angeben"
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="E-Mail"
                        variant="outlined"
                        value={form.email ?? ""}
                        error={form.email==="" || !validateEmail(form.email)}
                        helperText={!!checkIfNew() ? "Die E-Mail Adresse kann nachträglich nur vom Benutzer selbst geändert werden" : (!validateEmail(form.email) ? "Diese E-Mail Adresse ist ungültig": (form.email==="" ? "Bitte eine gültige E-Mail Adresse eintragen" : ""))}
                        onChange={handleChange}
                        disabled={!!checkIfNew()}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="phone"
                        name="phone"
                        label="Telefon"
                        variant="outlined"
                        value={form.phone ?? ""}
                        error={form.phone===""}
                        helperText="Bitte Telefonnummer in diesem Format angeben: +49 (0)123 98765432"
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="jobPosition"
                        name="jobPosition"
                        label="Job-Position"
                        variant="outlined"
                        value={form.jobPosition ?? ""}
                        error={form.jobPosition===""}
                        helperText="Bitte Job-Position angeben"
                        onChange={handleChange}
                    />
                </FormControl>

                <Divider sx={{my: 2}} />

                <Typography variant="h6" sx={{fontWeight: 500}}>
                    Berechtigung
                </Typography>
                
                <FormControl fullWidth sx={{my: 2}}>
                    <InputLabel id="typelabel" sx={{background:"#fff",px:1}} required>User-Typ</InputLabel>
                    <Select
                        required
                        labelId="typelabel"
                        onChange={handleChange}
                        value={form.userType ?? ""}
                        error={form.userType===""}
                        name="userType"
                    >
                        {
                            employeeTypes.map((item, i) => <MenuItem key={i} value={item}>{item}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <Alert sx={{mb: 2}} severity="warning">
                    <strong>Berechtigungen der verschiedenen User-Typen im Partnerportal:</strong><br />
                    USER: darf nur die Seiten der CPN-Services sehen<br />
                    MEMBER: darf alle Seiten sehen<br />
                    ADMIN: darf alle Seiten sehen und die Firmendaten bearbeiten (entsprechend deinem Account!)<br /><br />
                    Alle User dürfen generell den eigenen Account bearbeiten.
                </Alert>

                {
                    props.errorMsg!=="" &&
                    <>
                        <Divider sx={{my: 2}} />
                        <Alert sx={{mb: 2}} severity="error">
                            {props.errorMsg}
                        </Alert>
                    </>
                }
                
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={_.isEqual(defaultFormObj, form) || form.salutation==="" || form.type==="" || form.name==="" || form.email==="" || form.phone==="" || !validateEmail(form.email)}
                            >
                            { "Daten speichern" }
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleCancel}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                </Grid>
             </Box>
		</Fragment>
	);
}

export default EmployeeAdd;
