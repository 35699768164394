export const contactPersonTypes = {
    TECH: "Technik",
    MARKETING: "Marketing",
    OFFICE: "Buchhaltung",
    SALES: "Vertrieb",
    TRAINING: "Schulung",
    MISC: "Allgemein",
}
export const contactPersonHeadlines = {
    TECH: "Technik-Abteilung",
    MARKETING: "Marketing-Abteilung",
    OFFICE: "Buchhaltung",
    SALES: "Vertrieb",
    TRAINING: "Schulung",
    MISC: "Allgemein",
}
