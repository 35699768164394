import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet-async';
import AppConfig from '../config';
import LoadingView from '../components/misc/LoadingView';
import ErrorView from '../components/misc/ErrorView';
import useGeneralData from '../hooks/useGeneralData';
import UserDataLoader from '../components/loader/UserDataLoader';
import { useAuthState } from '../contexts/auth'
import { useUserDataState } from '../contexts/userdata'
import GeneralError from '../components/misc/GeneralError';


const LayoutContent = ({status, component: Component}) => {
    if(status === "loading") {
        return <LoadingView />;
    }
    if(status === "error") {
        return <ErrorView />;
    }

    if(status === "success") {
        return <Component />;
    }
}

function Layout({component: Component, ...props}) {
    const { pageTitle } = props;
    const { loggedIn } = useAuthState();
    const { error, errorCode } = useUserDataState();

    const { status } = useGeneralData();

	return (
        <Fragment>
            <Helmet>
                <title>{`${AppConfig.defaultTitle} - ${pageTitle}`}</title>
            </Helmet>
            <UserDataLoader />
            <Container maxWidth="xl">
                <Box sx={{ pt: 6, pb: 2 }}>
                    { (error!=="" || !loggedIn) && <GeneralError open={true} code={parseInt(errorCode,10)} message={error} />}
                    <LayoutContent status={status} component={Component} />
                </Box>
            </Container>
        </Fragment>
	);
}

export default Layout;
