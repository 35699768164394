import { Paper, InputLabel, Select, MenuItem, Grid, Button } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import useSitemap from '../../hooks/useSitemap';
import { PATHNAMES } from '../../routes/pathnames';


export default function ServicesFilter() {
    const history = useHistory();
	const { data } = useSitemap();
	const { slug } = useParams();
    const [cat, setCat] = useState(slug ?? "-");
  
    const handleChange = e => {
        setCat(e.target.value);
    }

    const handleClick = e => {
        let path = `${PATHNAMES.SERVICES}/${cat}`;
        if(cat==="-") {
            path = `${PATHNAMES.SERVICES}`
        }
      history.push({
          pathname: path
      })
    }

    useEffect(() => {
        if(!slug) {
            return;
        }
        setCat(slug);
    },[slug]);

    return (
        <Fragment>
            { data ? (
                <Paper elevation={10} sx={{px:2, py: 1, mb: 4}}>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                        <Grid item xs={9} sm={11}>
                            <InputLabel sx={{fontSize: "0.7rem"}} id="categorieslabel">Kategorien</InputLabel>
                            <Select
                                labelId="categorieslabel"
                                value={cat}
                                onChange={handleChange}
                                name="categories"
                                fullWidth
                                variant="standard"
                            >
                                <MenuItem value="-">Alle anzeigen</MenuItem>
                                { data.map(item => <MenuItem key={item.title} value={item.slug} disabled={cat===item.slug}>{item.title}</MenuItem>) }
                            </Select>
                        </Grid>
                        <Grid item xs={3} sm={1}>
                            <Button variant="outlined" disabled={cat==="" || cat===slug} onClick={handleClick} fullWidth sx={{mt:1, mb:.5}}>
                                Filtern
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            ) : null }
        </Fragment>
    );
}