import React, { Fragment } from 'react';
import useNewsEvents from '../../hooks/useNewsEvents';
import LoadingView from '../misc/LoadingView';
import ErrorView from '../misc/ErrorView';
import CardsResultGrid from '../misc/CardsResultGrid';

function EventsList(props) {

	const { status, data } = useNewsEvents();

    if(status === "loading") {
        return <LoadingView />;
    }
    if(status === "error") {
        return <ErrorView />;
    }

	return (
		<Fragment>
			{
                data.events.length > 0 && 
                <CardsResultGrid data={data.events} type="events" page="events" />
            }
		</Fragment>
	);
}

export default EventsList;
