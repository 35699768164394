import DeleteIcon from '@mui/icons-material/Delete';
import {
    Alert, Box, Button, Dialog,
    DialogContent, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography, Switch, FormControlLabel
} from '@mui/material';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';

function DeleteConfirm(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = (value) => {
        setOpen(false);
    };

    const handleDelete = (evt) => {
        props.handleDelete();
        setOpen(false);
    };
  
    return (
        <>
            <IconButton aria-label="delete" onClick={handleClickOpen} color="primary" size="small" sx={{mt: 3}}>
                <DeleteIcon />
            </IconButton>
            <Dialog onClose={handleClose} open={open} maxWidth="xs">
                <DialogContent>
                    <Typography>
                        Soll dieser Eintrag wirklich gelöscht werden?
                    </Typography>
                    <Box sx={{display:"flex",justifyContent:"space-between", mt: 2}}>
                        <Button variant="contained" onClick={handleDelete}>Löschen</Button>
                        <Button variant="outlined" onClick={handleClose}>Abbrechen</Button>
                    </Box>
                    
                </DialogContent>
            </Dialog>
        </>
        
    );
  }


function LocationsEdit(props) {
    const defaultFormObj = {
        name: props.name,
        street: props.street,
        zipcode: props.zipcode,
        city: props.city,
        website: props.website,
        country: props.country,
        enabled: props.enabled,
    };
    const [form, setForm] = useState(defaultFormObj);
    const [latLng,] = useState({
        lat: props.lat,
        lng: props.lng,
    });

    const handleChange = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            [evt.target.name]: evt.target.value
        })
        props.onChange(evt);
    }

    const handleCancel = evt => {
        evt.preventDefault();
        setForm(defaultFormObj);
        props.onCancelAdd();
    }
    const toggleSwitch = evt => {
        evt.preventDefault();
        setForm({
            ...form,
            enabled: evt.target.checked
        })
        props.onChange(evt);
    }

    const handleDelete = () => {
        props.onDelete(props.uuid);
    };

    const handleSubmit = (e) => {
		e.preventDefault();
        if(_.isEqual(defaultFormObj, form)) {
            return;
        }
        if(form.street==="" || form.zipcode==="" || form.city==="") {
            return;
        }
        const fields = {
            name: form.name,
            street: form.street,
            zipcode: form.zipcode,
            city: form.city,
            country: form.country,
            website: form.website,
            enabled: form.enabled,
        };
        setForm(fields);
        props.onEdit(props.uuid, fields);
    }



	return (
		<Fragment>
            <Box component="form" noValidate autoComplete="off" sx={{p:2}} onSubmit={handleSubmit}>

                <Typography variant="h6" sx={{fontWeight: 500}}>
                    Standort Adresse
                </Typography>

                {
                    parseFloat(latLng.lat) === 0 && parseFloat(latLng.lng) === 0 &&
                
                    <Alert sx={{mb: 2}} severity="warning">
                        <strong>Bitte überprüfe die Adresse:</strong><br />
                        Die Adresse konnte nicht von der Location API ausgewertet werden, um daraus korrekte Angaben zu Längen- und Breitengrad zu machen, weswegen der Standort inaktiv ist und zur Zeit nicht im Partnerportal angzeigt wird.<br />Bitte überprüfe, ob die Adresse Schreibfehler oder Zahlendreher enthält und speichere die Daten nach Korrektur erneut.<br />Wenn das Problem bestehen bleibt, wende dich bitte an das CPN Team. Danke.
                    </Alert>
                }

                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="name"
                        name="name"
                        label="Firmenname"
                        variant="outlined"
                        value={form.name ?? ""}
                        error={form.name===""}
                        onChange={handleChange}
                        helperText="Wenn kein Standortname angegeben wird, wird im Partnerportal der Name des Hauptstandortes verwendet"
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="street"
                        name="street"
                        label="Strasse"
                        variant="outlined"
                        value={form.street ?? ""}
                        error={form.street===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="zipcode"
                        name="zipcode"
                        label="Postleitzahl"
                        variant="outlined"
                        value={form.zipcode ?? ""}
                        error={form.zipcode===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        required
                        id="city"
                        name="city"
                        label="Stadt"
                        variant="outlined"
                        value={form.city ?? ""}
                        error={form.city===""}
                        onChange={handleChange}
                    />
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <InputLabel id="countrylabel" sx={{background:"#fff",px:1}}>Land</InputLabel>
                    <Select
                        labelId="countrylabel"
                        onChange={handleChange}
                        value={form.country ?? "DE"}
                        name="country"
                    >
                        <MenuItem value="DE">Deutschland</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{my: 2}}>
                    <TextField
                        id="website"
                        name="website"
                        label="Webseite"
                        variant="outlined"
                        value={form.website ?? ""}
                        onChange={handleChange}
                        helperText="Wenn der Standort eine eigene Webseite hat bitte eine vollständige Web-Adresse mit https:// angebeben"
                    />
                </FormControl>
                <FormControlLabel
                    control={
                        <Switch checked={form.enabled} onChange={toggleSwitch} />
                    }
                    label="Standort im Partnerportal zeigen"
                />

                {
                    props.errorMsg!=="" &&
                    <>
                        <Divider sx={{my: 2}} />
                        <Alert sx={{mb: 2}} severity={props.errorType}>
                            {props.errorMsg}
                        </Alert>
                    </>
                }
                
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6} sm={6}>
                        <Button 
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={_.isEqual(defaultFormObj, form) || form.street==="" || form.zipcode==="" || form.city==="" || form.country===""}
                            >
                            { "Daten speichern" }
                        </Button>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <Button 
                            type="button"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleCancel}
                            disabled={_.isEqual(defaultFormObj, form)}
                        >
                            Abbrechen
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <DeleteConfirm handleDelete={handleDelete} />
                    </Grid>
                </Grid>
             </Box>
		</Fragment>
	);
}

export default LocationsEdit;
