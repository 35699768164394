import React, { Fragment } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import SectionHeadline from '../misc/SectionHeadline';
import { getSalutation } from '../../utils';
import { useUserDataState } from '../../contexts/userdata';
import CompanyMap from '../map/CompanyMap';
import CompanyMapWithLocations from '../map/CompanyMapWithLocations';
import { gmapsDefaultOptions } from '../../utils/gmapsOptions';
import LinkIcon from '@mui/icons-material/Link';

function CompaniesDetailRightColumn(props) {
    const { gmaps } = useUserDataState();
    const { locations } = props;
    var locs = [];

    if(locations.length > 0) {
        locs = locations.map(item => {
            return {
                name: item.name,
                street: item.street,
                zipcode: item.zipcode,
                city: item.city,
                lat: item.lat,
                lng: item.lng
            }
        });
        locs.unshift({
            name: props.name,
            street: props.street,
            zipcode: props.zipcode,
            city: props.city,
            lat: props.lat,
            lng: props.lng
        });
    }

	return (
        <Fragment>
            <Paper elevation={10}>
                <Box>
                    <Box sx={{pb: "100%", position: "relative"}}>
                        { gmaps!=="" && 
                            <Box sx={{position:"absolute",top:0,bottom:0,left:0,right:0}}>
                                {
                                    locations.length > 0 &&
                                    <CompanyMapWithLocations gmaps={gmaps} locations={locs} options={{
                                        ...gmapsDefaultOptions,
                                        zoom: 11
                                    }} />
                                }
                                {
                                    locations.length === 0 &&
                                    <CompanyMap id={props.slug} gmaps={gmaps} options={{
                                        ...gmapsDefaultOptions,
                                        center: {
                                            lat: parseFloat(props.lat),
                                            lng: parseFloat(props.lng),
                                        },
                                        zoom: 11
                                    }} />
                                }
                            </Box>
                        }
                    </Box>
                </Box>
                <Box sx={{px:3, pt: 2, pb: 4}}>
                    <SectionHeadline>Hauptansprechpartner*in</SectionHeadline>
                    <Typography variant="body1" sx={{fontWeight: 500}}>{getSalutation(props.contactPersonMain.salutation)} {props.contactPersonMain.firstname} {props.contactPersonMain.lastname}</Typography>
                    <Typography variant="body1">{props.contactPersonMain.email}<br />{props.contactPersonMain.phone}</Typography>
                </Box>
            </Paper>
            {
                locations.length > 0 &&
                <Paper elevation={10} sx={{mt:3}}>
                    <Box sx={{px:3, pt: 2, pb: 4}}>
                        <SectionHeadline>Hauptstandort</SectionHeadline>
                        <Box sx={{mb:2}}>
                            <Typography variant="body1" sx={{fontWeight: 500}}>
                                {props.name}
                            </Typography>
                            <Typography variant="body1">
                                {props.street}<br />{`${props.zipcode} ${props.city}`}
                            </Typography>
                        </Box>
                        <SectionHeadline>Weitere Standorte</SectionHeadline>
                        {
                            locations.map((item, n) =>
                                {
                                    return (
                                        <Box key={n} sx={{mb:2}}>
                                            <Typography variant="body1" sx={{fontWeight: 500}}>
                                                {item.name}
                                            </Typography>
                                            <Typography variant="body1">
                                                {item.street}<br />{`${item.zipcode} ${item.city}`}
                                            </Typography>
                                            { item.website!=="" && 
                                                <Box sx={{ display: "block"}}>
                                                    <Typography component="a" href={`${item.website}`} target="_blank" rel="noreferrer" sx={{display:"flex", alignItems: "center", fontSize: ".85rem"}}><LinkIcon sx={{fontSize: ".85rem", mr: .5}} />{item.website}</Typography>
                                                </Box>
                                            }
                                        </Box>
                                    )
                                }
                            )
                        }
                    </Box>
                </Paper>
            }
        </Fragment>
	);
}

export default CompaniesDetailRightColumn;
