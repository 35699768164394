import { Alert, Box, Button, Divider, Paper, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { addBusinessEmployeesData, deleteBusinessEmployeesData, editBusinessEmployeesData, useUserDataDispatch, useUserDataState } from '../../contexts/userdata';
import EmployeeAdd from './EmployeeAdd';
import EmployeeEdit from './EmployeeEdit';


function BusinessEmployeesData(props) {
    const { employees, loading } = useUserDataState();
    const [form, setForm] = useState();
    const [hasBlank, setHasBlank] = useState(false);

    const emptyMsgObj = {str:"",type:""};

    const [msg, setMsg] = useState(emptyMsgObj);
    const dispatch = useUserDataDispatch();
    
    const onChange = (e) => {
        setMsg(emptyMsgObj);
    }
    
    const handleAddBlank = (e) => {
        const blank = {
            uuid: "",
            salutation: "",
            firstname: "",
            lastname: "",
            phone: "",
            portrait: "",
            jobPosition: "",
            userType: "MEMBER",
            id: ""
        }
        setForm(blank);
        setHasBlank(true);
    }
    
    const onAdd = (fields) => {
        setMsg(emptyMsgObj);
        handleAdd(fields);
    }
    
    const onCancelAdd = (fields) => {
        setMsg(emptyMsgObj);
        setForm();
        setHasBlank(false);
    }
    
    const onDelete = async (uuid) => {
        setMsg(emptyMsgObj);
        setForm();
        setHasBlank(false);

        const res = await deleteBusinessEmployeesData(dispatch, {uuid});
        if(res?.employees) {
            setForm();
            setMsg({
                str:"Erfolgreich gelöscht",
                type:"success"
            });
        } else {
            setMsg({
                str:"Ein Fehler ist aufgetreten — nicht gelöscht",
                type:"error"
            });
        }
    }

    const onEdit = async (uuid, userType) => {
        const res = await editBusinessEmployeesData(dispatch, {uuid, userType});
        if(res?.employees) {
            setForm();
            setMsg({
                str:"Erfolgreich gespeichert",
                type:"success"
            });
        } else {
            setMsg({
                str:"Ein Fehler ist aufgetreten — nicht gespeichert",
                type:"error"
            });
        }
        setHasBlank(false);
    }

    const handleAdd = async (form) => {
        const res = await addBusinessEmployeesData(dispatch, form);
        if(res?.employees) {
            setForm();
            setHasBlank(false);
            setMsg({
                str:"Erfolgreich gespeichert",
                type:"success"
            });
        } else {
            setMsg({
                str:"Ein Fehler ist aufgetreten — nicht gespeichert",
                type:"error"
            });
        }
    }


	return (
		<Fragment>
			<Typography variant="h2">
				Mitarbeiter*innen
			</Typography>
            <Paper elevation={10}>
            <Box noValidate autoComplete="off" sx={{p:2}}>
                {
                    msg?.str && !hasBlank && <Alert sx={{mb: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                {
                    employees && employees.length > 0 && employees.map((item, i) => {
                        return (
                            <Box key={item.id}>
                                <EmployeeEdit {...item} onEdit={onEdit} onDelete={onDelete} />
                                <Divider sx={{my: 2}} />
                            </Box>
                        )
                    })
                }
                { !hasBlank && <Button color="primary" onClick={handleAddBlank}>Weitere Mitarbeiter*in hinzufügen</Button> }
                { hasBlank && <EmployeeAdd loading={loading} {...form} onChange={onChange} onAdd={onAdd} onCancelAdd={onCancelAdd} errorMsg={msg && msg.str!=="" ? msg.str : ""} /> }
             </Box>
            </Paper>
		</Fragment>
	);
}

export default BusinessEmployeesData;
