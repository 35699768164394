import React from 'react';
import { Container } from '@mui/material';
import PersonalData from '../components/account/PersonalData';
import LoginData from '../components/account/LoginData';
import PersonalCertificationsData from '../components/account/PersonalCertificationsData';
// import PortraitData from '../components/account/PortraitData';
import { useUserDataState } from '../contexts/userdata';

function Account(props) {
	const { account, initialized } = useUserDataState();

	if(!initialized) {
		return null;
	}

	return (
		<Container maxWidth="md" sx={{ mt: "-20px", mb: 4}} className="no-padding">
			<PersonalData />
			<LoginData />
			{
				account.isRestricted === false && <PersonalCertificationsData />
			}
			
			{/* <PortraitData /> */}
		</Container>
	);
}

export default Account;
