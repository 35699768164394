import React, { useCallback, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Typography, Box } from '@mui/material';

const getToolbar = type => {
    const options = {
        advanced: 'blocks | bold italic underline strikethrough | link image | alignleft aligncenter alignright alignjustify | checklist numlist bullist | hr | undo redo | removeformat',
        simple: 'bold italic underline strikethrough | link | checklist numlist bullist | undo redo | removeformat',
        tiny: 'bold italic underline strikethrough | link | undo redo | removeformat',
    }
    return options.hasOwnProperty(type) ? options[type] : options.simple;
}
const getPlugins = type => {
    const options = {
        advanced: 'wordcount image link lists hr',
        simple: 'wordcount link lists',
        tiny: 'wordcount link',
    }
    return options.hasOwnProperty(type) ? options[type] : options.simple;
}
const getValidElements = type => {
    const options = {
        advanced: 'a[!href|target|rel|title],strong/b,em,h1[style],h2[style],h3[style],h4[style],h5[style],h6[style],p[style],u,i,ol,li,ul,img[!src|title],br',
        simple: 'a[!href|target|rel|title],strong/b,em,p[style],u,i,ol,li,ul,br',
        tiny: 'a[!href|target|rel|title],strong/b,em,p,u,i,br',
    }
    return options.hasOwnProperty(type) ? options[type] : options.simple;
}
const getValidStyles = type => {
    const options = {
        simple: {
            '*': 'text-align',
        },
    }
    return options.hasOwnProperty(type) ? options[type] : options.simple;
}

const TinyMceInput = ( props ) => {
    const editorRef = useRef(null);
    const { value, label, name } = props;

    const [editorContent, setEditorContent] = useState(value);

  
    const handleChange = useCallback(value => {
        setEditorContent(value);
        props.onChange(name, value);
    }, [props, name]);

    const errorClass = editorContent==="" && props?.required && props.required ? " has-error" : "";
    const helperText = editorContent==="" && props?.required && props.required ? 'Dieses Feld darf nicht leer sein' : '';
  
    return (
        <Box className={`tinymce ${errorClass}`} sx={{ my: 2 }}>
            <Typography variant="button">
                {label}
            </Typography>
        <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={editorContent}
            init={{
                height: props.editorType === "advanced" ? 500 : 300,
                menubar: false,
                plugins: getPlugins(props.editorType),
                toolbar:getToolbar(props.editorType),
                valid_elements:getValidElements(props.editorType),
                valid_styles:getValidStyles(props.editorType),
                icons: "thin",
                powerpaste_word_import: 'clean',
                powerpaste_html_import: 'clean'
              }}
            menubar={false}
            apiKey="lojvfwz74xhq2diof1vtdifolwxxv630l53z4k6gemykhwx8"
            onEditorChange={handleChange}
        />
        { helperText !== "" && <Typography sx={{ color: 'error.main' }}>{helperText}</Typography>}
      </Box>
    )
  }

TinyMceInput.defaultProps = {
    editorType: "tiny"
}

export default TinyMceInput;