import React from 'react';
import {
    Switch,
    Redirect
} from 'react-router-dom';
import AppRoute from './AppRoute';
import routes from './config.js';
import { userType } from '../utils';

function Routes() {
    const USER_TYPE = userType();
    return (
        <Switch>
          <Redirect exact from="/" to={{ pathname: USER_TYPE!=="USER" ? '/dashboard' : '/cpn-services' }} />
          {routes.map((route) => (
            <AppRoute
              key={route.path}
              exact={route.exact}
              path={route.path}
              pageTitle={route.label}
              component={route.component}
              authRequired={route.authRequired}
              accessLevel={route.accessLevel}
            />
          ))}
        </Switch>
    );
  }
   
  export default Routes;