import { Fragment, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthState } from '../../../contexts/auth';
import routes from '../../../routes/config';
import LogoFull from '../logos/LogoFull';
import { hasAccess } from '../../../utils';
import Search from './Search';
import { PATHNAMES } from '../../../routes/pathnames';



const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#E3010F',
    },
    warning: {
      main: '#ffc300',
    },
    text: {
      primary: '#000',
      secondary: '#E3010F',
    },
  },
  typography: {
    fontFamily: [
      'CPN',
    ].join(',')
  },
  shape: {
    borderRadius: 0,
  },
});

theme.shadows[16] = '0 0 8px 1px rgba(0, 0, 0, 0.09), 0 8px 16px -3px rgba(0, 0, 0, 0.18), 0 4px 18px -2px rgba(0, 0, 0, 0.04)';

const drawerWidth = 240;

const ResponsiveAppBar = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [drawerOpen, setDrawerState] = useState(false);

  const toggleDrawer = (bool) => {
    setDrawerState(bool);
  };

	const { loggedIn } = useAuthState();

  const location = useLocation();
  const history = useHistory();

  const clickNavMenu = (page) => {
    history.push({
        pathname: page,
        query: {}
    });
    toggleDrawer(false);
  };
  const clickUserMenu = (page) => {
    history.push({
        pathname: page,
        query: {}
    });
    toggleDrawer(false);
    handleCloseUserMenu();
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Fragment>
      {
        loggedIn && !location.pathname.includes(PATHNAMES.PASSWORDRESET) && !location.pathname.includes(PATHNAMES.PASSWORDINITIAL) &&
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex' }}>
            <AppBar position="static" elevation={16} color="primary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
              <Container maxWidth="xl">
                <Toolbar disableGutters variant="dense" sx={{ height: "60px"}}>

                  <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between' }}>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <LogoFull clickable={true} styles={{maxWidth: "250px", paddingTop: "5px"}} />
                    </Box>

                    <IconButton
                      size="large"
                      aria-label="navigation öffnen"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => toggleDrawer(!drawerOpen)}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>



                  <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'space-between' }}>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <LogoFull clickable={true} styles={{maxWidth: "220px", paddingTop: "5px"}} />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      {routes.map((route) => route.inAppBar && hasAccess(route.accessLevel) && (
                        <Button
                          key={route.label}
                          disableRipple
                          onClick={() => clickNavMenu(route.path)}
                          sx={[
                            {
                              px: 2,
                              display: 'block',
                              fontWeight: 400,
                              textTransform: 'none',
                              fontSize: '1.1rem',
                              color: location.pathname.includes(route.path) ? 'text.secondary' : 'text.primary',
                              height: "60px",
                              border: "1px solid #fff",
                              borderBottomColor: location.pathname.includes(route.path) ? 'text.secondary' : '#fff',
                              '&:hover': {
                                color: 'text.secondary',
                                backgroundColor: 'white',
                                borderBottomColor: 'text.secondary'
                              },
                          }]}
                        >
                          {route.label}
                        </Button>
                      ))}
                    </Box>

                    <Box sx={{display: 'flex'}}>
                      <Search />
                      <Tooltip title="Einstellungen">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} disableRipple color="secondary">
                          <AccountCircleIcon />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {routes.map((route) => route.inUserMenu && hasAccess(route.accessLevel) && (
                          <MenuItem key={route.label} onClick={() => clickUserMenu(route.path)}>
                            <Typography textAlign="center" sx={{
                            color: location.pathname.includes(route.path) ? 'text.secondary' : 'text.primary',
                          }}>{route.label}</Typography>
                          </MenuItem>
                        ))}

                      </Menu>
                    </Box>
                  </Box>


                </Toolbar>
              </Container>
            </AppBar>

            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
                display: { md: 'none' }
              }}
              onClose={() => toggleDrawer(false)}
              anchor="right"
              open={drawerOpen}
            >
              <Toolbar />
              <List>
                <Search />
                <Divider />
                {routes.map((route) => route.inAppBar && hasAccess(route.accessLevel) && (
                  <ListItem key={route.label} disablePadding>
                    <ListItemButton onClick={() => clickNavMenu(route.path)}>
                      <ListItemText primary={route.label} sx={{
                        color: location.pathname.includes(route.path) ? 'text.secondary' : 'text.primary',
                      }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              <Divider />
              {routes.map((route) => route.inUserMenu && hasAccess(route.accessLevel) && (
                <Fragment key={route.label}>
                  {
                    route.label === "Logout" ? <Divider /> : null
                  }
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => clickNavMenu(route.path)}>
                      <ListItemText primary={route.label} sx={{
                        color: location.pathname.includes(route.path) ? 'text.secondary' : 'text.primary',
                      }} />
                    </ListItemButton>
                  </ListItem>
                </Fragment>
              ))}
              </List>

            </Drawer>
          </Box>
        </ThemeProvider>
      }
    </Fragment>
    
  );
};
export default ResponsiveAppBar;
