import React, { useReducer, createContext } from "react";

const MapScrollStateContext = createContext();
const MapScrollDispatchContext = createContext();

const initialState = {
  mapScrollIndex: -1,
};

export const MapReducer = (initialState, action) => {
  switch (action.type) {
    case "SET_MAP_SCROLL":
      return {
        ...initialState,
        mapScrollIndex: action.payload.mapScrollIndex,
      };
    case "UNSET_MAP_SCROLL":
    default:
      return initialState;
  }
};

export async function setMapScrollIndex(dispatch, payload) {
  dispatch({ type: "SET_MAP_SCROLL", payload });
}

export async function resetMapScrollIndex(dispatch) {
  dispatch({ type: "RESET_MAP_SCROLL" });
}

export function useMapScrollState() {
  const context = React.useContext(MapScrollStateContext);
  if (context === undefined) {
    throw new Error("useMapState must be used within a MapProvider");
  }

  return context;
}

export function useMapScrollDispatch() {
  const context = React.useContext(MapScrollDispatchContext);
  if (context === undefined) {
    throw new Error("useMapDispatch must be used within a MapProvider");
  }

  return context;
}

export const MapScrollProvider = ({ children }) => {
  const [userdata, dispatch] = useReducer(MapReducer, initialState);

  return (
    <MapScrollStateContext.Provider value={userdata}>
      <MapScrollDispatchContext.Provider value={dispatch}>
        {children}
      </MapScrollDispatchContext.Provider>
    </MapScrollStateContext.Provider>
  );
};
