import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { PATHNAMES } from '../../routes/pathnames';
import PropTypes from 'prop-types';
import CompaniesLogo from './CompaniesLogo';
export default function CompaniesCard(props) {
  const history = useHistory();
  
  const handleClick = e => {
    if(!props.hasDetailLink) {
      return;
    }
    history.push({
        pathname: `${PATHNAMES.COMPANIES}/${props.slug}`
    })
  }

  const Logo = () => props.logo !== "" ? <CompaniesLogo src={props.logo} alt={props.slug} /> : null;

  return (
    <Box>
      <Card elevation={10}>
        <CardActionArea onClick={handleClick}>
          <CardContent>
              <Logo />
              { props.hasAddressHeader && 
                <Typography variant="button" component="div" sx={{ fontWeight: 600 }}>
                    Adresse
                </Typography>
              }
              <Typography component="div" sx={{fontSize: "1.25rem", fontWeight: 500, lineHeight: 1.15, maxWidth: ( props.logo !== "" ? "60%" : "100%" )}}>
                  {props.name}
              </Typography>
              <Typography color="text.secondary" sx={{fontSize: "0.75rem", mt: .5}}>
                  {props.street}<br />
                  {props.zipcode} {props.city}
                  { props.hasAddressHeader && props.website!=="" && 
                    <Button size="small" onClick={() => window.open(`${props.website}`)} sx={{ display: "block", mt: 1}}>Zur Webseite</Button>
                  }
              </Typography>
          </CardContent>
        
        </CardActionArea>
      </Card>
    </Box>
  );
}

CompaniesCard.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string,
  location: PropTypes.object,
  logo: PropTypes.string,
  memberSince: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  street: PropTypes.string,
  uuid: PropTypes.string,
  website: PropTypes.string,
  zipcode: PropTypes.string,
  hasDetailLink: PropTypes.bool,
  hasAddressHeader: PropTypes.bool,
};
CompaniesCard.defaultProps = {
  hasDetailLink: true,
  hasAddressHeader: false,
};