import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { PATHNAMES } from "../../routes/pathnames";
import TinyMceRenderer from "../misc/TinyMceRenderer";
import { NO_IMAGE_TEASER } from "../../utils";
import { CardActionArea } from "@mui/material";

export default function NewsCard(props) {
  const history = useHistory();

  const handleClick = (e) => {
    history.push({
      pathname: `${PATHNAMES.NEWS}/${props.slug}`,
      query: { prevPath: props.isDashboardOrSearch ? `` : PATHNAMES.NEWS },
    });
  };

  const teaser = props.teaser !== "" ? props.teaser : NO_IMAGE_TEASER;

  return (
    <Box sx={{ height: "100%" }}>
      <Card elevation={10} sx={{ height: "100%", alignItems: "top" }}>
        <CardActionArea
          onClick={handleClick}
          sx={{
            height: "100%",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <CardMedia
            component="img"
            height="190"
            image={teaser}
            alt={`teaser ${props.slug}`}
            sx={{
              backgroundSize: "contain",
              height: "auto",
              objectFit: "contain",
            }}
          />
          <CardContent>
            <Typography
              component="div"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 500,
                lineHeight: "1.65rem",
              }}
            >
              {props.title}
            </Typography>
            <Typography
              component="div"
              color="text.secondary"
              className="excerpt-wrapper"
              sx={{
                height: "7.5rem",
                overflow: "hidden",
                fontSize: "0.875rem",
                mb: 1,
                lineHeight: "1.25rem",
              }}
            >
              <TinyMceRenderer rawHtml={props.excerpt} />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}
