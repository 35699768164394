import React from 'react';
import PageNotFoundView from '../components/misc/PageNotFoundView';

function NotFound(props) {
	return (
		<PageNotFoundView />
	);
}

export default NotFound;
