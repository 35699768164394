import React from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import useEvent from "../../hooks/useEvent";
import LoadingView from "../misc/LoadingView";
import ErrorView from "../misc/ErrorView";
import PageNotFoundView from "../misc/PageNotFoundView";
import TinyMceRenderer from "../misc/TinyMceRenderer";
import { useHistory, useLocation } from "react-router-dom";
import { PATHNAMES } from "../../routes/pathnames";
import { getDateFromString } from "../../utils";

function EventsDetail(props) {
  const { events, slug } = props;
  const history = useHistory();
  const location = useLocation();

  const comesFromList =
    location.query?.prevPath &&
    location.query?.prevPath !== "" &&
    location.query?.prevPath === PATHNAMES.EVENTS;
  const backLabel = comesFromList ? "Zurück " : "";

  const handleGoBack = (evt) => {
    if (comesFromList) {
      window.history.back();
    } else {
      history.push(PATHNAMES.EVENTS);
    }
  };

  const content = events ? events.find((item) => item.slug === slug) : null;

  const { status, data } = useEvent(content ? content.uuid : "");

  if (!events || !content) {
    return <PageNotFoundView />;
  }

  if (status === "loading") {
    return <LoadingView />;
  }
  if (status === "error") {
    return <ErrorView />;
  }

  let dateString = "";
  if (data.isMultiDay === false) {
    dateString = `${getDateFromString(data.fromDate)}`;
  } else {
    dateString = `${getDateFromString(data.fromDate)} bis ${getDateFromString(
      data.toDate
    )}`;
  }
  let timeString = "";
  if (data.fromTime && data.fromTime !== "") {
    timeString = `${data.fromTime} Uhr `;
  }
  if (data.toTime && data.toTime !== "") {
    timeString = timeString.concat(`bis ${data.toTime} Uhr`);
  }

  return (
    <Container maxWidth="md" sx={{ marginTop: "-20px" }} className="no-padding">
      <Card elevation={10} sx={{ px: 1 }}>
        <CardContent>
          <Typography variant="h2" sx={{ mb: 2 }}>
            {data.title}
          </Typography>

          <Box sx={{ mb: 2 }}>
            <Typography variant="body2">
              <strong>Datum: </strong> {dateString}
            </Typography>
            {timeString !== "" && (
              <Typography variant="body2">
                <strong>Zeit: </strong> {timeString}
              </Typography>
            )}

            {data.location !== "" && (
              <Typography variant="body2">
                <strong>Ort: </strong> {data.location}
              </Typography>
            )}

            {data.organizer !== "" && (
              <Typography variant="body2">
                <strong>Veranstalter: </strong> {data.organizer}
              </Typography>
            )}

            {data.eventType !== "" && (
              <Typography variant="body2">
                <strong>Art: </strong> {data.eventType}
              </Typography>
            )}
          </Box>

          <TinyMceRenderer rawHtml={data.eventText} />
          {data.hyperlink !== "" && (
            <Button href={data.hyperlink} sx={{ p: 0 }}>
              Für weitere Informationen hier klicken
            </Button>
          )}
        </CardContent>
      </Card>
      <Box sx={{ my: 2, textAlign: "center" }}>
        <Button
          variant="outlined"
          onClick={handleGoBack}
          sx={{ borderRadius: "30px" }}
        >
          {backLabel} zur Termin-Übersicht
        </Button>
      </Box>
    </Container>
  );
}

export default EventsDetail;
