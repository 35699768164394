import React, { Fragment, useState } from 'react';
import { Box, Typography, Paper, Alert, Button, Divider } from '@mui/material';
import { useUserDataState, saveBusinessContactPersonsData, useUserDataDispatch } from '../../contexts/userdata';
import ContactPerson from './ContactPerson';

function BusinessContactPersons(props) {
    const { company, loading } = useUserDataState();
    const defaultFormObj = company.contactPersons.map((item, i) => { item.id = i; return item; });
    const [form, setForm] = useState(defaultFormObj);
    const [hasBlank, setHasBlank] = useState(false);

    const emptyMsgObj = {str:"",type:""};

    const [msg, setMsg] = useState(emptyMsgObj);
    const [msgId, setMsgId] = useState();
    const dispatch = useUserDataDispatch();
    
    const onChange = (e) => {
        setMsg(emptyMsgObj);
    }
    
    const handleAdd = (e) => {
        const blank = {
            salutation:"",
            name:"",
            email:"",
            phone:"",
            type:"",
            id: form.length
        }
        const newForm = [
            ...form,
            blank
        ];
        setHasBlank(true);
        setForm(newForm);
    }
    
    const onSubmit = (id, fields) => {
        setMsg(emptyMsgObj);
        setMsgId(id);
        const filtered = form.filter(item => item.id!==id);
        const newForm = [
            ...filtered,
            fields
        ].sort((a,b) => a.id - b.id);
        handleSubmit(newForm);
    }
    
    const onDelete = (id, isNew) => {
        setMsg(emptyMsgObj);
        setMsgId(null);
        const newForm = form.filter(item => item.id!==id).sort((a,b) => a.id - b.id);
        if(isNew) {
            setForm(newForm);
            setHasBlank(false);
            return;
        }
        handleSubmit(newForm);
    }

    const handleSubmit = async (form) => {
        const fields = {
            contactPersons: form
        };
        const res = await saveBusinessContactPersonsData(dispatch, fields);
        if(res?.company) {
            const defaultFormObj = res.company.contactPersons.map((item, i) => { item.id = i; return item; });
            setForm(defaultFormObj);
            setMsg({
                str:"Erfolgreich gespeichert",
                type:"success"
            });
        } else {
            setMsg({
                str:"Ein Fehler ist aufgetreten — nicht gespeichert",
                type:"error"
            });
        }
        setHasBlank(false);
        
    }


	return (
		<Fragment>
			<Typography variant="h2">
				Ansprechpartner*innen
			</Typography>
            <Paper elevation={10}>
            <Box noValidate autoComplete="off" sx={{py:2}}>
                {
                    msgId===-1 && msg?.str && <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>
                }
                {
                    form && form.length > 0 && form.map((item, i) => {
                        let msgComponent = <></>;
                        if(msgId===i && msg?.str) {
                            msgComponent = <Alert sx={{mt: 2}} severity={msg.type}>{msg.str}</Alert>;
                        }
                        return (
                            <Box key={item.id}>
                                <ContactPerson loading={loading} {...item} onChange={onChange} onSubmit={onSubmit} onDelete={onDelete} message={msgComponent} />
                                <Divider sx={{my: 2}} />
                            </Box>
                        )
                    })
                }
                { !hasBlank && <Button color="primary" onClick={handleAdd}>Weitere Person hinzufügen</Button> }
             </Box>
            </Paper>
		</Fragment>
	);
}

export default BusinessContactPersons;
